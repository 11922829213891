import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, {
  AlignT,
  CustomWidthE,
  FlexDirectionT,
  FlexWrapT,
} from "../../components/FlexBox";
import LoaderComponent from "../../components/Loader";
import { rem, theme } from "../../styling/theme";
import ApprovalHeaderPlaceholder from "./Items/ApprovalHeaderPlaceholder";
import CampaignCardPlaceholder from "./Items/CampaignCardPlaceholder";
import CampaignCardSmallPlaceholder from "./Items/CampaignCardSmallPlaceholder";
import CampaignDetailHeader from "./Items/CampaignDetailHeaderPlaceholder";
import ChartCardPlaceholder from "./Items/ChartCardPlaceholder";
import ChatMessagePlaceholder from "./Items/ChatMessagePlaceholder";
import ConversationCardPlaceholder from "./Items/ConversationCardPlaceholder";
import InfluencerItemPlaceholder from "./Items/InfluencerItemPlaceholder";
import NocitifcationCardPlaceholder from "./Items/NocitifcationCardPlaceholder";
import OutputBoxPlaceholder from "./Items/OutputBoxPlaceholder";
import TableItemPlaceholder from "./Items/TableItemPlaceholder";

type AvatarSizeStyleT = {
  size?: number;
};

type CardWrapperStyleT = {
  withBorder?: boolean;
  noPadding?: boolean;
};

export const CardWrapper = styled(FlexBox)<CardWrapperStyleT>(
  ({ withBorder = true, noPadding = false }) => ({
    padding: noPadding ? 0 : `${rem(16)} ${rem(24)}`,
    borderBottom: withBorder
      ? `1px solid ${theme.color.greyLightColor}`
      : "none",
  }),
);

export const AnimationOverlay = styled.div({
  position: "absolute",
  width: "100%",
  height: "200%",
  background:
    "linear-gradient(70deg, rgba(255,255,255,0) 25%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 75%)",
  backgroundSize: "100% 100%",
  animation: "moving-gradient 1.5s ease infinite",
});

export const Avatar = styled.div<AvatarSizeStyleT>(({ size = 50 }) => ({
  width: rem(size),
  minWidth: rem(size),
  height: rem(size),
  marginRight: rem(12),
  borderRadius: "50%",
  backgroundColor: theme.color.greyLightColor,
}));

export const Line = styled.div<{ last?: boolean; noMarginBottom?: boolean }>(
  ({ last = false, noMarginBottom = false }) => ({
    width: last ? "70%" : "100%",
    height: rem(12),
    marginBottom: noMarginBottom ? 0 : rem(20),
    marginRight: rem(16),
    backgroundColor: theme.color.greyLightColor,
  }),
);

export enum PlaceholdersTypeE {
  conversation_card = "conversation_card",
  notification_card = "notification_card",
  table_item = "table_item",
  table_item_no_avatar = "table_item_no_avatar",
  chat_message = "chat_message",
  output_box = "output_box",
  camapign_card = "camapign_card",
  camapign_card_small = "camapign_card_small",
  camapign_detail_header = "camapign_detail_header",
  chart_card = "chart_card",
  influencers_list = "influencers_list",
  approval_header = "approval_header",
}

type PlaceholderLoaderT = {
  type: PlaceholdersTypeE;
  direction?: FlexDirectionT;
  flexWrap?: FlexWrapT;
  justifyContent?: AlignT;
  count?: number;
  withBorder?: boolean;
  perRow?: number;
};

const PlaceholderLoader: FC<PlaceholderLoaderT> = ({
  type,
  direction = "row",
  justifyContent = "center",
  flexWrap = "wrap",
  count = 3,
  perRow = 4,
  withBorder,
}) => {
  type getPlaceholderTypeT = (
    type: PlaceholdersTypeE,
    index: number,
  ) => JSX.Element;
  const getPlaceholderType: getPlaceholderTypeT = (type, index) => {
    if (type === PlaceholdersTypeE.conversation_card) {
      return <ConversationCardPlaceholder key={index} />;
    }

    if (type === PlaceholdersTypeE.notification_card) {
      return <NocitifcationCardPlaceholder key={index} />;
    }

    if (type === PlaceholdersTypeE.chat_message) {
      return <ChatMessagePlaceholder key={index} index={index} count={count} />;
    }

    if (type === PlaceholdersTypeE.table_item) {
      return <TableItemPlaceholder key={index} withBorder={withBorder} />;
    }

    if (type === PlaceholdersTypeE.table_item_no_avatar) {
      return (
        <TableItemPlaceholder
          key={index}
          withAvatar={false}
          withBorder={withBorder}
        />
      );
    }

    if (type === PlaceholdersTypeE.influencers_list) {
      return <InfluencerItemPlaceholder key={index} />;
    }

    if (type === PlaceholdersTypeE.output_box) {
      return <OutputBoxPlaceholder key={index} perRow={perRow} />;
    }

    if (type === PlaceholdersTypeE.camapign_card) {
      return <CampaignCardPlaceholder key={index} perRow={perRow} />;
    }

    if (type === PlaceholdersTypeE.camapign_card_small) {
      return <CampaignCardSmallPlaceholder key={index} />;
    }

    if (type === PlaceholdersTypeE.camapign_detail_header) {
      return <CampaignDetailHeader key={index} />;
    }

    if (type === PlaceholdersTypeE.approval_header) {
      return <ApprovalHeaderPlaceholder key={index} />;
    }

    if (type === PlaceholdersTypeE.chart_card) {
      return <ChartCardPlaceholder key={index} />;
    }

    return <LoaderComponent key={index} />;
  };

  return (
    <FlexBox
      flexDirection={direction}
      justifyContent={justifyContent}
      flexWrap={flexWrap}
      customWidth={CustomWidthE.full}
    >
      {[...Array(count)].map((_, index) => {
        return getPlaceholderType(type, index);
      })}
    </FlexBox>
  );
};

export default PlaceholderLoader;
