import styled from "@emotion/styled";
import { Select } from "antd";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import FilterContainer from "../components/FilterContainer";
import { FilterDataT, SearchQueryT } from "../types";

const Dash = styled(Paragraph)({
  color: theme.color.greyColor,
});

type FollowersT = {
  data: FilterDataT;
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
};

const Followers: FC<FollowersT> = ({ searchQuery, setFilterSearchQuery }) => {
  const { people_count_from, people_count_to } = searchQuery;

  const { t } = useTranslate("brands.influencer_database.filter.followers");

  const options = [
    { value: 1000, label: "1k" },
    { value: 5000, label: "5k" },
    { value: 10000, label: "10k" },
    { value: 25000, label: "25k" },
    { value: 50000, label: "50k" },
    { value: 100000, label: "100k" },
    { value: 250000, label: "250k" },
    { value: 500000, label: "500k" },
    { value: 1000000, label: "1M+" },
  ];

  return (
    <FilterContainer t={t}>
      <FlexBox customWidth={CustomWidthE.full} gap={rem(8)}>
        <Select
          style={{ width: "100%" }}
          placeholder={t("placeholder.from")}
          options={options}
          value={people_count_from}
          onChange={(value) =>
            setFilterSearchQuery({ people_count_from: value })
          }
        />
        <Dash paragraph={"-"} paragraphSize="small" />
        <Select
          style={{ width: "100%" }}
          placeholder={t("placeholder.to")}
          options={options}
          value={people_count_to}
          onChange={(value) => setFilterSearchQuery({ people_count_to: value })}
        />
      </FlexBox>
    </FilterContainer>
  );
};

export default Followers;
