import styled from "@emotion/styled";
import { rem } from "../../../styling/theme";

const CircleIcon = styled.div<{
  color: string;
  variant: "background" | "outline";
  isActive?: boolean;
}>`
  border-radius: 50%;
  display: inline-block;
  padding: ${rem(7)};
  font-size: 0;
  line-height: 0;
  ${({ variant, color }) =>
    variant === "background"
      ? `background-color: ${color}`
      : `border: solid 1px ${color}`};

  svg {
    fill: currentColor;
  }
`;

export default CircleIcon;
