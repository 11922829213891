export const calculateMonthlyAmount = (
  period_unit: "month" | "year",
  amount: number,
): number => {
  if (period_unit === "year") {
    return amount / 12;
  }

  return amount;
};
