import { Tag } from "antd";
import * as React from "react";
import { FC } from "react";
import Paragraph from "../../../../../../components/Paragraph";
import { designToken } from "../../../../../../helpers/antDesign";
import { getDateTime } from "../../../../../../helpers/date";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { CampaignT, PartnershipT } from "../../../../types";

type AutocollectInsightsT = {
  campaign: CampaignT;
  partnership: PartnershipT;
};

const AutocollectInsights: FC<AutocollectInsightsT> = ({
  campaign,
  partnership,
}) => {
  const { approval_requests } = partnership;
  const allApprovedMentions = approval_requests.flatMap(
    (ar) => ar.approved_mentions,
  );

  const { t } = useTranslate("brands.campaign.partnerships.table");

  const getApprovalRequestState = (mention: string): JSX.Element | void => {
    const request = approval_requests
      .filter((ar) => ar.mentions.includes(mention))
      .sort((a, b) => {
        const aDate = new Date(a.notification_last_sent_at || 0);
        const bDate = new Date(b.notification_last_sent_at || 0);
        return bDate.getTime() - aDate.getTime();
      })[0];

    if (
      request &&
      (request.status === "processed" || request.notification_last_sent_at)
    ) {
      const { notification_last_sent_at, created_at, status } = request;
      const approved = allApprovedMentions.includes(mention);
      const text = approved ? t("approved") : t("requested");
      const date = approved ? created_at : notification_last_sent_at;

      return (
        <Tag
          color={
            status === "processed" ? designToken.green1 : designToken.orange1
          }
          bordered={false}
        >
          <Paragraph
            paragraph={[
              text,
              date
                ? `${t("requested_on")} ${getDateTime(date, "shortDate")}`
                : "",
            ].join(" ")}
            color={
              status === "processed" ? designToken.green6 : designToken.orange6
            }
            paragraphSize="small"
          />
        </Tag>
      );
    }

    return (
      <Tag bordered={false}>
        <Paragraph paragraph={t("not_requested")} paragraphSize="small" />
      </Tag>
    );
  };

  return (
    <>
      {partnership.network == "instagram" ? (
        <table>
          <tbody>
            {campaign.instagram_mentions.map((mention) => (
              <tr key={mention}>
                <td>
                  <Paragraph paragraph={`@${mention}`} paragraphSize="small" />
                </td>
                <td>{getApprovalRequestState(mention)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Tag bordered={false}>
          <Paragraph
            paragraph={t("tiktok_unavailable")}
            paragraphSize="small"
          />
        </Tag>
      )}
    </>
  );
};

export default AutocollectInsights;
