import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import VisibleWrapper from "../../../components/VisibleWrapper";
import { rem, theme } from "../../../styling/theme";

const Wrapper = styled(VisibleWrapper)({
  height: rem(3),
  backgroundColor: theme.color.yellowLightColor,
});

const LoadingIndicator = styled.div({
  position: "absolute",
  width: "25%",
  height: rem(3),
  top: 0,
  left: 0,
  backgroundColor: theme.color.yellowColor,
  animation: "moving-object 2s cubic-bezier(0.61, 1, 0.88, 1) infinite",
});

type LineLoadingIndicatorT = {
  visible: boolean;
};

const LineLoadingIndicator: FC<LineLoadingIndicatorT> = ({ visible }) => {
  return (
    <Wrapper visible={visible}>
      <LoadingIndicator />
    </Wrapper>
  );
};

export default LineLoadingIndicator;
