import axios from "axios";
import { ClientBodyT, ClientT } from "../entry-files/types";
import { ApiRoutesE, getApiUrl } from "../helpers/routes";

type updateClientT = (
  clientId: string,
  payload: ClientBodyT,
) => Promise<ClientT>;

export const updateClient: updateClientT = async (clientId, payload) =>
  axios
    .patch(getApiUrl(ApiRoutesE.CLIENTS_ITEM, clientId), payload)
    .then((res) => res.data.client);
