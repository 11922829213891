import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../components/FlexBox";
import { rem, theme } from "../../styling/theme";
import Icon, { IconTypeE } from "../icons/Icon";

type ArrowStyleT = {
  side: "left" | "right";
  disable: boolean;
  visible: boolean;
};

const ArrowWrapper = styled(FlexBox)<ArrowStyleT>(
  ({ side, disable, visible }) => ({
    position: "absolute",
    padding: rem(10),
    top: "50%",
    transform: "translateY(-50%)",
    opacity: !disable ? 0.7 : 0.3,
    left: side === "left" ? rem(5) : "auto",
    right: side === "right" ? rem(5) : "auto",
    transition: "all 0.4s",
    transitionTimingFunction: theme.timing.default,
    cursor: !disable ? "pointer" : "auto",
    visibility: visible ? "visible" : "hidden",
  }),
);

type CarouselArrowT = {
  side: "left" | "right";
  onClickHandle: () => void;
  disable: boolean;
  visible: boolean;
};

const CarouselArrow: FC<CarouselArrowT> = ({
  side,
  disable,
  visible,
  onClickHandle,
}) => {
  return (
    <ArrowWrapper
      disable={disable}
      visible={visible}
      zIndex={1}
      onClick={disable ? undefined : onClickHandle}
      side={side}
    >
      <Icon
        icon={
          side === "left"
            ? IconTypeE.arrowRightCircle
            : IconTypeE.arrowLeftCircle
        }
        size="big"
        color={theme.color.whiteColor}
        withShadow={true}
      />
    </ArrowWrapper>
  );
};

export default CarouselArrow;
