import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../../../styling/theme";
import Icon, { IconTypeE } from "../../../ui/icons/Icon";

const Card = styled.div`
  height: 100%;
  background-color: ${theme.color.backgroundColor};
  border: solid 1px ${theme.color.borderColor};
  border-radius: ${rem(12)};
  padding: ${rem(32)} ${rem(22)};
  color: ${theme.color.primaryColor};
  position: relative;
  font-weight: 600;
  line-height: ${rem(22)};
  font-size: ${rem(16)};
  margin: 0 0 ${rem(8)};

  ${theme.media.lg} {
    width: 100%;
  }
`;

const Line = styled.span`
  position: absolute;
  top: 0;
  right: ${rem(24)};
  left: ${rem(24)};
  background-color: ${theme.color.primaryColor};
  height: ${rem(4)};
`;

const IconWrapper = styled.div`
  display: block;
`;

interface BasicCardT {
  text: string;
}
const BasicCard: FC<BasicCardT> = ({ text }) => {
  return (
    <Card>
      <Line />
      <IconWrapper>
        <Icon icon={IconTypeE.shield} size={24} />
      </IconWrapper>
      {text}
    </Card>
  );
};

export default BasicCard;
