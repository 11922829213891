import axios from "axios";
import { MediaGroupT } from "../types";

type GetMediaGroupsPropsT = {
  apiUrl: string;
};

type getMediaGroupsT = (props: GetMediaGroupsPropsT) => Promise<MediaGroupT[]>;
export const getMediaGroups: getMediaGroupsT = async ({ apiUrl }) => {
  return axios.get(apiUrl).then((res) => res.data.media_groups);
};
