$(document).on("click", ".directUpload", function () {
  directUpload($(this));
});

window.directUpload = function (elem) {
  var fileInput = $(elem);
  var file_form = fileInput.closest("#file_form");
  var only_image = fileInput.data("only-image");
  fileInput.fileupload({
    fileInput: fileInput,
    url: fileInput.data("url"),
    type: "POST",
    autoUpload: true,
    formData: fileInput.data("form-data"),
    paramName: "file", // S3 does not like nested name fields i.e. name="user[avatar_url]"
    dataType: "XML", // S3 returns XML if success_action_status is set to 201
    replaceFileInput: false,
    downloadTemplateId: null,
    uploadTemplateId: null,
    acceptFileTypes: only_image ? /(gif|jpe?g|png|bmp|svg)$/i : null,
    maxFileSize: 10000000, // 10 MB
    messages: {
      acceptFileTypes: "Soubor nemá správný formát",
      maxFileSize: "Soubor je příliš velký",
      minFileSize: "Soubor je příliš malý",
    },
    processfail: function (e, data) {
      var file = data.files[data.index];
      alert(file.error);
    },
    add: function (e, data) {
      $.each(data.files, function (index, file) {
        //set new name here
        var newname = data.files[index].name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "_")
          .replace(/[\s\+]/g, "_");
        Object.defineProperty(data.files[index], "name", {
          value: newname,
        });
      });
      $.blueimp.fileupload.prototype.options.add.call(this, e, data);
    },
    progress: function (e, data) {
      var preview = data.files[0].preview;
      if (typeof preview !== "undefined") {
        fileInput
          .closest(".uploader-image")
          .css("background-image", "url(" + preview.toDataURL() + ")");
      }
    },
    start: function (e) {
      fileInput.closest(".uploader-image").addClass("is-loading");
      fileInput.closest(".uploader-file").addClass("is-loading");
      fileInput.closest(".loading_div").addClass("is-loading");
    },
    done: function (e, data) {
      // extract key and generate URL from response
      var text = $(data.jqXHR.responseXML)
        .find("Location")
        .text()
        .replace(new RegExp("%2F", "g"), "/");
      var url = text;
      var name = url.split("/").pop();
      // span for links upload
      var filesWrapper = $("#links_files_wrapper");
      if (filesWrapper.length && filesWrapper.length > 0) {
        var div = filesWrapper.find(".links_file_input_template:first").clone();
        div.find(".file__name").html(name);
        div.find(".file_url").val(url);
        filesWrapper.append(div);
        $("#links_files_div").removeClass("is-empty");
        div.show();
        fileInput.val("");
      } else {
        // Remove all hidden inputs with same name!
        file_form
          .find('input[type=hidden][name="' + fileInput.attr("name") + '"]')
          .remove();
        // create hidden field
        var input = $("<input />", {
          type: "hidden",
          name: fileInput.attr("name"),
          value: url,
        });
        fileInput.after(input);
      }

      // For campaigns
      fileInput
        .closest(".uploader-image")
        .css("background-image", "url(" + url + ")");
      fileInput.closest(".loading_div").removeClass("is-loading");
      fileInput.closest(".uploader-image").removeClass("is-loading");
      fileInput.closest(".uploader-image").addClass("is-uploaded");
      fileInput.closest(".uploader-file").removeClass("is-loading");
      fileInput.closest(".uploader-file").addClass("is-uploaded");
    },
    fail: function (e, data) {
      alert("Chyba, zkuste refresh..");
    },
  });
};
