import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import Select from "../features/Select";
import InputWrapper from "../ui/InputWrapper";

type TagsInputT = {
  allowNew?: boolean;
  disabled?: boolean;
  isStripped?: boolean;
  label?: string;
  labelDescription?: string;
  noSuggestions?: string;
  placeholder?: string;
  addonBefore?: string;
  setValues: Dispatch<SetStateAction<string[]>>;
  suggestions?: string[];
  values: string[];
};

const TagsInput: FC<TagsInputT> = ({
  allowNew = true,
  disabled = false,
  isStripped,
  label,
  labelDescription,
  noSuggestions,
  placeholder,
  addonBefore,
  setValues,
  suggestions,
  values,
}) => {
  const noSuggestionsText = noSuggestions ? ` ${noSuggestions}` : null;

  const getInput = (): JSX.Element => {
    return (
      <Select
        allowClear={!allowNew}
        value={values}
        mode="tags"
        disabled={disabled}
        maxTagCount="responsive"
        notFoundContent={noSuggestionsText}
        style={{ width: "100%" }}
        placeholder={!disabled && placeholder}
        onChange={setValues}
        options={
          suggestions
            ? suggestions.map((s) => {
                return { label: s, value: s };
              })
            : []
        }
        tokenSeparators={[","]}
        addonBefore={addonBefore}
      />
    );
  };

  if (isStripped) {
    return getInput();
  }

  return (
    <InputWrapper label={label} labelDescription={labelDescription}>
      {getInput()}
    </InputWrapper>
  );
};

export default TagsInput;
