import styled from "@emotion/styled";
import * as React from "react";
import { ChangeEvent, FC, useContext, useState } from "react";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import { CustomWidthE } from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { InfluencerNavigationDetailStepT } from "../../types";
import {
  InfluencerNavigationContext,
  isInfluencerNavigationDetailItem,
} from "../context-providers/InfluencerNavigationContextProvider";
import CheckboxContainer from "./CheckboxContainer";

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: end;
  box-shadow: 8px 2px 9px ${theme.color.boxShadowColor};
  background-color: ${theme.color.whiteColor};
  z-index: 1;

  ${theme.media.lg} {
    padding-left: ${rem(432)};
  }
`;

const Container = styled.div<{ checkbox: boolean }>(({ checkbox }) => ({
  padding: `${rem(20)} ${rem(16)}`,

  [theme.media.sm]: {
    display: "flex",
    justifyContent: checkbox ? "space-between" : "flex-end",
    alignItems: "center",
    gap: rem(16),
    padding: rem(24),
  },

  [theme.media.lg]: {
    paddingRight: rem(74),
  },
}));

const CheckboxWrapper = styled.div`
  margin-bottom: ${rem(20)};

  ${theme.media.sm} {
    margin-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  gap: ${rem(8)};
`;

export interface CheckboxProps {
  label: string;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface FooterProps {
  checkbox?: boolean;
}

const Footer: FC<FooterProps> = ({ checkbox = false, children }) => {
  const [active, setActive] = useState<boolean>(!checkbox);
  const { t } = useTranslate("influencer_root.approval_request");

  const { navigationState, setNavigationState } = useContext(
    InfluencerNavigationContext,
  );
  const navigationDetailState =
    navigationState && isInfluencerNavigationDetailItem(navigationState)
      ? navigationState
      : undefined;
  const currentStep = navigationDetailState
    ? navigationDetailState.step
    : "welcome";

  const stepFlow: InfluencerNavigationDetailStepT[] = [
    "welcome",
    "security",
    "tutorials",
    "approval",
  ];
  const stepIndex = stepFlow.indexOf(currentStep);

  const onClickHandle = (step: InfluencerNavigationDetailStepT): void => {
    if (navigationDetailState) {
      setNavigationState({ ...navigationDetailState, step: step });
    }
  };

  return (
    <Wrapper>
      <Container checkbox={checkbox}>
        {children ? (
          children
        ) : (
          <>
            {checkbox && (
              <CheckboxWrapper>
                <CheckboxContainer
                  active={active}
                  name={"policy"}
                  onChange={() => setActive((prev) => !prev)}
                >
                  <Paragraph paragraph={t("tutorials.agreement")} />
                </CheckboxContainer>
              </CheckboxWrapper>
            )}
            <ButtonWrapper>
              {currentStep != "welcome" && (
                <Button
                  customWidth={CustomWidthE.full}
                  onClick={() => onClickHandle(stepFlow[stepIndex - 1])}
                  type={ButtonTypeE.outline}
                  size={ButtonSizeE.small}
                >
                  {t("footer.back")}
                </Button>
              )}
              <Button
                customWidth={CustomWidthE.full}
                onClick={() => onClickHandle(stepFlow[stepIndex + 1])}
                type={ButtonTypeE.default}
                disabled={!active}
                size={ButtonSizeE.small}
              >
                {t("footer.next")}
              </Button>
            </ButtonWrapper>
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default Footer;
