import { Scope, TranslateOptions } from "i18n-js";
import { useContext } from "react";
import { TranslationsContext } from "../context-providers/TranslationsContextProvider";

type useTranslateReturnT = {
  t: (scope: Scope, options?: TranslateOptions) => string;
};

type useTranslateT = (path?: string) => useTranslateReturnT;
export const useTranslate: useTranslateT = (path) => {
  const { i18n, locale } = useContext(TranslationsContext);

  return {
    t: path ? (key, options) => i18n.t(`${path}.${key}`, options) : i18n.t,
    locale: locale,
  };
};
