import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { SubscriptionPriceEstimateT } from "../types";

type SubscritptionPriceEstimateQueryT = {
  plan: string;
  period: string;
  country_code?: string;
  vat_number?: string;
  currency: string;
};

export const getSubscriptionPlanPriceEstimate = async (
  params: SubscritptionPriceEstimateQueryT,
): Promise<SubscriptionPriceEstimateT> => {
  return axios
    .get(getApiUrl(ApiRoutesE.SUBSCRIPTIONS_PRICE_ESTIMATE), {
      params,
    })
    .then((res) => res.data.subscription_estimate);
};
