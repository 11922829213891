import axios from "axios";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

export const createIgHandle = async (
  handle: string,
): Promise<{ success: boolean }> => {
  return axios
    .post(getUrlWithQuery(getApiUrl(ApiRoutesE.IG_HANDLES), { name: handle }))
    .then((res) => res.data);
};
