import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { rem } from "../../../../styling/theme";

type ValueBoxT = {
  label: string;
  value: string;
};

const ValueBox: FC<ValueBoxT> = ({ label, value }) => {
  return (
    <FlexBox
      flexDirection="column"
      gap={rem(4)}
      alignItems="flex-start"
      customWidth={CustomWidthE.full}
    >
      <Paragraph
        paragraph={label}
        paragraphSize="micro"
        color={designToken.colorTextTertiary}
      />
      <Paragraph paragraph={value} paragraphSize="small" fontWeight={600} />
    </FlexBox>
  );
};
export default ValueBox;
