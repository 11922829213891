const { allowScroll, _o, getGlobalClassState } = require("./helpers");

const MOBILE_VERSION_BREAKPOINT = 992;

const _ = window;
_.MOBILE_VERSION = false;

export const mobileViewHandle = () => {
  const width = document.body.clientWidth;

  if (width && width < MOBILE_VERSION_BREAKPOINT) {
    window.MOBILE_VERSION = true;
    return;
  }

  window.MOBILE_VERSION = false;
};

_.addEventListener("resize", () => {
  const appClasses = document.getElementById("app").className;
  const isScrollDsiabled = appClasses.includes("overflow-hidden");
  const modalOpen = getGlobalClassState("modal-open");

  if (!_.MOBILE_VERSION && !modalOpen && isScrollDsiabled) {
    allowScroll();
  }

  mobileViewHandle();
});

$(document).on("turbolinks:load", () => {
  mobileViewHandle();
});
