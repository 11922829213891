import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FileTypeE } from "../helpers/fileType";
import { useS3Upload } from "./useS3Upload";

export type PendingFileT = {
  id: string;
  file: File;
  type: FileTypeE;
  link: string | null;
  status: FileStatusTypeE | null;
};

export enum FileStatusTypeE {
  pending = "pending",
  willErase = "willErase",
  erased = "erased",
  uploaded = "uploaded",
  failed = "failed",
}

type usePendingFileReturnT = {
  type: FileTypeE;
  temporaryUrl: string;
  uploadedUrl: string | null;
  name: string;
  pending: boolean;
  failure: boolean;
  localState: FileStatusTypeE | null;
  showFile: boolean;
  deleteFileHandle: () => void;
};

type usePendingFileT = (
  pendingFile: PendingFileT,
  setPendingFiles: Dispatch<SetStateAction<PendingFileT[]>>,
) => usePendingFileReturnT;

const usePendingFile: usePendingFileT = (pendingFile, setPendingFiles) => {
  const [localState, setLocalState] = useState<FileStatusTypeE | null>(
    pendingFile.status,
  );

  const { file, type } = pendingFile;
  const { s3fileUrl, isError } = useS3Upload(pendingFile);

  type getExistedFileIndexT = (prevState: PendingFileT[], id: string) => number;
  const getExistedFileIndex: getExistedFileIndexT = (prevState, id) => {
    return prevState.findIndex((file) => file.id === id);
  };

  type updatePendingFileT = (updatedFile: PendingFileT) => void;
  const updatePendingFile: updatePendingFileT = (updatedFile) => {
    setPendingFiles((prevState) => {
      if (prevState !== null) {
        const index = getExistedFileIndex(prevState, updatedFile.id);

        // update file in pending array
        if (index > -1) {
          prevState[index] = updatedFile;
        }

        // splice file from pending array
        if (index > -1 && updatedFile.status === FileStatusTypeE.erased) {
          prevState.splice(index, 1);
        }

        return [...prevState];
      }

      return [];
    });
  };

  useEffect(() => {
    if (s3fileUrl) {
      if (localState === FileStatusTypeE.willErase) {
        setLocalState(FileStatusTypeE.erased);
        return;
      }

      setLocalState(FileStatusTypeE.uploaded);
    }
  }, [s3fileUrl]);

  useEffect(() => {
    if (isError) {
      //setLocalState(FileStatusTypeE.failed); //TODO handle failure message and remove timout and set erased state below
      setTimeout(() => {
        setLocalState(FileStatusTypeE.erased);
      }, 1000);
    }
  }, [isError]);

  useEffect(() => {
    if (localState !== FileStatusTypeE.pending) {
      updatePendingFile({
        id: pendingFile.id,
        file: pendingFile.file,
        link: s3fileUrl,
        type: pendingFile.type,
        status: localState,
      });
    }
  }, [localState]);

  const deleteFileHandle = (): void => {
    if (localState === FileStatusTypeE.willErase) {
      return;
    }

    if (localState === FileStatusTypeE.pending) {
      setLocalState(FileStatusTypeE.willErase);

      return;
    }

    setLocalState(FileStatusTypeE.erased);
  };

  const name =
    file.name.length > 15 ? file.name.substring(0, 12) + "..." : file.name;

  const failure = localState === FileStatusTypeE.failed;
  const pending = localState === FileStatusTypeE.pending;

  const showFile =
    failure || pending || localState === FileStatusTypeE.uploaded;

  const uploadedUrl =
    localState === FileStatusTypeE.uploaded && s3fileUrl ? s3fileUrl : null;

  return {
    type,
    temporaryUrl: URL.createObjectURL(file),
    uploadedUrl,
    name,
    pending,
    failure,
    localState,
    showFile,
    deleteFileHandle,
  };
};

export default usePendingFile;
