import { Form } from "antd";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import Container from "../../../../components/Container";
import Drawer from "../../../../components/Drawer";
import RoutingTabs, { RoutingTabT } from "../../../../features/RoutingTabs";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { MixpanelEventT, trackEvent } from "../../../../helpers/trackEvent";
import { useTranslate } from "../../../../hooks/useTranslate";
import { getCampaign } from "../../data-access/getCampaign";
import { updateCampaignMutation } from "../../data-access/mutation/updateCampaignMutation";
import { updateCampaign } from "../../data-access/updateCampaign";
import { NavigationKeyE } from "../../router";
import { CampaignT } from "../../types";
import EditModal, { FormFieldsT } from "../campaigns/AddNewModal";
import Header from "./Header";

type CampaignContextT = {
  campaign: CampaignT;
};

const CampaignDetailContainer: FC = () => {
  const { id } = useParams() as { id: string };

  const [editModal, setEditModal] = useState<boolean>(false);

  const [form] = Form.useForm<FormFieldsT>();
  const { t } = useTranslate("brands.campaign");

  const tabs: RoutingTabT[] = [
    {
      label: t("partnerships.title"),
      value: "influencers",
    },
    {
      label: t("media_groups.title"),
      value: "media_groups",
    },
  ];

  const { data: campaign, isLoading: isCampaignLoading } = useQuery(
    [QueryKeys.CAMPAIGN_DETAIL, id],
    () => getCampaign(id),
    {
      onSuccess: (data) => {
        form.setFieldsValue({
          brandId: data.client.id,
          title: data.title,
          instagram_mentions: data.instagram_mentions,
          tiktok_mentions: data.tiktok_mentions,
          hashtags: data.hashtags,
          date:
            data.from && data.to
              ? [new Date(data.from), new Date(data.to)]
              : [],
        });
      },
    },
  );

  const queryClient = useQueryClient();
  const { mutate: update, isLoading } = useMutation(
    () => {
      if (!campaign) return Promise.reject();

      return updateCampaign(campaign.id, {
        campaign: {
          title: form.getFieldValue("title"),
          instagram_mentions: form.getFieldValue("instagram_mentions"),
          tiktok_mentions: form.getFieldValue("tiktok_mentions"),
          hashtags: form.getFieldValue("hashtags"),
          from: form.getFieldValue("date")[0],
          to: form.getFieldValue("date")[1],
        },
      });
    },
    {
      onSuccess: (data) => {
        updateCampaignMutation(queryClient, data);
        setEditModal(false);
      },
    },
  );

  useEffect(() => {
    if (!campaign) return;

    trackEvent(MixpanelEventT.campaignsInfluencersListViewImpression, {
      campaignId: campaign.id,
    });
  }, [campaign]);

  if (!campaign) return <></>;

  return (
    <Container>
      {!isCampaignLoading && campaign && (
        <Header campaign={campaign} setEditModal={setEditModal} />
      )}
      <RoutingTabs
        rootPath={NavigationKeyE.Campaign}
        id={campaign.id}
        tabs={tabs}
      />
      <Outlet context={{ campaign } satisfies CampaignContextT} />

      <Drawer
        open={editModal}
        title={t("edit.title")}
        onClose={() => setEditModal(false)}
        onConfirm={{
          label: t("form.submit"),
          handle: () => form.submit(),
          isLoading: isLoading,
        }}
      >
        <EditModal
          form={form}
          newBrandInput={false}
          setNewBrandInput={() => {}}
          onFinishHandle={update}
          mode="edit"
        />
      </Drawer>
    </Container>
  );
};

export const useCampaign = (): CampaignContextT => {
  return useOutletContext<CampaignContextT>();
};

export default CampaignDetailContainer;
