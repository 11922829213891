import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { OverlapAnalysisT } from "../types";

export const getOverlapAnalysis = async (
  id: string,
): Promise<OverlapAnalysisT> => {
  return axios
    .get(getApiUrl(ApiRoutesE.OVERLAP_ANALYSIS_DETAIL, id))
    .then((res) => res.data.overlap_analysis);
};
