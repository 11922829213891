import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { SubscriptionProposalT } from "../types";

export const getSubscriptionProposal =
  async (): Promise<SubscriptionProposalT> => {
    return axios
      .get(getApiUrl(ApiRoutesE.SUBSCRIPTIONS_PROPOSAL))
      .then((res) => res.data.subscription_proposal);
  };
