import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { UserForUserManagementT } from "../../types";
import { updateCurrentUsageMutation } from "./updateCurrentUsageMutation";

export const removeUserMutation = (
  queryClient: QueryClient,
  id: number,
): void => {
  queryClient.setQueryData<UserForUserManagementT[] | undefined>(
    QueryKeys.USERS,
    (prevState) => {
      if (prevState) {
        return prevState.filter((user) => user.id !== id);
      }

      return prevState;
    },
  );

  updateCurrentUsageMutation(queryClient, "users", -1);
};
