import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { SessionT, SubscriptionT } from "../../../types";

export const createSubscriptionMutation = (
  queryClient: QueryClient,
  data: SubscriptionT,
  afterMutationHandle?: () => void,
): void => {
  queryClient.setQueryData<SessionT | undefined>(
    QueryKeys.SESSION,
    (prevState) => {
      if (prevState) {
        return { ...prevState, subscription: data };
      }

      return prevState;
    },
  );

  afterMutationHandle && afterMutationHandle();
};
