import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { MediaT, UpdatedMediaDatatT } from "../types";

type UpdatedMediaPropsT = {
  id: string;
  updatedMediaData: {
    media: UpdatedMediaDatatT;
  };
};

type UpdateMediaPayloadT = {
  media: MediaT;
};

type updateMediaT = (props: UpdatedMediaPropsT) => Promise<UpdateMediaPayloadT>;
export const updateMedia: updateMediaT = async ({ id, updatedMediaData }) => {
  return axios
    .put(getApiUrl(ApiRoutesE.MEDIAS_ITEM, id), updatedMediaData)
    .then((res) => res.data);
};
