import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { CampaignT, PayloadCampaignT } from "../types";

export const createCampaign = async (payload: {
  campaign: PayloadCampaignT;
}): Promise<CampaignT> => {
  return axios
    .post(getApiUrl(ApiRoutesE.CAMPAIGNS), payload)
    .then((res) => res.data.campaign);
};
