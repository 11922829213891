import { roundNumber } from "../../../../../../helpers/formaters";
import { theme } from "../../../../../../styling/theme";

export const getoptionsEngagementHistogram = (
  tooltipData: { [key: number]: string }[],
  engagementRate: number,
  medianValue?: number,
) => {
  return {
    responsive: true,
    layout: {
      padding: {
        top: 20,
        right: 10,
        bottom: 33,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      medianAndUser: {
        greyColor: theme.color.greyLightColor,
        blueColor: theme.color.darkPrimaryColor,
        tooltipText: `${roundNumber(engagementRate * 100, 2)}%`,
        medianText: "Median",
        medianNumber: `${roundNumber(
          (medianValue ? medianValue : 0) * 100,
          2,
        )}%`,
      },
      tooltip: {
        yAlign: "bottom" as const,
        callbacks: {
          label: (tooltip: any) => {
            const { raw } = tooltip;

            return `${raw} accounts`;
          },
          title: (tooltip: any) => {
            const { label } = tooltip[0];
            const index = tooltipData.findIndex((o) => Object.keys(o) == label);

            return tooltipData[index][label];
          },
        },
      },
    },
    scales: {
      y: {
        ticks: { display: false },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      x: {
        ticks: { display: false },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
  };
};
