import { useMutation, useQueryClient } from "react-query";
import { updateMedia } from "../entry-files/company-root/data-access/updateMedia";
import {
  MediaGroupT,
  MutateMediaDataT,
  QueryStackT,
} from "../entry-files/company-root/types";

type mutateMediaT = (data: MutateMediaDataT) => void;

type useMutateMediaT = (
  mediaId: string,
  queryStack: QueryStackT,
  onSuccessHandle?: () => void,
) => { isDisabled: boolean; mutateMedia: mutateMediaT };

export const useMutateMedia: useMutateMediaT = (
  mediaId,
  queryStack,
  onSuccessHandle,
) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(updateMedia, {
    onSuccess: (data) => {
      queryClient.setQueryData<MediaGroupT[] | undefined>(
        queryStack,
        (prevState) => {
          if (prevState) {
            const currentMediaGroupIndex = prevState.findIndex(
              (mediaGroup) => mediaGroup.id === data.media.media_group_id,
            );

            if (currentMediaGroupIndex > -1) {
              const currentMediaIndex = prevState[
                currentMediaGroupIndex
              ].medias.findIndex((media) => media.id === data.media.id);

              if (currentMediaIndex > -1) {
                prevState[currentMediaGroupIndex].medias[currentMediaIndex] =
                  data.media;
              }
            }
          }

          return prevState;
        },
      );

      onSuccessHandle && onSuccessHandle();
    },
  });

  const mutateMedia: mutateMediaT = (data) => {
    mutate({
      id: mediaId,
      updatedMediaData: {
        media: {
          id: mediaId,
          ...data,
        },
      },
    });
  };

  return {
    isDisabled: isLoading,
    mutateMedia,
  };
};
