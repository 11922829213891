import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ListT } from "../types";

export const removeContactNote = async (
  id: string,
  networkProfileId: string,
): Promise<ListT> => {
  return axios
    .delete(
      getApiUrl(ApiRoutesE.NETWORK_PROFILES_CONTACT_NOTE, id, networkProfileId),
    )
    .then((res) => res.data);
};
