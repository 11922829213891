import { theme as antTheme } from "antd";
import { theme } from "../styling/theme";

const { getDesignToken } = antTheme;

export const defaults = {
  token: {
    colorPrimary: theme.color.primaryColor,
    colorLink: theme.color.primaryColor,
  },
  components: {
    Progress: {
      defaultColor: theme.color.primaryColor,
      remainingColor: theme.color.remainingColor,
    },
  },
};

export const designToken = getDesignToken(defaults);
