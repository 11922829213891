import * as React from "react";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  InfluencerNavigationDetailItemT,
  InfluencerNavigationDetailStepT,
  InfluencerNavigationItemT,
  InfluencerNavigationStateT,
} from "../../types";

type InfluencerNavigationContextT = {
  navigationState: InfluencerNavigationStateT | undefined;
  setNavigationState: Dispatch<
    SetStateAction<InfluencerNavigationStateT | undefined>
  >;
};

export const InfluencerNavigationContext =
  createContext<InfluencerNavigationContextT>({
    navigationState: "approvals",
    setNavigationState: () => {},
  });

const isOfType = (value: string): value is InfluencerNavigationItemT =>
  ["approvals", "approval_requests"].includes(value);

const isInfluencerNavigationDetailStep = (
  value: string,
): value is InfluencerNavigationDetailStepT =>
  [
    "approval",
    "approved_not_logged",
    "email_missing",
    "email_rejected",
    "email_updated",
    "handle_mismatch",
    "no_account_available",
    "not_approved",
    "scopes_missing",
    "security",
    "session_mismatch",
    "success",
    "tutorials",
    "user_cancelled",
    "welcome",
  ].includes(value);

export const isInfluencerNavigationDetailItem = (
  value: InfluencerNavigationDetailItemT | InfluencerNavigationItemT,
): value is InfluencerNavigationDetailItemT => {
  return (value as InfluencerNavigationDetailItemT).key !== undefined;
};

const urlParam = (value: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(value);
};

const getCurrentStep = (): InfluencerNavigationDetailStepT => {
  const step = urlParam("step") || "welcome";
  if (step.length > 0 && isInfluencerNavigationDetailStep(step)) {
    return step;
  }

  return "welcome";
};

export const getCurrentUrlKey = (): InfluencerNavigationStateT => {
  const pathName = window.location.pathname;
  const queryResult = pathName
    .split("/")
    .filter((item) => !["", "influencers", "reporting"].includes(item));

  if (queryResult.length > 0 && isOfType(queryResult[0])) {
    if (queryResult[0] === "approval_requests" && queryResult[1]) {
      return {
        key: queryResult[0],
        id: queryResult[1],
        step: getCurrentStep(),
      };
    }
    return queryResult[0];
  }

  return "approvals";
};

export const InfluencerNavigationContextProvider: FC = ({ children }) => {
  const [navigationState, setNavigationState] =
    useState<InfluencerNavigationStateT>();

  const setCurrentKeyToNavigationState = (): void => {
    const currentUrlKey = getCurrentUrlKey();

    if (currentUrlKey) {
      setNavigationState(currentUrlKey);
    }
  };

  useEffect(() => {
    setCurrentKeyToNavigationState();
  }, []);

  useEffect(() => {
    if (!navigationState) {
      setCurrentKeyToNavigationState();
      return;
    }

    if (navigationState !== getCurrentUrlKey()) {
      if (isInfluencerNavigationDetailItem(navigationState)) {
        let path = `/${navigationState.key}/${navigationState.id}?step=${navigationState.step}`;
        const unconfirmed_email = urlParam("unconfirmed_email");
        if (unconfirmed_email) {
          path = path.concat(`&unconfirmed_email=${unconfirmed_email}`);
        }

        window.history.pushState("", "", path);
        return;
      }
      window.history.pushState(
        "",
        "",
        `/influencers/reportings/${navigationState}`,
      );
    }
  }, [navigationState]);

  return (
    <InfluencerNavigationContext.Provider
      value={{
        navigationState,
        setNavigationState,
      }}
    >
      {children}
    </InfluencerNavigationContext.Provider>
  );
};
