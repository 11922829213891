import { QueryClient } from "react-query";
import { MediaGroupT, QueryStackT } from "../../types";

type removeMediaFileMutationT = (
  queryClient: QueryClient,
  data: {
    mediaFileId: string;
    mediaId: string;
  },
  queryStack: QueryStackT,
  afterMutationHandle?: () => void,
) => void;

export const removeMediaFileMutation: removeMediaFileMutationT = (
  queryClient,
  data,
  queryStack,
  afterMutationHandle,
) => {
  queryClient.setQueryData<MediaGroupT[] | undefined>(
    queryStack,
    (prevState) => {
      if (prevState) {
        const { mediaFileId, mediaId } = data;

        const currentMediaGroupIndex = prevState.findIndex((mediaGroup) =>
          mediaGroup.medias.find((media) => media.id === mediaId),
        );

        if (currentMediaGroupIndex > -1) {
          const currentMediaIndex = prevState[
            currentMediaGroupIndex
          ].medias.findIndex((media) => media.id === mediaId);

          if (currentMediaIndex > -1) {
            const currentMediaFileIndex = prevState[
              currentMediaGroupIndex
            ].medias[currentMediaIndex].media_files.findIndex(
              (file) => file.id === mediaFileId,
            );

            if (currentMediaFileIndex > -1) {
              prevState[currentMediaGroupIndex].medias[
                currentMediaIndex
              ].media_files.splice(currentMediaFileIndex, 1);
            }
          }
        }
      }

      return prevState;
    },
  );

  afterMutationHandle && afterMutationHandle();
};
