import styled from "@emotion/styled";
import * as React from "react";
import { FC, ReactNode } from "react";
import Navigation from "../entry-files/company-root/features/navigation";
import { rem, theme } from "../styling/theme";

export const defaultSubmenuWidth = 216;

const Wrapper = styled.div<{
  leftMargin: number;
  noMargin?: boolean;
  isRelative?: boolean;
}>(({ leftMargin, noMargin = false, isRelative = false }) => ({
  width: "100%",
  position: isRelative ? "relative" : "static",
  maxWidth: rem(theme.containerSizes.monsterContainerWidth - leftMargin),
  margin: noMargin
    ? `0 0 0 ${rem(leftMargin)}`
    : `${rem(16)} 0 auto ${rem(leftMargin)}`,
  padding: `0 ${rem(16)}`,

  [theme.media.maxXXl]: {
    maxWidth: rem(theme.containerSizes.bigContainerWidth - leftMargin),
  },

  [theme.media.maxXl]: {
    maxWidth: `calc(100dvw - ${rem(leftMargin + 48)})`,
  },

  [theme.media.maxMd]: {
    maxWidth: rem(theme.containerSizes.baseContainerWidth),
    margin: noMargin ? "0 auto" : `${rem(16)} auto ${rem(52)}`,
  },
}));

type ContainerT = {
  noMargin?: boolean;
  isRelative?: boolean;
  submenuWidth?: number;
  brandNavigation?: boolean;
  renderSubmenu?: (submenuWidth: number) => ReactNode;
};

const Container: FC<ContainerT> = ({
  noMargin,
  isRelative,
  submenuWidth = defaultSubmenuWidth,
  brandNavigation = true,
  renderSubmenu,
  children,
}) => {
  return (
    <>
      {brandNavigation && <Navigation />}
      {renderSubmenu !== undefined && renderSubmenu(submenuWidth)}

      <Wrapper
        noMargin={noMargin}
        isRelative={isRelative}
        leftMargin={renderSubmenu !== undefined ? submenuWidth : 0}
      >
        {children}
      </Wrapper>
    </>
  );
};

export default Container;
