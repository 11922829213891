import styled from "@emotion/styled";
import { Checkbox } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import * as React from "react";
import { FC, useState } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";

const CheckboxWrapper = styled(FlexBox)({
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
});

const CheckboxItem = styled(FlexBox)({
  textWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginBottom: rem(4),
});

const ExpandButton = styled(FlexBox)({
  cursor: "pointer",
  gap: rem(5),
  borderBottom: `1px solid transparent`,
  paddingTop: rem(5),

  ["&:hover"]: {
    borderBottom: `1px solid ${theme.color.textLightGreyColor}`,
  },
});

interface ExpandableSectionT {
  items: string[];
  values: string[];
  setValues: (values: CheckboxValueType[]) => void;
}

const ExpandableSection: FC<ExpandableSectionT> = ({
  items,
  values,
  setValues,
}) => {
  const [short, setShort] = useState<boolean>(true);

  const { t } = useTranslate("interests");

  return (
    <>
      <Checkbox.Group onChange={setValues} value={values}>
        <CheckboxWrapper>
          {items.slice(0, short ? 6 : 200).map((item) => (
            <CheckboxItem key={item}>
              <Checkbox value={item} checked={values.includes(item)}>
                {t(item)}
              </Checkbox>
            </CheckboxItem>
          ))}
        </CheckboxWrapper>
      </Checkbox.Group>
      <ExpandButton onClick={() => setShort((prev) => !prev)}>
        <Icon
          icon={IconTypeE.arrowBottom}
          color={theme.color.textLightGreyColor}
          size="mini"
          rotate={short ? "0deg" : "180deg"}
        />
        <Paragraph
          paragraph={t(short ? "more" : "less")}
          paragraphSize="small"
          color={theme.color.textGreyColor}
        />
      </ExpandButton>
    </>
  );
};

export default ExpandableSection;
