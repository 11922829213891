import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

type removePartnershipPropsT = {
  partnershipId: string;
  campaignId: string;
};

export const removePartnership = async ({
  campaignId,
  partnershipId,
}: removePartnershipPropsT): Promise<{}> => {
  return axios
    .delete(
      getApiUrl(ApiRoutesE.CAMPAIGNS_PARTNERSHIP, partnershipId, campaignId),
    )
    .then((res) => res.data);
};
