import { FunnelPlotOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Input, Modal } from "antd";
import { debounce, omit } from "lodash";
import * as React from "react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import Container from "../../../../components/Container";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import SubMenuContainer from "../../../../components/SubMenuContainer";
import {
  getDateForRangeDayFromToday,
  getSystemDate,
} from "../../../../helpers/date";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { NetworkProfileT } from "../../../types";
import { SubscriptionContext } from "../../context-providers/SubscriptionsContextProvider";
import { ToastContext } from "../../context-providers/ToastContextProvider";
import { createNetworkProfile } from "../../data-access/createNetworkProfile";
import { getFilterData } from "../../data-access/getFilterData";
import { getNetworkProfiles } from "../../data-access/getNetworkProfiles";
import Search from "./components/Search";
import Filter from "./Filter";
import Table from "./table/Table";
import { SearchQueryT } from "./types";

const ModalDescription = styled(Paragraph)({
  marginBottom: rem(16),
});

const Wrapper = styled(FlexBox)({
  flexDirection: "column",
  marginBottom: rem(16),
  borderRadius: rem(8),
});

const SearchWrapper = styled(FlexBox)({
  width: "100%",
  justifyContent: "flex-start",
  borderRadius: `${rem(8)} ${rem(8)} 0 0`,
  padding: `${rem(12)} ${rem(12)} 0 ${rem(12)}`,
  backgroundColor: theme.color.whiteColor,
});

export type DatabaseTypeT = "czsk" | "global";

export const defaultSearchQuery: SearchQueryT = {
  age_groups: ["13", "65+"],
  cities: [],
  countries: [],
  credibility_from: undefined,
  credibility_range: undefined,
  engagement_rate_from: undefined,
  engagement_rate_range: undefined,
  followers_gender_from: 51,
  followers_gender_type: undefined,
  followers_interests: [],
  hashtags: [],
  influencer_gender: undefined,
  mentions: [],
  outputs_from: getSystemDate(getDateForRangeDayFromToday(-365)),
  people_count_from: undefined,
  people_count_to: undefined,
  profile_interests: [],
  provider_eq: "instagram",
  tags: [],
  page: 1,
  sort: "people_count:desc",
};

const InfluencerDatabaseContainer: FC = () => {
  const [searchQuery, setSearchQuery] =
    useState<SearchQueryT>(defaultSearchQuery);
  const [addNewProfile, setAddNewProfile] = useState<boolean>(false);
  const [handle, setHandle] = useState<string>("");
  const [listNetworkProfiles, setListNetworkProfiles] =
    useState<NetworkProfileT[]>();
  const [options, setOptions] = useState<NetworkProfileT[]>([]);
  const [hiddenFilter, setHiddenFilter] = useState<boolean>(true);

  const queryWithoutOmits = omit(searchQuery, ["sort", "provider_eq"]);
  const defaultWithoutOmits = omit(defaultSearchQuery, ["sort", "provider_eq"]);
  const usedFilter =
    JSON.stringify(queryWithoutOmits) !== JSON.stringify(defaultWithoutOmits);

  const { openToast } = useContext(ToastContext);
  const { isTrial, openTrialModal } = useContext(SubscriptionContext);

  const { t } = useTranslate("brands.influencer_database");

  const {
    data: networkProfilesResponse,
    isLoading,
    isRefetching,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    [QueryKeys.NETWORK_PROFILES, searchQuery.page],
    ({ pageParam = 1 }) => getNetworkProfiles(searchQuery, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        const sum = pages
          .map((page) => page.network_profiles.length)
          .reduce((a, b) => a + b, 0);

        if (lastPage.meta.total > sum) {
          return pages.length + 1;
        }

        return undefined;
      },
    },
  );

  const { data: filterData, isLoading: isFilterLoading } = useQuery(
    QueryKeys.NETWORK_PROFILES_FILTER,
    () => getFilterData(),
  );

  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    () => createNetworkProfile(handle, searchQuery.provider_eq),
    {
      onSuccess: (data) => {
        setHandle("");
        setAddNewProfile(false);
        if (data.status == "created") {
          openToast({
            type: "success",
            message: t("add_new_profile.notification.success"),
          });
        } else {
          openToast({
            type: "error",
            message: t("add_new_profile.notification.error"),
          });
        }
      },
    },
  );

  const setFilterSearchQuery = (query: Partial<SearchQueryT>): void => {
    setSearchQuery((prev) => ({ ...prev, ...query, page: 1 }));
  };

  const onAddNewProfileHandle = (): void => {
    isTrial ? openTrialModal() : setAddNewProfile(true);
  };

  const search = useRef(debounce(() => refetch(), 500)).current;
  useEffect(() => {
    search();
  }, [searchQuery]);

  return (
    <>
      {filterData && (
        <>
          <Container
            submenuWidth={312}
            renderSubmenu={(width) => (
              <SubMenuContainer
                width={width}
                floatingButton={{
                  title: t("filter.title"),
                  icon: <FunnelPlotOutlined />,
                  badge: usedFilter,
                }}
                overrideHidden={{
                  value: hiddenFilter,
                  setValue: setHiddenFilter,
                }}
              >
                <Filter
                  searchQuery={searchQuery}
                  setFilterSearchQuery={setFilterSearchQuery}
                  data={filterData}
                  isLoading={isFilterLoading}
                  usedFilter={usedFilter}
                  listNetworkProfiles={listNetworkProfiles}
                  setListNetworkProfiles={setListNetworkProfiles}
                  hiddenFilter={hiddenFilter}
                  setHiddenFilter={setHiddenFilter}
                  setOptions={setOptions}
                />
              </SubMenuContainer>
            )}
          >
            <Wrapper>
              {!listNetworkProfiles && (
                <SearchWrapper>
                  <FlexBox customWidth={CustomWidthE.half}>
                    <Search
                      provider={searchQuery.provider_eq}
                      options={options}
                      setOptions={setOptions}
                    />
                  </FlexBox>
                  <FlexBox
                    customWidth={CustomWidthE.half}
                    justifyContent="flex-end"
                  >
                    <Button
                      icon={<PlusOutlined rev="" />}
                      onClick={onAddNewProfileHandle}
                    >
                      {t("add_new_profile.button")}
                    </Button>
                  </FlexBox>
                </SearchWrapper>
              )}
              <Table
                data={networkProfilesResponse}
                dataIsLoading={isLoading || isRefetching}
                onAddNewProfileHandle={onAddNewProfileHandle}
                onLoadMoreHandle={fetchNextPage}
                hasNextPage={hasNextPage}
                searchQuery={searchQuery}
                setFilterSearchQuery={setFilterSearchQuery}
                totalsCount={filterData.totals}
                listNetworkProfiles={listNetworkProfiles}
              />
            </Wrapper>
            <Modal
              title={t("add_new_profile.title")}
              open={addNewProfile}
              onOk={() => create()}
              okButtonProps={{
                disabled: handle.length < 3 || isCreateLoading,
                loading: isCreateLoading,
              }}
              onCancel={() => setAddNewProfile(false)}
            >
              <ModalDescription
                paragraph={t("add_new_profile.description")}
                paragraphSize="small"
                color={theme.color.textGreyColor}
              />
              <Input
                addonBefore="@"
                autoFocus
                value={handle}
                placeholder={t("add_new_profile.placeholder")}
                onChange={(e) => setHandle(e.target.value)}
              />
            </Modal>
          </Container>
        </>
      )}
    </>
  );
};

export default InfluencerDatabaseContainer;
