import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { OverlapAnalysisT } from "../../types";

export const removeOverlapAnalysisMutation = (
  queryClient: QueryClient,
  id: string,
): void => {
  queryClient.setQueryData<OverlapAnalysisT[] | undefined>(
    QueryKeys.OVERLAP_ANALYSES,
    (prevState) => {
      if (prevState) {
        return prevState.filter((item) => item.id !== id);
      }

      return prevState;
    },
  );
};
