import * as React from "react";
import { Dispatch, FC, SetStateAction, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import Button, { ButtonTypeE } from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../../components/Heading";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { TRANSITION_TIME } from "../../../../../ui/cards-ui/TransitionWrapper";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import { hideCampaignMediaGroup } from "../../../data-access/hideCampaignMediaGroup";
import { removeMediaGroupMutation } from "../../../data-access/mutation/removeMediaGroupMutation";
import { MediaGroupToRemoveStateT } from "../MediaGoupsContainer";

type RemoveMediaGroupModalT = {
  mediaGroupToRemove: MediaGroupToRemoveStateT;
  setRemoveMediaDisable: Dispatch<SetStateAction<boolean>>;
  isDisabled: boolean;
};

const RemoveMediaGroupModal: FC<RemoveMediaGroupModalT> = ({
  mediaGroupToRemove,
  setRemoveMediaDisable,
  isDisabled,
}) => {
  const { t } = useTranslate("brands.campaign.media_groups");
  const { setMediaGroupToRemove } = useContext(MediaGroupsHelperContext);

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    () =>
      hideCampaignMediaGroup(
        mediaGroupToRemove.mediaGroupId,
        mediaGroupToRemove.campaignId,
      ),
    {
      onMutate: () => {
        setRemoveMediaDisable(true);
      },
      onSuccess: () => {
        if (mediaGroupToRemove) {
          setMediaGroupToRemove((prevState) => {
            if (prevState) {
              return { ...prevState, removed: true };
            }

            return prevState;
          });

          setTimeout(() => {
            removeMediaGroupMutation(
              queryClient,
              mediaGroupToRemove,
              clearStatesAfterRemoving,
              mediaGroupToRemove.queryStack,
            );
          }, TRANSITION_TIME);
        }
      },

      onError: () => {
        clearStatesAfterRemoving();
      },
    },
  );

  const clearStatesAfterRemoving = (): void => {
    setMediaGroupToRemove(undefined);
    setRemoveMediaDisable(false);
  };

  const removeMediaHandle = (): void => {
    if (mediaGroupToRemove) {
      mutate();
    }
  };

  return (
    <FlexBox flexDirection="column" alignItems="stretch">
      <Heading
        headingType={HeadingTypeT.h3}
        heading={t("modal_remove_title")}
        spaceBottom={10}
      />
      <Paragraph paragraph={t("modal_remove_question")} />
      <Spacer size="big" direction="vertical" />
      <FlexBox customWidth={CustomWidthE.full}>
        <Button
          type={ButtonTypeE.darkGrey}
          customWidth={CustomWidthE.full}
          onClick={() => setMediaGroupToRemove(undefined)}
        >
          {t("buttons.modal_close")}
        </Button>
        <Spacer />
        <Button
          type={ButtonTypeE.error}
          customWidth={CustomWidthE.full}
          disabled={isDisabled}
          onClick={removeMediaHandle}
        >
          {t("buttons.confrim_remove_post")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default RemoveMediaGroupModal;
