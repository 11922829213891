import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { CompetitiveAnalysisT } from "../types";

type createCompetitiveAnalysisPayloadT = {
  competitive_analysis: {
    handle: string;
  };
};

export const createCompetitiveAnalysis = async (
  payload: createCompetitiveAnalysisPayloadT,
): Promise<{ competitive_analysis: CompetitiveAnalysisT }> => {
  return axios
    .post(getApiUrl(ApiRoutesE.COMPETITIVE_ANALYSES), payload)
    .then((res) => res.data);
};
