import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import {
  ContactNoteT,
  ContactT,
} from "../../features/influencer-database-detail/types";

export const createContactNoteMutation = (
  queryClient: QueryClient,
  data: ContactNoteT,
  afterMutationHandle?: () => void,
): void => {
  queryClient.setQueryData<ContactT | undefined>(
    QueryKeys.NETWORK_PROFILES_CONTACT,
    (prevState) => {
      if (prevState) {
        return { ...prevState, notes: [data, ...prevState.notes] };
      }

      return prevState;
    },
  );

  afterMutationHandle && afterMutationHandle();
};
