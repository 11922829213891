import { _o } from "./helpers";

type hideMessageT = (message: Element) => void;
const hideMessage: hideMessageT = (message) => {
  setTimeout(() => {
    message.classList.add("flash-message--hidden");
  }, 3000);

  setTimeout(() => {
    message.innerHTML = "";
  }, 3700);
};

export const showErrorMessage = (value: string): void => {
  const wrapper = document.getElementsByClassName("flash-message-wrapper")[0];
  wrapper.innerHTML = `<div class="panel panel--notifiaction-error flash-message">
    <div class="panel-body panel-body--small-padding panel-body--small-margin">
        <div>${value}</div>
      <a class="panel-close-button panel-close-button--error" onclick="$(this).closest('.flash-message').hide();return false;" href="#">
        <div class="svg micro">
          <svg class="svg-body" xmlns:xlink="http://www.w3.org/1999/xlink">
            <use xlink:href="/packs/media/images/icons-d722f6bbf124428c0365c95b7a2fd998.svg#ico-cross" x="0" y="0" width="100%" height="100%"></use>
          </svg>
        </div>
      </a>
    </div>
  </div>`;
  hideMessage(wrapper);
};

export const showMessage = (value: string): void => {
  const wrapper = document.getElementsByClassName("flash-message-wrapper")[0];
  wrapper.innerHTML = `<div class="panel panel--notifiaction-yellow flash-message">
    <div class="panel-body panel-body--small-padding panel-body--small-margin">
        <div>${value}</div>
      <a class="panel-close-button panel-close-button--yellow" onclick="$(this).closest('.flash-message').hide();return false;" href="#">
        <div class="svg micro">
          <svg class="svg-body" xmlns:xlink="http://www.w3.org/1999/xlink">
            <use xlink:href="/packs/media/images/icons-d722f6bbf124428c0365c95b7a2fd998.svg#ico-cross" x="0" y="0" width="100%" height="100%"></use>
          </svg>
        </div>
      </a>
    </div>
  </div>`;
  hideMessage(wrapper);
};

export const flashMessagesInit = (): void => {
  const flashMessages = _o(document.getElementsByClassName("flash-message"));

  if (flashMessages) {
    flashMessages.forEach((message) => {
      hideMessage(message);
    });
  }
};
