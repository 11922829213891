import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { InfluencerNavigationDetailStepT } from "../../types";
import CircleIcon from "./CircleIcon";

const FlowBarText = styled.div`
  display: none;
  ${theme.media.lg} {
    display: block;
  }
`;

const Wrapper = styled.li<{ active: boolean }>`
  display: flex;
  min-height: auto;
  position: relative;

  ${theme.media.lg} {
    min-height: ${rem(110)};
    width: 100%;

    &:last-child {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 32px;
        bottom: 0;
        background-color: ${theme.color.backgroundColor};
        ${({ active }) => (active ? `top: 32px;` : `top: 34px;`)}
    }
  }
`;

const FlowBarWraper = styled.div`
  margin-right: 0;
  ${theme.media.lg} {
    margin-right: ${rem(24)};
  }
`;

const Title = styled.div`
  margin: 0;
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  color: ${theme.color.darkPrimaryColor};
`;

const Text = styled.div`
  margin: 0;
  font-weight: 400;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  color: ${theme.color.blackColor};
  opacity: 0.4;
`;

const Icon = styled(CircleIcon)<{ isActive: boolean }>`
  color: ${({ isActive }) =>
    isActive ? theme.color.whiteColor : theme.color.primaryColor};
  ${({ isActive }) =>
    isActive ? undefined : `background-color: ${theme.color.whiteColor}`};
`;

interface NavigationItemProps {
  icon: React.ReactNode;
  step: InfluencerNavigationDetailStepT;
  active: boolean;
}

const NavigationItem: FC<NavigationItemProps> = ({ icon, step, active }) => {
  const { t } = useTranslate("influencer_root.approval_request");

  return (
    <Wrapper active={active}>
      <FlowBarWraper>
        <Icon
          variant={active ? "background" : "outline"}
          isActive={active}
          color={theme.color.darkPrimaryColor}
        >
          {icon}
        </Icon>
      </FlowBarWraper>
      <FlowBarText>
        <Title>{t(`menu.${step}.title`)}</Title>
        <Text>{t(`menu.${step}.text`)}</Text>
      </FlowBarText>
    </Wrapper>
  );
};

export default NavigationItem;
