import styled from "@emotion/styled";
import FlexBox from "../../components/FlexBox";
import { theme } from "../../styling/theme";

export const TRANSITION_TIME = 300;

const TransitionWrapper = styled(FlexBox)({
  transition: `all ${TRANSITION_TIME}ms`,
  transitionTimingFunction: theme.timing.default,
  overflow: "hidden",
});

export default TransitionWrapper;
