import * as React from "react";
import { FC } from "react";
import { Bar } from "react-chartjs-2";
import { theme } from "../../../../../../styling/theme";
import { AudienceReachabilityCardCardDataT } from "../../types";
import {
  customTicksAudienceReachibility,
  getOptionsAudienceReachibility,
} from "./options";

export type AudienceReachibilityT = {
  data: AudienceReachabilityCardCardDataT;
};

const AudienceReachibility: FC<AudienceReachibilityT> = ({ data }) => {
  const values = data.map((s) => Object.values(s)).map((a) => a.join());
  const labels = data.map((s) => Object.keys(s)).map((a) => a.join());

  const chartData = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: [
          theme.color.primaryColor,
          theme.color.lightPrimaryColor,
          theme.color.yellowColor,
          theme.color.yellowHoverColor,
        ],
        barThickness: 16,
        borderRadius: 5,
      },
    ],
  };

  return (
    <Bar
      data={chartData}
      options={getOptionsAudienceReachibility(values)}
      plugins={[customTicksAudienceReachibility]}
    />
  );
};

export default AudienceReachibility;
