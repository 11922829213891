import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../components/FlexBox";
import {
  FileTypeE,
  getFileExtension,
  getFileType,
} from "../../../../../helpers/fileType";
import { rem, theme } from "../../../../../styling/theme";

const Wrapper = styled(FlexBox)({
  minHeight: rem(550),
  maxHeight: "80vh",
  overflow: "scroll",
  backgroundColor: theme.color.backgroundColor,
});

const StyledVideo = styled.video({
  width: "100%",
  margin: "auto",
  maxHeight: "100%",
});

const StyledImage = styled.img({
  width: "auto",
  margin: "auto",
  maxHeight: "100%",
});

type LightBoxItemT = {
  url: string;
};

const LightBoxItem: FC<LightBoxItemT> = ({ url }) => {
  const fileType = getFileType(getFileExtension(url));

  return (
    <Wrapper alignItems="flex-start" fullHeight={true}>
      {fileType === FileTypeE.video ? (
        <StyledVideo controls={true} width="100%" src={url} />
      ) : (
        <StyledImage src={url} />
      )}
    </Wrapper>
  );
};

export default LightBoxItem;
