import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { useInfiniteQuery } from "react-query";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import NoResults from "../../../../../components/NoResults";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../features/Placeholders/PlaceholderLoader";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import { IllustrationTypeE } from "../../../../../ui/Illustrations";
import InfinityScrollContainer from "../../../../../ui/InfinityScrollContainer";
import OutputSelect from "../../../../../ui/OutputSelect";
import { ExistedOutputT, MediaGroupT, MediaT } from "../../../types";
import {
  ExistedMediaGroupParamsModalT,
  ExistedModalAttributesT,
  ExistedOutputsModalVariantT,
  ExistedOutputsParamsModalT,
  isMedia,
  isMediaGroup,
  isMediaGroupParams,
} from "./ExistedOutputsModal";

const Subtitle = styled(Paragraph)({
  padding: `${rem(0)} ${rem(5)} ${rem(5)} ${rem(5)}`,
  color: theme.color.textGreyColor,
});

export const isMediaGroups = (
  value: ExistedOutputT[] | MediaGroupT[],
): value is MediaGroupT[] => {
  return (value as MediaGroupT[])[0].partnership !== undefined;
};

type ExistedModalScrollT = {
  modalAttributes: ExistedModalAttributesT;
  hidden: boolean;
  subtitle?: string;
  getData: (
    variant: ExistedOutputsModalVariantT,
    params: ExistedOutputsParamsModalT | ExistedMediaGroupParamsModalT,
    pageParam: number,
  ) => Promise<ExistedOutputT[] | MediaGroupT[]>;
  localExistedOutput: MediaGroupT | ExistedOutputT | MediaT | undefined;
  setLocalExistedOutput: Dispatch<
    SetStateAction<ExistedOutputT | MediaGroupT | MediaT | undefined>
  >;
};

const ExistedModalScroll: FC<ExistedModalScrollT> = ({
  modalAttributes,
  hidden,
  subtitle,
  getData,
  localExistedOutput,
  setLocalExistedOutput,
}) => {
  const { requestParams, variant } = modalAttributes;
  const currentPartnershipId = isMediaGroupParams(requestParams)
    ? requestParams.partnership_ids[0]
    : requestParams.partnership_id;
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      `existedOutputs-${variant}-${requestParams.type}-${currentPartnershipId}`,
      ({ pageParam = 1 }) => getData(variant, requestParams, pageParam),
      {
        getNextPageParam: (lastPage, pages) => {
          if (lastPage && lastPage.length > 0) {
            return pages.length + 1;
          }

          return undefined;
        },
      },
    );

  const { t } = useTranslate("brands.campaign.media_groups.existed");

  type getOutputReturnT = {
    id: string;
    url: string | null;
    timestamp: string | null;
  };
  const getOutput = (
    item: ExistedOutputT | MediaGroupT | MediaT,
  ): getOutputReturnT => {
    if (isMediaGroup(item)) {
      return {
        id: item.id,
        url: item.medias[0].preview_url,
        timestamp: item.medias[0].published_at,
      };
    }
    if (isMedia(item)) {
      return {
        id: item.id,
        url: item.preview_url,
        timestamp: item.published_at,
      };
    }

    return {
      id: item.id,
      url: item.media_url ? item.media_url : null,
      timestamp: item.timestamp,
    };
  };

  const getOutputSelectItems = (
    items: ExistedOutputT[] | MediaGroupT[],
  ): ExistedOutputT[] | MediaGroupT[] | MediaT[] => {
    if (variant === "media" && isMediaGroups(items)) {
      return items.flatMap((item) => item.medias);
    }

    return items;
  };

  return (
    <>
      <InfinityScrollContainer
        isLoading={isLoading || isFetchingNextPage}
        hidden={hidden}
        hasMoreItems={!!hasNextPage}
        onLoadMore={() => (hasNextPage ? fetchNextPage() : null)}
      >
        {subtitle && <Subtitle paragraph={subtitle} paragraphSize="small" />}
        {isLoading && !data && (
          <PlaceholderLoader
            type={PlaceholdersTypeE.output_box}
            count={3}
            perRow={3}
            direction="row"
            justifyContent="flex-start"
          />
        )}
        {data && data.pages[0] && data.pages[0].length > 0 ? (
          data.pages.map((page, index) => (
            <FlexBox
              key={index}
              customWidth={CustomWidthE.full}
              alignItems="stretch"
              justifyContent="flex-start"
              flexWrap="wrap"
            >
              {page &&
                page.length > 0 &&
                getOutputSelectItems(page).map((item) => (
                  <OutputSelect
                    key={item.id}
                    output={getOutput(item)}
                    original={item}
                    selectedOutput={localExistedOutput}
                    setSelectedOutput={setLocalExistedOutput}
                    marginSize={8}
                  />
                ))}
            </FlexBox>
          ))
        ) : (
          <FlexBox customWidth={CustomWidthE.full} fullHeight={true}>
            <Spacer size="big" direction="vertical" />
            <NoResults
              illustrationSize={250}
              illustration={IllustrationTypeE.search_fighting}
              message={t("title_empty")}
            />
          </FlexBox>
        )}
      </InfinityScrollContainer>
    </>
  );
};

export default ExistedModalScroll;
