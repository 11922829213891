import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { NetworkProviderT } from "../../types";

export const createNetworkProfile = async (
  handle: string,
  provider: NetworkProviderT,
): Promise<{ status: "accepted" | "created" }> => {
  const payload = { profile_id: handle, provider: provider };
  return axios
    .post(getApiUrl(ApiRoutesE.NETWORK_PROFILES), payload)
    .then((res) => res.data);
};
