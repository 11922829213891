import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { UserForUserManagementT } from "../../types";
import { updateCurrentUsageMutation } from "./updateCurrentUsageMutation";

export const createUsersMutation = (
  queryClient: QueryClient,
  data: UserForUserManagementT[],
  afterMutationHandle?: () => void,
): void => {
  queryClient.setQueryData<UserForUserManagementT[]>(
    QueryKeys.USERS,
    (prevState) => {
      if (prevState) {
        return [...data, ...prevState];
      }

      return data;
    },
  );

  updateCurrentUsageMutation(queryClient, "users");

  afterMutationHandle && afterMutationHandle();
};
