import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../components/FlexBox";
import { rem, theme } from "../styling/theme";

const Wrapper = styled(FlexBox)<{ active: boolean }>(({ active }) => ({
  transition: "font-weight 0.05s",
  transitionTimingFunction: theme.timing.default,
  cursor: "pointer",
  textWrap: "nowrap",
  borderRadius: rem(8),
  backgroundColor: active ? theme.color.primaryBackgroundColor : "transparent",
  width: "100%",
  paddingLeft: `${rem(12)}`,
  justifyContent: "flex-start",
  gap: rem(8),
  color: active ? theme.color.primaryColor : theme.color.blackColor,
  height: rem(40),

  ["&::before"]: {
    content: '" "',
    position: "absolute",
    backgroundColor: active ? theme.color.primaryColor : "transparent",
    width: rem(4),
    height: rem(24),
    left: rem(4),
    borderRadius: `0 ${rem(4)} ${rem(4)} 0`,
  },

  ":hover": {
    backgroundColor: theme.color.backgroundColor,
  },
}));

type NavigationItemT = {
  onClick: () => void;
  active: boolean;
  icon: JSX.Element;
};

const NavigationItem: FC<NavigationItemT> = ({
  onClick,
  active,
  icon,
  children,
}) => {
  return (
    <Wrapper active={active} onClick={onClick}>
      {icon}
      {children}
    </Wrapper>
  );
};

export default NavigationItem;
