import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ApprovalRequestWithAuthorT } from "../../types";

export const createApprovalRequests = async (payload: {
  approval_request: {
    partnership_id: string;
    mentions: string[];
    email?: string;
  };
}): Promise<ApprovalRequestWithAuthorT> => {
  return axios
    .post(getApiUrl(ApiRoutesE.APPROVAL_REQUESTS), payload)
    .then((res) => res.data.approval_request);
};
