import axios from "axios";
import { pick } from "lodash";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ContactT } from "../features/influencer-database-detail/types";
import { ListT } from "../types";

export const updateNetworkProfileContact = async (
  id: string,
  contact: ContactT,
): Promise<ListT> => {
  const payload = {
    contact: {
      ...pick(contact, ["phone", "email", "company_number", "address", "tags"]),
    },
  };

  return axios
    .patch(getApiUrl(ApiRoutesE.NETWORK_PROFILES_CONTACT, id), payload)
    .then((res) => res.data.list);
};
