import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { SortingOptionsT, SortingOrderT } from "../hooks/useLocalSort";
import { useTranslate } from "../hooks/useTranslate";
import { theme } from "../styling/theme";
import { IconTypeE } from "../ui/icons/Icon";
import BlueLink from "./BlueLink";
import { ParagraphSizeT } from "./Paragraph";

type SortLinkT = {
  attribute: string;
  sortingOptions: SortingOptionsT;
  setSortingOptions: Dispatch<SetStateAction<SortingOptionsT>>;
  paragraphSize?: ParagraphSizeT;
};

const SortLink: FC<SortLinkT> = ({
  attribute,
  sortingOptions,
  setSortingOptions,
  paragraphSize = "micro",
}) => {
  const { t } = useTranslate("brands.reportings.table");

  const orderOption = (property: string): SortingOrderT => {
    if (
      sortingOptions.attribute === property &&
      sortingOptions.order !== "asc"
    ) {
      return "asc";
    }
    return "desc";
  };

  return (
    <BlueLink
      text={t(attribute)}
      icon={IconTypeE.arrowBottom}
      rotate={sortingOptions.order === "asc" ? "180deg" : undefined}
      iconPosition={"after"}
      iconSize="mini"
      iconColor={
        sortingOptions.attribute !== attribute
          ? theme.color.whiteColor
          : undefined
      }
      paragraphSize={paragraphSize}
      marginTop="0"
      onClick={() =>
        setSortingOptions({
          order: orderOption(attribute),
          attribute: attribute,
        })
      }
    />
  );
};

export default SortLink;
