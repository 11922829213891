import styled from "@emotion/styled";
import { rem, theme } from "../../../styling/theme";

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -${rem(8)};
  list-style: none;
  padding: 0;

  ${theme.media.sm} {
    margin: 0 0 0 -${rem(26)};
  }
`;

export const Item = styled.li`
  flex: 0 1 100%;
  max-width: 100%;
  padding: 0 0 ${rem(8)};

  ${theme.media.sm} {
    flex: 0 1 33.33333333%;
    max-width: 33.33333333%;
    padding: 0 0 0 ${rem(26)};
  }
`;
