import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { PartnershipT } from "../../types";

export const createCampaignPartnershipMutation = (
  queryClient: QueryClient,
  campaignId: string,
  data: PartnershipT[],
  afterMutationHandle?: () => void,
): void => {
  queryClient.setQueryData<PartnershipT[]>(
    [QueryKeys.PARTNERSHIPS, campaignId],
    (prevState) => {
      if (prevState) {
        return [...prevState, ...data];
      }

      return data;
    },
  );

  afterMutationHandle && afterMutationHandle();
};
