import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Container from "../../../../components/Container";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Panel from "../../../../components/Panel";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { getDateTime } from "../../../../helpers/date";
import { putSpacesToThousand } from "../../../../helpers/formaters";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { getOverlapAnalysis } from "../../data-access/getOverlapAnalysis";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import Graph from "./Graph";
import Table from "./Table";

const BackArrow = styled(FlexBox)({
  height: "auto",
  cursor: "pointer",
  padding: `0 ${rem(10)}`,
  borderRadius: `${rem(8)} 0 0 ${rem(8)}`,

  ["&:hover"]: {
    backgroundColor: theme.color.greyLightColor,
    backgroundImage: `linear-gradient(to right, ${theme.color.greyLightColor}, white)`,
  },
});

const Header = styled(FlexBox)({
  width: "100%",
  padding: rem(20),
  fontSize: rem(18),
});

const Title = styled(Paragraph)({
  fontSize: rem(24),
  fontWeight: 600,
});

const Handles = styled(FlexBox)({
  gap: rem(5),
});

const HeaderItem = styled(FlexBox)({
  justifyContent: "space-between",
  width: "100%",
  gap: rem(5),
});

const TotalsWrapper = styled(FlexBox)({
  width: "fit-content",
});

const ContentWrapper = styled(FlexBox)({
  gap: rem(10),
  flexDirection: "row",
  backgroundColor: theme.color.whiteColor,
  padding: `${rem(50)} ${rem(15)} ${rem(15)}`,
  border: `1px solid ${theme.color.greyLightColor}`,
  borderRadius: rem(8),

  [theme.media.maxMd]: {
    flexDirection: "column",
  },
});

const ContentItem = styled(FlexBox)({
  width: "50%",

  [theme.media.maxMd]: {
    width: "100%",
  },
});

const OverlapAnalisisDetailContainer: FC = () => {
  const { id } = useParams() as { id: string };

  const navigate = useRouterNavigate();

  const { t } = useTranslate("brands.overlap_analyses.show");
  const {
    data: overlapAnalysis,
    isLoading,
    isRefetching,
  } = useQuery([QueryKeys.OVERLAP_ANALYSIS_DETAIL, id], () =>
    getOverlapAnalysis(id),
  );

  const getContent = (): JSX.Element => {
    if (isLoading || isRefetching) {
      return (
        <PlaceholderLoader
          type={PlaceholdersTypeE.table_item_no_avatar}
          count={1}
          direction="column"
          withBorder={false}
        />
      );
    }

    if (!overlapAnalysis) {
      return <div>Not found</div>;
    }

    const {
      data: {
        report_info: { total_followers, total_unique_followers },
      },
    } = overlapAnalysis;
    const uniqPercentage = total_unique_followers / (total_followers / 100);

    return (
      <Container>
        <Panel justifyContent="stretch" alignItems="stretch">
          <BackArrow onClick={() => navigate(NavigationKeyE.OverlapAnalyses)}>
            <Icon icon={IconTypeE.arrowLeft} size="small" />
          </BackArrow>
          <Header>
            <FlexBox
              customWidth={CustomWidthE.half}
              flexDirection="column"
              alignItems="flex-start"
            >
              <Title paragraph={overlapAnalysis.title} />
              <Handles>
                <Paragraph paragraph={`${t("profiles")}:`} fontWeight={600} />
                {overlapAnalysis.handles.join(", ")}
              </Handles>
              <Paragraph
                paragraph={getDateTime(overlapAnalysis.created_at, "fullDate")}
                color={theme.color.textGreyColor}
              />
            </FlexBox>
            <TotalsWrapper
              customWidth={CustomWidthE.half}
              flexDirection="column"
              alignItems="flex-start"
            >
              <HeaderItem>
                <Paragraph
                  paragraph={`${t("total_followers")}:`}
                  fontWeight={600}
                />
                {putSpacesToThousand(total_followers)}
              </HeaderItem>
              <Spacer size="small" direction="vertical" />
              <HeaderItem>
                <Paragraph
                  paragraph={`${t("total_unique_followers")}:`}
                  fontWeight={600}
                />
                {putSpacesToThousand(total_unique_followers)} (
                {uniqPercentage.toFixed(1)}
                %)
              </HeaderItem>
            </TotalsWrapper>
          </Header>
        </Panel>
        <ContentWrapper>
          <ContentItem>
            <Graph info={overlapAnalysis.data} />
          </ContentItem>
          <ContentItem>
            <Table data={overlapAnalysis.data.data} />
          </ContentItem>
        </ContentWrapper>
      </Container>
    );
  };

  return getContent();
};

export default OverlapAnalisisDetailContainer;
