import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { OverlapAnalysisT } from "../types";

type createOverlapAnalysisPayloadT = {
  overlap_analysis: {
    title: string;
    handles: string[];
  };
};

export const createOverlapAnalysis = async (
  payload: createOverlapAnalysisPayloadT,
): Promise<{ overlap_analysis: OverlapAnalysisT }> => {
  return axios
    .post(getApiUrl(ApiRoutesE.OVERLAP_ANALYSES), payload)
    .then((res) => res.data);
};
