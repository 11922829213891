import styled from "@emotion/styled";
import FlexBox from "../../components/FlexBox";
import { rem, theme } from "../../styling/theme";

const TableHeadWrapper = styled(FlexBox)<{ padding?: string | 0 }>(
  ({ padding = `${rem(8)} 0` }) => ({
    padding,
    width: "100%",
    borderBottom: `1px solid ${theme.color.greyLightColor}`,
    zIndex: 0,
  }),
);

export default TableHeadWrapper;
