import * as React from "react";
import { FC, useContext } from "react";
import LoaderComponent from "../../../../components/Loader";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import ManageSubscription from "./ManageSubscription";

const SubscriptionContainer: FC = () => {
  const { session } = useContext(ApplicationContext);

  const getContent = (): JSX.Element => {
    if (session?.subscription) {
      return <ManageSubscription subscription={session.subscription} />;
    } else {
      return <LoaderComponent />;
    }
  };
  return getContent();
};

export default SubscriptionContainer;
