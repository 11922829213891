import axios from "axios";
import { MediaGroupT } from "../types";

type getCampaingMediaGroupsPropsT = {
  apiUrl: string;
};

export const getCampaignMediaGroups = async ({
  apiUrl,
}: getCampaingMediaGroupsPropsT): Promise<MediaGroupT[]> => {
  return axios.get(apiUrl).then((res) => res.data.media_groups);
};
