type allowExtensionT = (extension: string) => boolean;
export const allowExtension: allowExtensionT = (extension) => {
  return (
    ACCEPTED_EXTENSIONS.filter(
      (acc) => acc === extension || acc.toUpperCase() === extension,
    ).length > 0
  );
};

type getFileExtensionT = (name: string | null) => string;
export const getFileExtension: getFileExtensionT = (name) => {
  if (name) {
    const regExp = /(\.[^.]*)(?=\?|$)/;
    const regExpResult = regExp.exec(name);

    return regExpResult ? regExpResult[0].substring(1) : "not_recognize";
  }

  return "not_recognize";
};

type getFileNameT = (url: string) => string;
export const getFileName: getFileNameT = (url) => {
  const tmpHashFileName = url.includes("tmp_uploads%2F");

  const regExp = tmpHashFileName ? /(\%2F[^F]*)$/g : /(\/[^/]*)$/g;
  const regExpResult = regExp.exec(url);

  return regExpResult
    ? regExpResult[0].substring(tmpHashFileName ? 3 : 1)
    : "file_name";
};

export enum FileTypeE {
  file = "file",
  image = "image",
  video = "video",
}

type getFileTypeT = (fileExtension: string) => FileTypeE;
export const getFileType: getFileTypeT = (fileExtension: string) => {
  if (!!IMAGE_EXTENSIONS.find((imgExt) => imgExt === fileExtension)) {
    return FileTypeE.image;
  }
  if (!!VIDEO_EXTENSIONS.find((vidExt) => vidExt === fileExtension)) {
    return FileTypeE.video;
  }

  return FileTypeE.file;
};

const IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "gif", "heic"];
const VIDEO_EXTENSIONS = ["m4v", "mp4", "mov"];

const ACCEPTED_EXTENSIONS = [
  ...IMAGE_EXTENSIONS,
  ...VIDEO_EXTENSIONS,
  "csv",
  "m4a",
  "pdf",
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pptx",
  "ppt",
  "rtf",
  "odt",
  "ott",
  "oth",
  "and",
  "odm",
  "txt",
  "pages",
  "svg",
  "zip",
  "rar",
  "gzip",
  "not_recognize",
];
