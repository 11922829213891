import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import FlexBox from "../../components/FlexBox";
import { rem, theme } from "../../styling/theme";

const SIDE_PADDING = 12;
const HANDLES_TOP_SPACE = 28;

const Wrapper = styled(FlexBox)<{
  cardWidth: string;
  storySizing: boolean;
  transitions: boolean;
}>(({ cardWidth, storySizing, transitions }) => ({
  width: storySizing
    ? `calc(${cardWidth} - ${rem(SIDE_PADDING * 2)})`
    : cardWidth,
  minWidth: storySizing
    ? `calc(${cardWidth} - ${rem(SIDE_PADDING * 2)})`
    : cardWidth,
  paddingTop: storySizing ? 0 : rem(HANDLES_TOP_SPACE),
  paddingLeft: storySizing ? 0 : rem(SIDE_PADDING),
  paddingRight: storySizing ? 0 : rem(SIDE_PADDING),
  paddingBottom: storySizing ? 0 : rem(12),
  overflow: "hidden",
  transform: "translate3d(0,0,0)",

  ["&"]: transitions && {
    transition: "all 0.5s",
    transitionTimingFunction: theme.timing.default,
  },

  ["@media screen and (max-width: 600px)"]: {
    width: cardWidth,
    minWidth: cardWidth,
    paddingTop: storySizing ? 0 : rem(SIDE_PADDING + HANDLES_TOP_SPACE),
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: storySizing ? 0 : rem(32),
  },
}));

type PositionWrapperT = {
  cardWidth: string;
  storySizing?: boolean;
};

const PositionWrapper: FC<PositionWrapperT> = ({
  cardWidth,
  storySizing,
  children,
}) => {
  const [allowTransitions, setAllowTransitions] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setAllowTransitions(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Wrapper
      cardWidth={cardWidth}
      storySizing={!!storySizing}
      transitions={allowTransitions}
      alignItems="flex-start"
      fullHeight={true}
      position="relative"
    >
      {children}
    </Wrapper>
  );
};

export default PositionWrapper;
