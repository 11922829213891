import { DownOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Divider, Dropdown, MenuProps, Space } from "antd";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { numberWithSpaces } from "../../../../../helpers/formaters";
import { MixpanelEventT, trackEvent } from "../../../../../helpers/trackEvent";
import { useResponsive } from "../../../../../hooks/useResponsive";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { breakpoints, rem, theme } from "../../../../../styling/theme";
import { SearchQueryT } from "../types";
import ListSelect from "./ListSelect";

const Wrapper = styled(FlexBox)({
  width: "100%",
  justifyContent: "space-between",
  padding: `${rem(20)} ${rem(20)} ${rem(16)} ${rem(20)}`,
  height: rem(56),
  borderBottom: `1px solid ${theme.color.greyLightColor}`,

  [theme.media.maxSm]: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    height: "auto",
    flexWrap: "wrap",
    gap: rem(5),
    borderBottom: "none",
  },
});

type TableHeaderT = {
  currentCount: number;
  totalCount: number;
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
  networkProfileIds: string[];
  setNetworkProfileIds: Dispatch<SetStateAction<string[]>>;
  listProfilesCount: number;
};

const TableHeader: FC<TableHeaderT> = ({
  currentCount,
  totalCount,
  searchQuery,
  setFilterSearchQuery,
  networkProfileIds,
  setNetworkProfileIds,
  listProfilesCount,
}) => {
  const sortOption =
    searchQuery.sort === "people_count:desc"
      ? "credibility:desc"
      : "people_count:desc";

  const { breakePoint } = useResponsive(breakpoints.sm);

  const { t } = useTranslate("brands.influencer_database.table");

  const onSortClickHandle = (
    sortOption: "people_count:desc" | "credibility:desc",
  ): void => {
    trackEvent(MixpanelEventT.DatabaseListSort, { sort: sortOption });
    setFilterSearchQuery({ sort: sortOption });
  };

  const items: MenuProps["items"] = [
    {
      key: sortOption,
      label: (
        <a onClick={() => onSortClickHandle(sortOption)}>
          {t(`sorting.${sortOption.replace(":", "_")}`)}
        </a>
      ),
    },
  ];

  const totalCountString = (): string => {
    if (listProfilesCount > 0) {
      return `${listProfilesCount} ${t("influencers")}`;
    }

    const total = numberWithSpaces(totalCount);
    const current = numberWithSpaces(currentCount);

    if (totalCount === currentCount) {
      return `${total} ${t("influencers")}`;
    }

    return `<b>${current}</b> ${t("from")} ${total} ${t("influencers")}`;
  };

  const showListSelect = (condition: boolean): JSX.Element | undefined => {
    if (condition) {
      return (
        <>
          <Divider type="vertical" />
          <FlexBox gap={rem(10)}>
            <Paragraph
              paragraph={`${t("selected")} ${networkProfileIds.length}`}
            />
            <ListSelect
              networkProfileIds={networkProfileIds}
              setNetworkProfileIds={setNetworkProfileIds}
            />
          </FlexBox>
        </>
      );
    } else {
      return undefined;
    }
  };

  return (
    <Wrapper>
      <FlexBox>
        <Paragraph paragraph={totalCountString()} />
        {showListSelect(networkProfileIds.length > 0 && !breakePoint)}
      </FlexBox>
      {(searchQuery.provider_eq === "instagram" || listProfilesCount > 0) && (
        <FlexBox gap={rem(8)}>
          <Paragraph
            paragraph={t("sort_by")}
            paragraphSize="small"
            color={theme.color.textGreyColor}
          />
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {t(`sorting.${searchQuery.sort.replace(":", "_")}`)}
                <DownOutlined rev="" />
              </Space>
            </a>
          </Dropdown>
        </FlexBox>
      )}
      {showListSelect(networkProfileIds.length > 0 && breakePoint)}
    </Wrapper>
  );
};

export default TableHeader;
