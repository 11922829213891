import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Panel from "../../../../components/Panel";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { ApprovalPageStateT } from "../../types";

const IconWrapper = styled(FlexBox)({
  padding: rem(10),
  backgroundColor: theme.color.whiteColor,
  borderRadius: "50%",
});

const StyledPanel = styled(Panel)({
  justifyContent: "space-between",
  backgroundColor: theme.color.errorColor,

  [theme.media.maxSm]: {
    alignItems: "flex-start",
    flexDirection: "column",
  },
});

const StyledA = styled.a({
  [theme.media.maxSm]: {
    margin: `${rem(10)} 0 0 ${rem(50)}`,
  },
});

const ParagraphWrapper = styled(FlexBox)({
  padding: `0 ${rem(5)} 0 0`,
});

type ErrorMessageT = {
  approvalRequestId: string;
  influencerHandle: string;
  approvalPageState: ApprovalPageStateT;
  currentHandle: string;
};

const ErrorMessage: FC<ErrorMessageT> = ({
  approvalRequestId,
  approvalPageState,
  influencerHandle,
  currentHandle,
}) => {
  const { t } = useTranslate("influencer_root.approval_request.errors");

  const getContent = (): { text: string; button?: JSX.Element } | null => {
    if (approvalPageState === "session_mismatch") {
      return {
        text: t("session_mismatch", {
          current_handle: currentHandle,
          handle: influencerHandle,
        }),
        button: (
          <StyledA
            data-method="delete"
            href={`/users/logout?redirect_to=/approval_requests/${approvalRequestId}`}
          >
            <Button
              size={ButtonSizeE.small}
              type={ButtonTypeE.transparentWhite}
            >
              {t("change_account_button")}
            </Button>
          </StyledA>
        ),
      };
    }

    if (approvalPageState === "token_expired") {
      return {
        text: t("token_expired"),
        button: (
          <a href={`/profile/networks`}>
            <Button size={ButtonSizeE.small}>
              {t("approve_again_button")}
            </Button>
          </a>
        ),
      };
    }

    if (approvalPageState === "handle_mismatch") {
      return { text: t("handle_mismatch", { handle: influencerHandle }) };
    }

    if (approvalPageState === "scopes_missing") {
      return { text: t("scopes_missing") };
    }

    if (approvalPageState === "user_cancelled") {
      return { text: t("process_canceled") };
    }

    if (approvalPageState === "no_account_available") {
      return { text: t("no_account_available") };
    }

    return null;
  };

  const content = getContent();

  if (!content) {
    return null;
  }

  return (
    <StyledPanel padding={`${rem(12)} ${rem(16)}`} marginBottom={"default"}>
      <FlexBox>
        <IconWrapper>
          <Icon
            icon={IconTypeE.cross}
            size="mini"
            color={theme.color.errorColor}
          />
        </IconWrapper>
        <Spacer />
        <ParagraphWrapper
          customWidth={CustomWidthE.full}
          justifyContent="space-between"
        >
          <Paragraph paragraph={content.text} color={theme.color.whiteColor} />
        </ParagraphWrapper>
      </FlexBox>
      {content.button}
    </StyledPanel>
  );
};

export default ErrorMessage;
