import {
  Button,
  Drawer as AntDrawer,
  DrawerProps as AntDrawerProps,
} from "antd";
import { omit } from "lodash";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../components/FlexBox";
import { useTranslate } from "../hooks/useTranslate";
import { rem } from "../styling/theme";

interface DrawerProps {
  onConfirm?: {
    handle: () => void;
    label: string;
    isLoading?: boolean;
  };
  extraLeft?: JSX.Element;
}

const Drawer: FC<AntDrawerProps & DrawerProps> = (props) => {
  const { onConfirm, onClose, extraLeft } = props;
  const options = omit(props, ["onConfirm", "extraLeft"]) as AntDrawerProps;

  const { t } = useTranslate("shared.ui.drawer");

  const footer = (): JSX.Element => {
    return (
      <FlexBox justifyContent="space-between">
        <FlexBox>{extraLeft && extraLeft}</FlexBox>
        <FlexBox justifyContent="flex-end" gap={rem(12)}>
          {onClose && <Button onClick={onClose}>{t("cancel")}</Button>}
          {onConfirm && (
            <Button
              type="primary"
              onClick={onConfirm.handle}
              loading={onConfirm.isLoading}
            >
              {onConfirm.label}
            </Button>
          )}
        </FlexBox>
      </FlexBox>
    );
  };

  return <AntDrawer {...options} footer={props.footer || footer()} />;
};

export default Drawer;
