import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../components/FlexBox";
import Paragraph from "../components/Paragraph";
import Spacer from "../components/Spacer";
import { theme } from "../styling/theme";

type InputWrapperT = {
  label?: string;
  labelDescription?: string;
  fullHeight?: boolean;
};

const InputWrapper: FC<InputWrapperT> = ({
  label,
  labelDescription,
  fullHeight = false,
  children,
}) => (
  <FlexBox
    customWidth={CustomWidthE.full}
    fullHeight={fullHeight}
    flexDirection="column"
    alignItems="flex-start"
    justifyContent="flex-start"
  >
    {label && (
      <>
        <Paragraph paragraph={label} paragraphSize="small" />
        <Spacer size="mini" direction="vertical" />
      </>
    )}
    {labelDescription && (
      <>
        <Paragraph
          paragraph={labelDescription}
          paragraphSize="small"
          color={theme.color.textGreyColor}
        />
        <Spacer size="mini" direction="vertical" />
      </>
    )}
    {children}
  </FlexBox>
);

export default InputWrapper;
