import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { BillingAddressT, SessionT } from "../../types";

type PayloadT = {
  user: {
    name: string;
    position: string | undefined;
    brand: {
      company_name: string;
      campaigns_count: string | undefined;
      billing_address_attributes?: Partial<BillingAddressT>;
    };
  };
};

export const activateTrial = async (
  name: string,
  company_name: string,
  position: string | undefined,
  campaignsCount: string | undefined,
  billing_address?: Partial<BillingAddressT> | undefined,
): Promise<SessionT> => {
  const payload: PayloadT = {
    user: {
      name: name,
      position: position,
      brand: { company_name: company_name, campaigns_count: campaignsCount },
    },
  };
  if (billing_address) {
    payload.user.brand.billing_address_attributes = billing_address;
  }

  return axios
    .patch(getApiUrl(ApiRoutesE.CURRENT_USER_ACTIVATE_TRIAL), payload)
    .then((res) => res.data.session);
};
