import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import {
  ContactNoteT,
  ContactT,
} from "../../features/influencer-database-detail/types";

export const updateContactNoteMutation = (
  queryClient: QueryClient,
  data: ContactNoteT,
): void => {
  queryClient.setQueryData<ContactT | undefined>(
    QueryKeys.NETWORK_PROFILES_CONTACT,
    (prevState) => {
      if (prevState) {
        const findIndex = prevState.notes.findIndex(
          (item) => item.id === data.id,
        );

        if (findIndex === -1) {
          return { ...prevState, notes: [data, ...prevState.notes] };
        }

        const newState = { ...prevState };
        newState.notes[findIndex] = data;
        return newState;
      }

      return prevState;
    },
  );
};
