import styled from "@emotion/styled";
import React, { FC, useContext, useMemo } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { designToken } from "../../../../../helpers/antDesign";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import { SubscriptionContext } from "../../../context-providers/SubscriptionsContextProvider";
import { SearchQueryT } from "../types";
import { demoProfiles } from "./fixtures";
import ProfileRow from "./ProfileRow";
import { RowsWrapper } from "./Table";

const avatars_url = require("../../../../../assets/trial_avatars.png");

const Wrapper = styled(FlexBox)({
  alignItems: "flex-start",
  position: "relative",
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

const Blur = styled(FlexBox)({
  width: "100%",
  flexDirection: "column",
  filter: "blur(3px)",
});

const Banner = styled(FlexBox)({
  flexDirection: "column",
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: 2,
  opacity: 0.92,
  backgroundColor: designToken.colorWhite,
  gap: rem(24),
});

const InfoWrapper = styled(FlexBox)({
  flexDirection: "column",
  gap: rem(8),
});

const Img = styled.img({
  width: rem(153),
  height: rem(64),
});

type TrialBannerT = {
  currentCount: number | undefined;
  searchQuery: SearchQueryT;
};

const TrialBanner: FC<TrialBannerT> = ({ currentCount, searchQuery }) => {
  const data = useMemo(demoProfiles, []);

  const { upgradeTrialButton } = useContext(SubscriptionContext);
  const { t } = useTranslate("brands.influencer_database.table");

  return (
    <Wrapper>
      <Blur>
        {data.map((profile) => (
          <RowsWrapper key={profile.id}>
            <ProfileRow
              networkProfile={profile}
              networkProfileIds={[]}
              setNetworkProfileIds={() => {}}
              listMode={false}
              setOutputs={() => {}}
              searchQuery={searchQuery}
            />
          </RowsWrapper>
        ))}
      </Blur>
      <Banner>
        <InfoWrapper>
          <Img src={avatars_url} alt="avatars" />
          {currentCount ? (
            <>
              <Paragraph
                paragraph={t("trial.banner.search", { count: currentCount })}
                fontWeight={600}
              />
              <Paragraph
                paragraph={t("trial.banner.search_description")}
                paragraphSize="small"
                color={designToken.colorTextSecondary}
              />
            </>
          ) : (
            <>
              <Paragraph paragraph={t("trial.banner.title")} fontWeight={600} />
              <Paragraph
                paragraph={t("trial.banner.subtitle")}
                paragraphSize="small"
                color={designToken.colorTextSecondary}
              />
            </>
          )}
        </InfoWrapper>
        {upgradeTrialButton}
      </Banner>
    </Wrapper>
  );
};

export default TrialBanner;
