import { CheckboxValueType } from "antd/es/checkbox/Group";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../components/FlexBox";
import { useTranslate } from "../../../../../hooks/useTranslate";
import ExpandableSection from "../components/ExpandableSection";
import FilterContainer from "../components/FilterContainer";
import { FilterDataT, SearchQueryT } from "../types";

interface InfluencerInterestsT {
  data: FilterDataT;
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
}

const InfluencerInterests: FC<InfluencerInterestsT> = ({
  data,
  searchQuery,
  setFilterSearchQuery,
}) => {
  const { profile_interests } = data;
  const { profile_interests: values } = searchQuery;

  const { t } = useTranslate(
    "brands.influencer_database.filter.influencer_interests",
  );
  const setValues = (values: CheckboxValueType[]): void => {
    setFilterSearchQuery({
      profile_interests: values as string[],
    });
  };

  return (
    <FlexBox data-test-id="components-InfluencersInterests">
      <FilterContainer t={t}>
        <ExpandableSection
          values={values}
          setValues={setValues}
          items={profile_interests}
        />
      </FilterContainer>
    </FlexBox>
  );
};

export default InfluencerInterests;
