import {
  CreditCardOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Divider, Tag } from "antd";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import Container from "../../../../components/Container";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { getDateTime } from "../../../../helpers/date";
import { numberWithSpaces } from "../../../../helpers/formaters";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { MixpanelEventT, trackEvent } from "../../../../helpers/trackEvent";
import { useTranslate } from "../../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../../styling/theme";
import {
  SubscriptionCurrentUsageT,
  SubscriptionEntitlementFeaturesT,
  SubscriptionStatusT,
  SubscriptionT,
} from "../../../types";
import { SubscriptionContext } from "../../context-providers/SubscriptionsContextProvider";
import UsageItem from "./UsageItem";
import ValueBox from "./ValueBox";

const Box = styled(FlexBox)({
  backgroundColor: theme.color.whiteColor,
  padding: `${rem(20)} ${rem(16)}`,
  borderRadius: designToken.borderRadiusLG,
});

const StyledDivider = styled(Divider)({
  color: designToken["blue-1"],
  margin: 0,
});

const UsageWrapper = styled(FlexBox)({
  width: "50%",
  padding: `${rem(20)} ${rem(16)}`,
  backgroundColor: hexToRgb(designToken["blue-1"], 0.2),
  borderRadius: designToken.borderRadiusSM,
});

type ManageSubscriptionT = {
  subscription: SubscriptionT;
};

const ManageSubscription: FC<ManageSubscriptionT> = ({ subscription }) => {
  const {
    current_period_usage,
    next_billing_at,
    billing_period_unit,
    total_price,
    expires_on,
    plan,
    status,
    currency_code,
    remote,
  } = subscription;
  const subscriptionIsEnabled = status !== "cancelled";

  const usage = (
    featureId: SubscriptionEntitlementFeaturesT,
  ): SubscriptionCurrentUsageT | undefined => {
    return current_period_usage.usage.find((u) => u.feature_id === featureId);
  };

  const usersUsage = usage("users");
  const monitoredProfilesUsage = usage("reporting-influencers");
  const overlapsUsage = usage("overlap-analyses");
  const competitiveUsage = usage("competitive-analyses");

  const { openPortal, setSubscription, isTrial, upgradeTrialButton } =
    useContext(SubscriptionContext);
  const { t } = useTranslate("brands.manage_subscription");

  const statusColor = (status: SubscriptionStatusT): string => {
    switch (status) {
      case "active":
        return "green";
      case "non_renewing":
      case "paused":
        return "orange";
      case "cancelled":
        return "red";
      default:
        return "black";
    }
  };

  const handleManageUserseOnClick = (): void => {
    window.location.href = getApiUrl(ApiRoutesE.MANAGE_USERS);
  };

  useEffect(() => {
    trackEvent(MixpanelEventT.SubscriptionManagementImpression);
  }, []);

  useEffect(() => {
    setSubscription(subscription);
  }, [subscription]);

  return (
    <FlexBox>
      <Container>
        <FlexBox gap={rem(16)} flexDirection="column" alignItems="flex-start">
          <Paragraph
            paragraph={"Manage Subscription"}
            paragraphSize="large"
            fontWeight={600}
          />
          <FlexBox
            customWidth={CustomWidthE.full}
            flexDirection="column"
            gap={rem(16)}
          >
            <FlexBox
              gap={rem(16)}
              customWidth={CustomWidthE.full}
              alignItems="stretch"
            >
              <Box
                customWidth="70%"
                flexDirection="column"
                alignItems="flex-start"
                gap={rem(16)}
              >
                <FlexBox gap={rem(12)}>
                  <Paragraph
                    paragraph={t(`plans.${plan.toLowerCase()}`)}
                    fontWeight={600}
                  />
                  <Tag color={statusColor(status)}>
                    {t(`statuses.${status}`)}
                  </Tag>
                </FlexBox>
                <FlexBox
                  customWidth={CustomWidthE.full}
                  justifyContent="space-between"
                >
                  {subscriptionIsEnabled ? (
                    <>
                      <ValueBox
                        label={t("next_payment")}
                        value={
                          next_billing_at
                            ? getDateTime(next_billing_at, "shortDate")
                            : "-"
                        }
                      />
                      <ValueBox
                        label={t("billing_cycle")}
                        value={
                          billing_period_unit
                            ? t(`billing.${billing_period_unit}`)
                            : "-"
                        }
                      />
                      <ValueBox
                        label="Total"
                        value={
                          total_price
                            ? `${numberWithSpaces(
                                total_price / 100,
                              )} ${currency_code}`
                            : "-"
                        }
                      />
                    </>
                  ) : (
                    <Paragraph
                      paragraph={t("disabled", {
                        date: getDateTime(expires_on, "shortDate"),
                      })}
                      paragraphSize="small"
                    />
                  )}
                </FlexBox>
                <FlexBox>
                  {isTrial ? (
                    upgradeTrialButton
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => openPortal()}
                      disabled={!remote}
                    >
                      {t(subscriptionIsEnabled ? "manage" : "renew")}
                    </Button>
                  )}
                </FlexBox>
              </Box>
              <Box
                customWidth="30%"
                flexDirection="column"
                alignItems="flex-start"
                gap={rem(8)}
              >
                <Button
                  type="link"
                  size="large"
                  icon={<FileTextOutlined />}
                  disabled={!remote}
                  onClick={() => openPortal("portal_billing_history")}
                >
                  {t("view_invoices")}
                </Button>
                <StyledDivider dashed />
                <Button
                  type="link"
                  size="large"
                  icon={<CreditCardOutlined />}
                  disabled={!remote}
                  onClick={() => openPortal("portal_payment_methods")}
                >
                  {t("payment_methods")}
                </Button>
                <StyledDivider dashed />
                <Button
                  type="link"
                  size="large"
                  icon={<EnvironmentOutlined />}
                  disabled={!remote}
                  onClick={() => openPortal("portal_edit_billing_address")}
                >
                  {t("billing_address")}
                </Button>
              </Box>
            </FlexBox>
            {subscriptionIsEnabled && (
              <Box
                customWidth={CustomWidthE.full}
                flexDirection="column"
                gap={rem(16)}
                alignItems="flex-start"
              >
                <Paragraph paragraph={t("plan_usage")} fontWeight={600} />
                <FlexBox customWidth={CustomWidthE.full} gap={rem(16)}>
                  <UsageWrapper>
                    {usersUsage && (
                      <UsageItem
                        usage={usersUsage}
                        action={
                          usersUsage.quota !== 1
                            ? {
                                title: t("manage_users"),
                                onClickhandle: handleManageUserseOnClick,
                              }
                            : undefined
                        }
                      />
                    )}
                  </UsageWrapper>
                  <UsageWrapper>
                    {monitoredProfilesUsage && (
                      <UsageItem usage={monitoredProfilesUsage} />
                    )}
                  </UsageWrapper>
                </FlexBox>
                <FlexBox customWidth={CustomWidthE.full} gap={rem(16)}>
                  <UsageWrapper>
                    {overlapsUsage && <UsageItem usage={overlapsUsage} />}
                  </UsageWrapper>
                  <UsageWrapper>
                    {competitiveUsage && <UsageItem usage={competitiveUsage} />}
                  </UsageWrapper>
                </FlexBox>
              </Box>
            )}
          </FlexBox>
        </FlexBox>
      </Container>
    </FlexBox>
  );
};

export default ManageSubscription;
