import { IconTypeE } from "../../ui/icons/Icon";
import {
  FieldTypeT,
  MediaGroupKindT,
  MediaKindT,
  OutputOriginT,
  PartnershipNetworkT,
} from "./types";

export const outputsTypeIcons: Record<MediaGroupKindT, IconTypeE> = {
  story_set: IconTypeE.igStory,
  post: IconTypeE.igPost,
  video: IconTypeE.igVideo,
};

export const outputsOriginsIcons: Record<OutputOriginT, IconTypeE> = {
  public: IconTypeE.reportingPublic,
  private: IconTypeE.reportingPrivate,
  manual: IconTypeE.reportingManual,
};

export const contentTypes: MediaGroupKindT[] = ["post", "story_set", "video"];

type FieldValueT = FieldTypeT[] | Record<PartnershipNetworkT, FieldTypeT[]>;
export const cardFields: Record<MediaKindT, FieldValueT> = {
  post: ["likes", "comments", "reach", "impressions"],
  story: ["reach", "impressions", "stickertaps"],
  video: {
    instagram: ["reach", "impressions", "likes", "comments"],
    tiktok: ["reach", "impressions", "likes", "comments"],
  },
};

export const disabledFiledsMap: Record<OutputOriginT, FieldTypeT[]> = {
  manual: [],
  public: ["likes", "comments"],
  private: ["likes", "comments", "reach", "impressions"],
};
