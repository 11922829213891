import * as React from "react";
import { FC, useContext } from "react";
import AvatarTile from "../../../../../components/AvatarTile";
import { CustomWidthE } from "../../../../../components/FlexBox";
import Spacer from "../../../../../components/Spacer";
import { rem } from "../../../../../styling/theme";
import CardWrapper from "../../../../../ui/cards-ui/CardWrapper";
import { Content } from "../../../../../ui/cards-ui/styles";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import { MediaGroupT, MediaT } from "../../../types";
import CoverImages from "../card-cover/CoverImages";
import CardFooter from "./CardFooter";
import CardHeader from "./CardHeader";
import Insights from "./Insights";
import InsightsForm from "./InsightsForm";

type MediaCardT = {
  media: MediaT;
  mediaGroup: MediaGroupT;
  liveAddedMedia?: boolean;
  cardStorySizing?: boolean;
  storyCounter?: string;
  isStorySet?: boolean;
};

const MediaCard: FC<MediaCardT> = ({
  media,
  mediaGroup,
  liveAddedMedia,
  cardStorySizing,
  storyCounter,
  isStorySet = false,
}) => {
  const { partnership } = mediaGroup;
  const { handle, avatar_url, id, network } = partnership;
  const { updated_at, origin } = media;

  const { cardWidth } = useContext(MediaGroupsHelperContext);

  return (
    <CardWrapper
      cardWidth={cardWidth}
      storySizing={cardStorySizing}
      renderChildren={(wrapperWidth) => (
        <>
          <CardHeader
            media={media}
            mediaGroup={mediaGroup}
            storyCounter={storyCounter}
            network={mediaGroup.network}
            liveAddedMedia={liveAddedMedia}
          />
          <CoverImages
            media={media}
            partnershipId={id}
            wrapperWidth={wrapperWidth}
            liveAddedMedia={liveAddedMedia}
          />
          <Content
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            customWidth={CustomWidthE.full}
            padding={`${rem(12)} ${rem(16)}`}
          >
            <AvatarTile
              handle={handle}
              network={network}
              imageUrl={avatar_url}
            />
            <InsightsForm
              media={media}
              liveAddedMedia={liveAddedMedia}
              isUrlPresent={!isStorySet}
              network={network}
            />
            {origin !== "private" ? (
              <Insights
                media={media}
                mediaGroup={mediaGroup}
                liveAddedMedia={liveAddedMedia}
              />
            ) : (
              <Spacer direction="vertical" size="big" />
            )}
          </Content>
          <CardFooter cardOrigin={origin} updatedTime={updated_at} />
        </>
      )}
    />
  );
};

export default MediaCard;
