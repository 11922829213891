import axios from "axios";
import { Dictionary, identity, pickBy } from "lodash";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { NetworkProfilesResponseT } from "../../types";
import { SearchQueryT } from "../features/influencer-database/types";

export const getNetworkProfiles = async (
  query: SearchQueryT | undefined,
  pageParam: number,
): Promise<NetworkProfilesResponseT> => {
  const params = { ...query, page: pageParam };
  const url = getUrlWithQuery(
    getApiUrl(ApiRoutesE.NETWORK_PROFILES),
    pickBy(params as Dictionary<string | number | string[]>, identity),
  );
  return axios.get(url).then((res) => res.data);
};
