export const isIE = (): boolean => {
  if (typeof document !== "undefined") {
    // tslint:disable-next-line:no-string-literal
    return /*@cc_on!@*/ false || !!document["documentMode"];
  }

  return false;
};

export const isSafari = (): boolean => {
  if (typeof document !== "undefined" && typeof window !== "undefined") {
    return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
  }

  return false;
};
