import styled from "@emotion/styled";
import * as React from "react";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useResponsive } from "../hooks/useResponsive";
import { useScroll } from "../hooks/useScroll";
import { breakpoints, rem, theme } from "../styling/theme";
import FlexBox from "./FlexBox";
import { getSpacerSize } from "./Spacer";

const StickyPanel = styled(FlexBox)<{ isSticky: boolean }>(({ isSticky }) => ({
  position: isSticky ? "sticky" : "relative",
  top: 0,
  transform: "translateZ(1)",
  zIndex: 3,
  backgroundColor: theme.color.whiteColor,
  border: `1px solid ${theme.color.greyLightColor}`,
  borderRadius: rem(8),
  width: "100%",
  height: isSticky ? "auto" : rem(96),
  marginBottom: rem(getSpacerSize("mini")),

  [theme.media.maxSm]: {
    top: rem(64),
  },
}));

type StickyHeaderT = {
  renderChildren: (fixed: boolean) => ReactNode;
};

const StickyHeader: FC<StickyHeaderT> = ({ renderChildren }) => {
  const [headerFixed, setHeaderFixed] = useState<boolean>(false);
  const [headerWidth, setHeaderWidth] = useState(0);

  const element = useRef<HTMLDivElement>(null);
  const { currentOffset } = useScroll(0);
  const { windowWidth, breakePoint } = useResponsive(breakpoints.sm);

  useEffect(() => {
    if (element.current) {
      const topOffset = 104;
      const topHeaderOffset = 75;

      setHeaderWidth(element.current.clientWidth);

      if (currentOffset > topOffset - topHeaderOffset || breakePoint) {
        setHeaderFixed(true);
        return;
      }

      setHeaderFixed(false);
    }
  }, [currentOffset, windowWidth]);

  return (
    <StickyPanel
      ref={element}
      isSticky={headerFixed}
      fullHeight={headerFixed ? false : true}
      customWidth={rem(headerWidth)}
    >
      {renderChildren(headerFixed)}
    </StickyPanel>
  );
};

export default StickyHeader;
