import { QueryClient } from "react-query";
import { MediaGroupToRemoveStateT } from "../../features/media-groups/MediaGoupsContainer";
import { MediaGroupT, QueryStackT } from "../../types";

type removeMediaGroupMutationT = (
  queryClient: QueryClient,
  mediaGroupsToRemove: MediaGroupToRemoveStateT,
  afterMutationHandle: () => void,
  queryStack: QueryStackT,
) => void;

export const removeMediaGroupMutation: removeMediaGroupMutationT = (
  queryClient,
  mediaGroupsToRemove,
  afterMutationHandle,
  queryStack,
) => {
  queryClient.setQueryData<MediaGroupT[] | undefined>(
    queryStack,
    (prevState) => {
      if (prevState) {
        return [
          ...prevState.filter(
            (item) => item.id !== mediaGroupsToRemove?.mediaGroupId,
          ),
        ];
      }
      return prevState;
    },
  );

  afterMutationHandle();
};
