import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ListT } from "../types";

export const createList = async (
  name: string,
  networkProfileIds: string[],
): Promise<ListT> => {
  const payload = {
    list: { name: name, network_profile_ids: networkProfileIds },
  };
  return axios
    .post(getApiUrl(ApiRoutesE.LISTS), payload)
    .then((res) => res.data.list);
};
