import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import * as React from "react";
import { createContext, FC } from "react";
import BlueLink from "../../../components/BlueLink";
import FlexBox from "../../../components/FlexBox";
import { rem, theme } from "../../../styling/theme";

export type ToastType = "success" | "info" | "warning" | "error";

type ToastButtonT = { text: string; onClick: () => void };
export type OpenToastT = (options: {
  type: ToastType;
  message: string;
  button?: ToastButtonT;
}) => void;

type ToastContextT = {
  openToast: OpenToastT;
};

export const ToastContext = createContext<ToastContextT>({
  openToast: () => {},
});

export const ToastContextProvider: FC = ({ children }) => {
  const [api, contextHolder] = message.useMessage();

  const content: OpenToastT = (options): JSX.Element => {
    const { type, message, button } = options;

    const baseStyle = {
      fontSize: rem(20),
    };

    const icon = {
      success: (
        <CheckCircleOutlined
          style={{ ...baseStyle, color: theme.color.successColor }}
          rev=""
        />
      ),
      info: (
        <InfoCircleOutlined
          style={{ ...baseStyle, color: theme.color.primaryColor }}
          rev=""
        />
      ),
      warning: (
        <InfoCircleOutlined
          style={{ ...baseStyle, color: theme.color.yellowPressedColor }}
          rev=""
        />
      ),
      error: (
        <CloseCircleOutlined
          style={{ ...baseStyle, color: theme.color.errorColor }}
          rev=""
        />
      ),
    }[type];

    return (
      <FlexBox flexDirection="row" gap={rem(8)} alignItems="center">
        {icon}
        {message}
        {button && (
          <BlueLink type="grey" text={button.text} onClick={button.onClick} />
        )}
      </FlexBox>
    );
  };

  const openToast: OpenToastT = (options) => {
    api.open({ content: content(options) });
  };

  return (
    <ToastContext.Provider value={{ openToast }}>
      {contextHolder}
      {children}
    </ToastContext.Provider>
  );
};
