import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";
import FlexBox from "../../../../../../components/FlexBox";
import Paragraph from "../../../../../../components/Paragraph";
import { getStringDate } from "../../../../../../helpers/date";
import { QueryKeys } from "../../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { getCampaignPartnerships } from "../../../../data-access/getCampaignPartnerships";
import {
  MediaGroupKindT,
  OutputsFilterStateT,
  PartnershipT,
} from "../../../../types";
import HashtagsTagsTags from "../HashtagsTags";
import FilterDate, { DateStateT } from "./FilterDate";
import FilterInfluencers from "./FilterInfluencers";
import FilterNetwork from "./FilterNetwork";
import FilterPostType from "./FilterPostType";

const Section = styled(FlexBox)({
  flexDirection: "column",
  alignItems: "flex-start",
  gap: rem(8),
});

export type RadioNetworkT = "all" | "instagram" | "tiktok";

type FilterT = {
  setFiliterState: Dispatch<SetStateAction<OutputsFilterStateT | undefined>>;
  campaignId: string;
};

const Filter: FC<FilterT> = ({ setFiliterState, campaignId }) => {
  const [hashtags, setHashtags] = useState<string[]>([]);
  const [dateState, setDateState] = useState<DateStateT | undefined>();
  const [postTypeState, setPostTypeState] = useState<MediaGroupKindT[]>([]);
  const [partnershipsState, setPartnershipsState] = useState<string[]>([]);
  const [network, setNetwork] = useState<RadioNetworkT>("all");

  const { t } = useTranslate("brands.campaign.outputs");

  const { data, isLoading } = useQuery(QueryKeys.PARTNERSHIPS, () =>
    getCampaignPartnerships(campaignId),
  );

  const filterStateWithoutType = {
    ...(dateState?.startDate && { from: getStringDate(dateState.startDate) }),
    ...(dateState?.endDate && { to: getStringDate(dateState.endDate) }),
    ...(hashtags.length > 0 && { hashtags }),
    ...{ partnership_ids: partnershipsState },
  };

  const onNetworkChangeHandle = (
    partnerships: PartnershipT[] | undefined,
    setPartnershipIds: (values: string[]) => void,
  ): void => {
    if (network === "all") setPartnershipIds([]);
    if (network === "instagram")
      setPartnershipIds(
        partnerships
          ? partnerships
              .filter((p) => p.network === "instagram")
              .map((p) => p.id)
          : [],
      );
    if (network === "tiktok")
      setPartnershipIds(
        partnerships
          ? partnerships.filter((p) => p.network === "tiktok").map((p) => p.id)
          : [],
      );
  };

  useEffect(() => {
    setFiliterState({
      ...filterStateWithoutType,
      ...(postTypeState.length > 0 && { kinds: postTypeState }),
    });
  }, [postTypeState, dateState, partnershipsState, hashtags]);

  return (
    <FlexBox
      justifyContent="flex-start"
      alignItems="stretch"
      flexDirection="column"
      gap={rem(24)}
    >
      <Section>
        <Paragraph paragraph={t("filter.post_type")} paragraphSize="small" />
        <FilterPostType
          postTypeState={postTypeState}
          setPostTypeState={setPostTypeState}
        />
      </Section>
      <Section>
        <Paragraph
          paragraph={t("filter.network.title")}
          paragraphSize="small"
        />
        <FilterNetwork
          partnerships={data ? data : []}
          setPartnershipIds={setPartnershipsState}
          network={network}
          setNetwork={setNetwork}
          onNetworkChangeHandle={onNetworkChangeHandle}
        />
      </Section>
      <Section>
        <Paragraph paragraph={t("filter.influencers")} paragraphSize="small" />
        <FilterInfluencers
          partnerships={data}
          setPartnerships={setPartnershipsState}
          network={network}
          isLoading={isLoading}
          onNetworkChangeHandle={onNetworkChangeHandle}
          partnershipIds={partnershipsState}
        />
      </Section>
      <Section>
        <Paragraph paragraph={t("filter.date")} paragraphSize="small" />
        <FilterDate dateState={dateState} setDateState={setDateState} />
      </Section>
      <Section>
        <Paragraph
          paragraph={t("filter.hashtags.title")}
          paragraphSize="small"
        />
        <HashtagsTagsTags
          hashtags={hashtags}
          setHashtags={setHashtags}
          campaignId={campaignId}
          allowNew={false}
          placeholder={t("filter.hashtags.placeholder")}
        />
      </Section>
    </FlexBox>
  );
};

export default Filter;
