import styled from "@emotion/styled";
import { Button } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../../components/FlexBox";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { NavigationKeyE, useRouterNavigate } from "../../../../router";
import { ContactCampaignT, ContactT } from "../../types";

const Wrapper = styled(FlexBox)({
  justifyContent: "flex-start",
  width: "100%",
  padding: `0 ${rem(16)} 0 ${rem(8)}`,
});

interface DataType {
  key: string;
  campaign: ContactCampaignT;
}

type CampaignsT = {
  contact: ContactT;
};

const Campaigns: FC<CampaignsT> = ({ contact }) => {
  const navigate = useRouterNavigate();

  const { t } = useTranslate(
    "brands.influencer_database.crm.contact.campaigns",
  );

  const columns: ColumnsType<DataType> = [
    {
      title: t("client"),
      dataIndex: "client",
      render: (_, record) => record.campaign.client_name,
    },
    {
      title: t("title"),
      dataIndex: "title",
      render: (_, record) => (
        <Button type="link" onClick={() => onClickHandle(record.campaign)}>
          {record.campaign.title}
        </Button>
      ),
    },
  ];
  const data: DataType[] = contact.campaigns.map((campaign) => ({
    key: campaign.id,
    campaign: campaign,
  }));

  const onClickHandle = (campaign: ContactCampaignT): void => {
    navigate(NavigationKeyE.Campaign, `${campaign.id}/influencers`);
  };

  return (
    <Wrapper>
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
      />
    </Wrapper>
  );
};

export default Campaigns;
