import styled from "@emotion/styled";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import FlexBox from "../components/FlexBox";
import { theme } from "../styling/theme";

export const CalendarWrapper = styled(FlexBox)`
  .rdrCalendarWrapper,
  .rdrDateDisplayWrapper,
  .rdrMonthAndYearWrapper,
  .rdrMonths,
  .rdrMonth {
    width: 100%;
  }

  .rdrMonth {
    padding: 0;
  }

  .rdrMonthAndYearWrapper {
    height: 40px;
    padding: 0 7px !important;
  }

  .rdrNextPrevButton {
    margin: 0 !important;
  }

  .rdrDayNumber span:after {
    background: ${theme.color.primaryColor} !important;
  }

  .rdrDayHovered .rdrDayNumber:after,
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrDayStartPreview,
  .rdrDayEndPreview,
  .rdrDayInPreview {
    color: ${theme.color.primaryColor} !important;
  }
`;
