import styled from "@emotion/styled";
import { Slider } from "antd";
import { SliderMarks } from "antd/es/slider";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useTranslate } from "../../../../../hooks/useTranslate";
import FilterContainer from "../components/FilterContainer";
import { SearchQueryT } from "../types";

const SliderWrapper = styled.div({
  width: "100%",
});

interface AgeGroupsT {
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
}

const AgeGoups: FC<AgeGroupsT> = ({ searchQuery, setFilterSearchQuery }) => {
  const [range, setRange] = useState<[number, number]>([1, 7]);
  const { t } = useTranslate("brands.influencer_database.filter.age_groups");
  const valueLabels = {
    1: "13",
    2: "18",
    3: "25",
    4: "35",
    5: "45",
    6: "65+",
  };
  const marks: SliderMarks = valueLabels;

  const handleInputOnChange = (values: [number, number]): void => {
    setRange(values);

    setFilterSearchQuery({
      age_groups: [valueLabels[values[0]], valueLabels[values[1]]],
    });
  };

  const mapLablesToValues = (labels: [string, string]): [number, number] => {
    const labelValues = {
      "13": 1,
      "18": 2,
      "25": 3,
      "35": 4,
      "45": 5,
      "65+": 6,
    };
    return [labelValues[labels[0]], labelValues[labels[1]]];
  };

  useEffect(() => {
    if (searchQuery.age_groups) {
      setRange(mapLablesToValues(searchQuery.age_groups));
    }
  }, [searchQuery]);

  return (
    <FilterContainer t={t}>
      <SliderWrapper>
        <Slider
          tooltip={{ open: false }}
          range
          marks={marks}
          step={1}
          min={1}
          max={6}
          value={range}
          onChange={handleInputOnChange}
        />
      </SliderWrapper>
    </FilterContainer>
  );
};

export default AgeGoups;
