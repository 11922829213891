import { PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Divider, Input, Modal, Select, Space } from "antd";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FlexBox from "../../../../../components/FlexBox";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import { ToastContext } from "../../../context-providers/ToastContextProvider";
import { createList } from "../../../data-access/createList";
import { getLists } from "../../../data-access/getLists";
import { updateListsMutation } from "../../../data-access/mutation/updateListsMutation";
import { updateList } from "../../../data-access/updateList";
import { ListT } from "../../../types";

type Option = {
  label: string;
  value: number;
};

const NewListWrapper = styled(FlexBox)({
  justifyContent: "flex-start",
  cursor: "pointer",
  padding: `${rem(4)} ${rem(12)} ${rem(8)} ${rem(12)}`,

  ":hover": {
    backgroundColor: theme.color.greyLightColor,
  },
});

interface ListSelectProps {
  networkProfileIds: string[];
  setNetworkProfileIds: Dispatch<SetStateAction<string[]>>;
}

const ListSelect: React.FC<ListSelectProps> = ({
  networkProfileIds,
  setNetworkProfileIds,
}) => {
  const [list, setList] = useState<ListT>();
  const [addNew, setAddNew] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");

  const { openToast } = useContext(ToastContext);

  const { t } = useTranslate("brands.influencer_database.table.lists");

  const { data } = useQuery(QueryKeys.LISTS, () => getLists());

  const options: Option[] = data
    ? data.map((list) => {
        return { label: list.name, value: list.id };
      })
    : [];

  const findList = (id: number): ListT | undefined => {
    return data?.find((l: ListT) => l.id === id);
  };

  const queryClient = useQueryClient();
  const { mutate: update } = useMutation(
    (listId: number) => {
      const list = findList(listId);
      const ids = list?.network_profiles.map((profile) => profile.id) || [];
      return updateList(listId, ids.concat(networkProfileIds), list?.name);
    },
    {
      onSuccess: (data) => {
        updateListsMutation(queryClient, data);
        setList(undefined);
        openToast({ type: "success", message: t("notifications.added") });
        setNetworkProfileIds([]);
      },
    },
  );
  const { mutate: create } = useMutation(
    (title: string) => createList(title, networkProfileIds),
    {
      onSuccess: (data) => {
        updateListsMutation(queryClient, data);
        setAddNew(false);
        openToast({ type: "success", message: t("notifications.created") });
        setTitle("");
        setNetworkProfileIds([]);
      },
    },
  );

  const onOkHandle = (): void => {
    title.length > 2 && create(title);
  };

  useEffect(() => {
    list && update(list.id);
  }, [list]);

  return (
    <>
      <Select
        showSearch
        options={options}
        placeholder={t("add_to_list")}
        style={{ width: 180 }}
        onChange={(listId: number) => setList(findList(listId))}
        value={undefined}
        dropdownRender={(list) => (
          <>
            {list}
            <Divider style={{ margin: "4px 0" }} />
            <NewListWrapper
              justifyContent="flex-start"
              onClick={() => setAddNew(true)}
            >
              <Space>
                <PlusOutlined rev="" />
                {t("add_new_list")}
              </Space>
            </NewListWrapper>
          </>
        )}
      />

      <Modal
        title={t("new_list")}
        open={addNew}
        zIndex={1051}
        onOk={onOkHandle}
        okButtonProps={{ disabled: title.length < 3 }}
        onCancel={() => setAddNew(false)}
      >
        <Input
          autoFocus
          placeholder={t("title")}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default ListSelect;
