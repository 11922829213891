import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ContactT } from "../features/influencer-database-detail/types";

export const getNetworkProfileContact = async (
  id: string,
): Promise<ContactT> => {
  return axios
    .get(getApiUrl(ApiRoutesE.NETWORK_PROFILES_CONTACT, id))
    .then((res) => res.data.contact);
};
