const POPUPS = {
  "notifications-popup": false,
};

// type setPopupT = (show: boolean, popupName: string) => void;
const setPopup = (show, popupName) => {
  const timer = setTimeout(() => {
    if (!show) {
      $(`#${popupName} #popup`).removeClass("visible");
      $(`#${popupName}-trigger`).removeClass("open");

      POPUPS[popupName] = show;
      return;
    }

    $(`#${popupName} #popup`).addClass("visible");
    $(`#${popupName}-trigger`).addClass("open");
    POPUPS[popupName] = show;
  }, 10);

  return () => clearTimeout(timer);
};

const _ = window;

$(document).on("click", "#notifications-popup-trigger", function () {
  setPopup(!POPUPS["notifications-popup"], "notifications-popup");
});

$(document).on("click", "#notifications-back-popup-trigger", function () {
  setPopup(!POPUPS["notifications-popup"], "notifications-popup");
});

// TODO: .path does not exist on Safari and Firefox + get rid of jQuery
// Handler for global closing popup by clicking anywhere out of popup
$(document).on("click", function (e) {
  const paths = e.originalEvent && e.originalEvent.path;

  Object.keys(POPUPS).map((popup) => {
    if (paths && !paths.find((item) => item.id === popup) && POPUPS[popup]) {
      setPopup(false, popup);
    }
  });
});
