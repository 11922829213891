import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Panel from "../../../components/Panel";
import { rem, theme } from "../../../styling/theme";
import Icon, { IconTypeE } from "../../../ui/icons/Icon";
import { AnimationOverlay, Line } from "../PlaceholderLoader";

const CampaignCardCover = styled(FlexBox)({
  height: "65%",
  backgroundColor: theme.color.greyLightColor,
});

const CampaignCardContent = styled(FlexBox)({
  height: "35%",
  padding: rem(16),
});

const Wrapper = styled(Panel)({
  margin: rem(8),
  position: "relative",
  width: `calc(33.33% - ${rem(16)})`,
  overflow: "hidden",
  height: rem(400),

  [theme.media.maxLg]: {
    width: `calc(50% - ${rem(16)})`,
  },

  [theme.media.maxSm]: {
    width: `calc(100% - ${rem(16)})`,
  },
});

const ChartCardPlaceholder: FC<{}> = () => (
  <Wrapper>
    <AnimationOverlay />
    <FlexBox
      customWidth={CustomWidthE.full}
      fullHeight={true}
      flexDirection="column"
    >
      <CampaignCardCover customWidth={CustomWidthE.full}>
        <Icon
          icon={IconTypeE.chart}
          size={100}
          color={theme.color.whiteColor}
        />
      </CampaignCardCover>
      <CampaignCardContent
        customWidth={CustomWidthE.full}
        alignItems="flex-start"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Line />
        <Line />
        <Line />
      </CampaignCardContent>
    </FlexBox>
  </Wrapper>
);

export default ChartCardPlaceholder;
