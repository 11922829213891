import axios from "axios";
import { PartnershipT } from "../../company-root/types";

export const updateApprovalRequest = async (
  url: string,
  payload: {
    approval_request: {
      approved_mentions: string[];
      tac_approval_request: true;
    };
  },
): Promise<{ partnerships: PartnershipT[] }> =>
  axios.patch(url, payload).then((res) => res.data);
