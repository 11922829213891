import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { CompetitiveAnalysisT } from "../types";

export const getCompetitiveAnalyses = async (): Promise<
  CompetitiveAnalysisT[]
> => {
  return axios
    .get(getApiUrl(ApiRoutesE.COMPETITIVE_ANALYSES))
    .then((res) => res.data.competitive_analyses);
};
