import { CheckCircleFilled, MoreOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Badge, Button } from "antd";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../styling/theme";
import FlexBox from "./FlexBox";

const Wrapper = styled(FlexBox)({
  position: "fixed",
  bottom: rem(20),
  right: rem(20),
  zIndex: 2,

  [theme.media.md]: {
    display: "none",
  },
});

interface FloatingBadgeButtonProps {
  title?: string;
  onClickHandler: (value: boolean) => void;
  icon?: JSX.Element;
  badge?: boolean;
}

const FloatingBadgeButton: FC<FloatingBadgeButtonProps> = ({
  title,
  onClickHandler,
  icon = <MoreOutlined />,
  badge = false,
}) => {
  return (
    <Wrapper>
      <Badge
        count={
          badge ? (
            <CheckCircleFilled
              rev=""
              style={{ fontSize: 24, color: theme.color.yellowColor }}
            />
          ) : undefined
        }
      >
        <Button
          onClick={() => onClickHandler(false)}
          style={{ fontSize: rem(18), height: "auto", borderRadius: rem(24) }}
          type="primary"
          size="large"
          icon={icon}
        >
          {!!title && title}
        </Button>
      </Badge>
    </Wrapper>
  );
};

export default FloatingBadgeButton;
