import { theme } from "../../../../styling/theme";
import { IconTypeE } from "../../../../ui/icons/Icon";

export enum CredibilityTierE {
  average = 70,
  good = 75,
  excellent = 85,
}

export const credibilityIcon = (value: number): IconTypeE => {
  if (value >= CredibilityTierE.excellent) {
    return IconTypeE.smileVery;
  }

  if (value >= CredibilityTierE.good) {
    return IconTypeE.smileSlightly;
  }

  if (value >= CredibilityTierE.average) {
    return IconTypeE.smileNeutral;
  }

  return IconTypeE.smileSad;
};

export const credibilityColor = (value: number): string => {
  if (value >= CredibilityTierE.excellent) {
    return "#219653";
  }

  if (value >= CredibilityTierE.good) {
    return "#27AE60";
  }

  if (value >= CredibilityTierE.average) {
    return "#F2994A";
  }

  return theme.color.errorColor;
};
