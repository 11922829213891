import { _o } from "./helpers";

const HIDDEN_CLASS = "hidden";

export const localStorageVisibilityInit = (): void => {
  const items = _o(document.getElementsByClassName("local-storage-visible"));

  if (items) {
    items.forEach((item) => {
      const KEY = (item as HTMLElement).dataset.key;
      const close = _o(item.getElementsByClassName("note-close"));

      if (KEY) {
        item.classList.add(HIDDEN_CLASS);

        if (!localStorage.getItem(KEY)) {
          item.classList.remove(HIDDEN_CLASS);
        }

        if (close) {
          close.forEach((ele) =>
            ele.addEventListener("click", () => {
              item.classList.add(HIDDEN_CLASS);
              localStorage.setItem(KEY, "TRUE");
            })
          );
        }
      }
    });
  }
};
