import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { PartnershipT } from "../types";

export const createCampaignPartnership = async (
  campaignId: string,
  instagram_handles: string,
  tiktok_handles: string,
): Promise<PartnershipT[]> => {
  return axios
    .post(getApiUrl(ApiRoutesE.CAMPAIGN_PARTNERSHIPS, campaignId), {
      partnership: { instagram_handles, tiktok_handles },
    })
    .then((res) => res.data.partnerships);
};
