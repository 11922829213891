import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ContactT } from "../features/influencer-database-detail/types";

type createContactAccountT = {
  profile_id: string;
  network: string;
};

export const createContactAccount = async (
  networkProfileId: string,
  contact_id: string,
  account: createContactAccountT,
): Promise<ContactT> => {
  return axios
    .post(
      getApiUrl(
        ApiRoutesE.NETWORK_PROFILES_CONTACT_CONFIRM_ACCOUNT,
        networkProfileId,
      ),
      { contact_id, account },
    )
    .then((res) => res.data.contact);
};
