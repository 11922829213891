import styled from "@emotion/styled";
import _ from "lodash";
import * as React from "react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import FlexBox from "../components/FlexBox";

const Wrapper = styled(FlexBox)<{ hidden: boolean }>(({ hidden }) => ({
  display: hidden ? "none" : "flex",
  position: "relative",
  height: "100%",
  width: "100%",
  overflowY: "scroll",
  overflowX: "hidden",
  alignItems: "baseline",
}));

type InfinityScrollContainerT = {
  isLoading: boolean;
  hasMoreItems: boolean;
  onLoadMore: () => void;
  className?: string;
  hidden?: boolean;
};

const InfinityScrollContainer: FC<InfinityScrollContainerT> = ({
  isLoading,
  hasMoreItems,
  onLoadMore,
  children,
  className,
  hidden = false,
}) => {
  const [scrollTop, setScrollTop] = useState<number | null>(null);
  const [scrollHeight, setScrollHeight] = useState<number | null>(null);

  const setScrollTopHandle = useCallback((e): void => {
    const { target } = e;

    setScrollTop(() => target.scrollTop + target.clientHeight);
    setScrollHeight(() => target.scrollHeight);
  }, []);

  useEffect(() => {
    if (
      (scrollTop === scrollHeight ||
        (scrollTop && scrollTop + 1 === scrollHeight)) &&
      !isLoading &&
      hasMoreItems
    ) {
      onLoadMore();
    }
  }, [scrollTop]);

  return (
    <Wrapper
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="stretch"
      onScroll={useRef(_.throttle((e) => setScrollTopHandle(e), 100)).current}
      className={className}
      hidden={hidden}
    >
      {children}
    </Wrapper>
  );
};

export default InfinityScrollContainer;
