import { useContext } from "react";
import { PermissionContext } from "../context-providers/PermissionContextProvider";
import { PermissionT } from "../entry-files/types";

const usePermission = (permission: PermissionT): boolean => {
  const { isAllowedTo } = useContext(PermissionContext);
  return isAllowedTo(permission);
};

export default usePermission;
