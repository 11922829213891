import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../features/Placeholders/PlaceholderLoader";
import { rem, theme } from "../../../../../styling/theme";
import { getCardsMap } from "../cardsProviders/cardsMap";
import GraphCard from "../components/GraphCard";
import ProfileThumbnails from "../ProfileThumbnails";
import { InfluencerProfileT } from "../types";
import BrandAffinity from "./BrandAffinity";

const StyledThumbnails = styled(FlexBox)({
  margin: `${rem(16)} ${rem(16)} 0 ${rem(16)}`,
});

const CardsWrapper = styled(FlexBox)({
  margin: `${rem(8)}`,

  [theme.media.maxSm]: {
    margin: `0 0 ${rem(16)}`,
    width: "100%",
  },
});

const BrandAffinityWrapper = styled(FlexBox)({
  padding: `0 ${rem(16)}`,
});

type CardsT = {
  networkProfile: InfluencerProfileT;
};

const Cards: FC<CardsT> = ({ networkProfile }) => {
  const { provider, cards: data } = networkProfile;
  const cards = provider && getCardsMap(provider);

  return (
    <FlexBox
      customWidth={CustomWidthE.full}
      flexDirection="column"
      alignItems="stretch"
    >
      {networkProfile.top_posts.length > 0 && (
        <StyledThumbnails>
          <ProfileThumbnails
            items={networkProfile.top_posts}
            thumbnailWidth={"20%"}
            thumbnailHeight={rem(250)}
          />
        </StyledThumbnails>
      )}
      <CardsWrapper
        flexWrap="wrap"
        alignItems="stretch"
        justifyContent="flex-start"
      >
        {!!cards && !!data ? (
          cards.map((item) => {
            const dataSource = data[item.type];

            if (!dataSource) {
              return null;
            }

            return (
              <GraphCard
                key={item.type}
                cardChartData={item}
                card={dataSource}
                provider={provider}
              />
            );
          })
        ) : (
          <PlaceholderLoader
            type={PlaceholdersTypeE.chart_card}
            count={9}
            direction="row"
            justifyContent="flex-start"
          />
        )}
      </CardsWrapper>
      {networkProfile.provider === "instagram" && (
        <BrandAffinityWrapper>
          <BrandAffinity networkProfileId={networkProfile.id} />
        </BrandAffinityWrapper>
      )}
    </FlexBox>
  );
};

export default Cards;
