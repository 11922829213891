import * as React from "react";
import { FC } from "react";
import Button from "../../../../components/Button";
import FlexBox from "../../../../components/FlexBox";
import { useTranslate } from "../../../../hooks/useTranslate";
import Footer from "../../ui/Footer";
import ApprovalInfo from "./ApprovalInfo";

type ApprovedStatementT = {
  mentions: string;
  email: string;
};

const ApprovedStatement: FC<ApprovedStatementT> = ({ mentions, email }) => {
  const { t } = useTranslate("influencer_root.approval_request");

  return (
    <>
      <ApprovalInfo
        title={t("success_title")}
        content={t("success_description", {
          mentions: mentions,
          email: email,
        })}
        image="medal"
      />
      <Footer>
        <FlexBox justifyContent="flex-end">
          <Button href="/influencers/reportings/approvals">
            {t("approval.all_approvals")}
          </Button>
        </FlexBox>
      </Footer>
    </>
  );
};

export default ApprovedStatement;
