import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import Button, { ButtonSizeE } from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import Popover from "../../../../../components/Popover";
import Spacer from "../../../../../components/Spacer";
import Tooltip from "../../../../../components/Tooltip";
import Input from "../../../../../features/Input";
import { useMutateMedia } from "../../../../../hooks/useMutateMedia";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import { CloseOverlay } from "../../../../../ui/CloseOverlay";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import InsightItemWrapper from "../../../ui/InsightItemWrapper";

const TriggerWrapper = styled(FlexBox)({
  cursor: "pointer",
});

const StyledInput = styled(Input)({
  padding: rem(10),
});

type InputUrlT = {
  id: string;
  label: string;
  url: string | null;
  permanentDisabled?: boolean;
  liveAddedMedia?: boolean;
};

const InputUrl: FC<InputUrlT> = ({
  id,
  label,
  url,
  permanentDisabled = false,
  liveAddedMedia,
}) => {
  const [urlState, setUrlState] = useState<string>(url || "");
  const [openPopover, setOpenPopover] = useState<"open" | "close">("close");
  const [hover, setHover] = useState<boolean>(false);

  const { addedQueryStack, queryStack } = useContext(MediaGroupsHelperContext);
  const { isDisabled, mutateMedia } = useMutateMedia(
    id,
    liveAddedMedia ? addedQueryStack : queryStack,
  );
  const { t } = useTranslate("brands.campaign.media_groups.form");

  const updateUrlHandle = (): void => {
    mutateMedia({ url: urlState });
    setOpenPopover("close");
  };

  useEffect(() => {
    if (openPopover === "close") {
      let timeout = setTimeout(() => {
        setUrlState(url || ""), 250;
      });

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, [openPopover, url]);

  return (
    <InsightItemWrapper
      label={label}
      disabled={false}
      clear={true}
      width="100%"
    >
      <FlexBox alignItems="stretch" justifyContent="stretch">
        {openPopover === "open" && (
          <CloseOverlay onClick={() => setOpenPopover("close")} />
        )}
        <FlexBox
          position="relative"
          zIndex={openPopover === "open" ? 3 : "auto"}
        >
          {!url ? (
            <Paragraph paragraph={"-"} fontWeight={500} />
          ) : (
            <a href={url} target="_blank" rel="noreferrer">
              <Paragraph
                paragraph={t("open_url")}
                color={theme.color.primaryColor}
                fontWeight={500}
              />
            </a>
          )}
          {!permanentDisabled && (
            <Popover
              forceActive={openPopover}
              padding={4}
              width={290}
              renderTrigger={() => (
                <TriggerWrapper
                  onClick={() => setOpenPopover("open")}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  position="relative"
                >
                  <Spacer size={"mini"} />
                  <Icon icon={IconTypeE.edit} size="small" />
                  <Tooltip
                    visible={openPopover === "close" && hover}
                    position={"top"}
                    tooltip={t("edit_url")}
                  />
                </TriggerWrapper>
              )}
              content={
                <FlexBox customWidth={CustomWidthE.full}>
                  <StyledInput value={urlState || ""} setValue={setUrlState} />
                  <Spacer size="micro" />
                  <Button
                    size={ButtonSizeE.small}
                    onClick={() => updateUrlHandle()}
                    disabled={isDisabled}
                  >
                    <Icon icon={IconTypeE.check} size="small" />
                  </Button>
                </FlexBox>
              }
            />
          )}
        </FlexBox>
      </FlexBox>
    </InsightItemWrapper>
  );
};

export default InputUrl;
