import {
  DoubleRightOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Alert, Button, Input, InputRef, Modal } from "antd";
import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Avatar from "../../../../../../components/Avatar";
import BlueLink from "../../../../../../components/BlueLink";
import FlexBox, { CustomWidthE } from "../../../../../../components/FlexBox";
import Paragraph from "../../../../../../components/Paragraph";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../../styling/theme";
import { createContactAccount } from "../../../../data-access/createContactAccount";
import { createContactAccountMutation } from "../../../../data-access/mutation/createContactAccount";
import { updateContactMutation } from "../../../../data-access/mutation/updateContactMutation";
import { resolveContactConflict } from "../../../../data-access/resolveContactConflict";
import { NavigationKeyE } from "../../../../router";
import {
  ContactRelatedAccountT,
  ContactT,
  InfluencerProfileT,
} from "../../types";

const Wrapper = styled(FlexBox)({
  justifyContent: "space-between",
  width: "100%",
  minHeight: rem(48),
  gap: rem(12),
});

const EditButtonWrapper = styled(FlexBox)<{ active: boolean }>(
  ({ active }) => ({
    visibility: active ? "visible" : "hidden",
    justifyContent: "flex-start",
  }),
);

const AccountWrapper = styled(FlexBox)({
  flexDirection: "column",
  gap: rem(8),
});

const IconWrapper = styled(FlexBox)({
  width: rem(32),
  height: rem(32),
  borderRadius: rem(16),
  backgroundColor: theme.color.lighterBlueColor,
});

const ModalInsideWrapper = styled(FlexBox)({
  alignItems: "flex-start",
  gap: rem(16),
  paddingBottom: rem(24),
});

const StyledButton = styled(Button)({
  marginLeft: rem(16),
});

type NetworkAccountT = {
  contact: ContactT;
  account: ContactRelatedAccountT | undefined;
  networkProfile: InfluencerProfileT;
  network: string;
  editVisible: boolean;
};

const NetworkAccount: FC<NetworkAccountT> = ({
  contact,
  account,
  networkProfile,
  network,
  editVisible,
}) => {
  const [border, setBorder] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [value, setValue] = useState<string>(account?.profile_id || "");
  const [openModal, setOpenModal] = useState<boolean>(false);

  const related_profile = account?.related_profile;

  const ref = useRef<InputRef>(null);
  const { t } = useTranslate("brands.influencer_database.crm.contact");

  const queryClient = useQueryClient();
  const { mutate: confirm } = useMutation(
    () =>
      createContactAccount(networkProfile.id, contact.id, {
        profile_id: value,
        network,
      }),
    {
      onSuccess: (data) => {
        createContactAccountMutation(queryClient, data);
      },
    },
  );

  const { mutate: resolve } = useMutation(
    (conflictedNetworkProfileId: string) =>
      resolveContactConflict(networkProfile.id, conflictedNetworkProfileId),
    {
      onSuccess: (data) => {
        updateContactMutation(queryClient, data);
      },
    },
  );

  const onAddButtonClickHandle = (): void => {
    setShowInput(true);
  };

  const onFocusHandle = (): void => {
    setShowInput(true);
    setBorder(true);
  };

  const onPressEnterHandle = (): void => {
    setShowInput(false);
    ref.current?.blur();
    setBorder(false);
  };

  const onBlurHandle = (): void => {
    onPressEnterHandle();
    value.length > 0 && value !== account?.profile_id && confirm();
  };

  const onResolveButtonHandle = (networkProfileId: string): void => {
    resolve(networkProfileId);
    setOpenModal(false);
  };

  const onPreviewProfileButtonClicked = (id: string): void => {
    window.open(
      `${window.location.origin}${NavigationKeyE.Database}/${id}/overview`,
      "_blank",
    );
  };

  const openAccountProfile = (account: ContactRelatedAccountT): void => {
    const { related_profile } = account;

    if (related_profile?.network_profile_id) {
      onPreviewProfileButtonClicked(related_profile.network_profile_id);
    }
  };

  const newAccountInput = (): JSX.Element => {
    return (
      <>
        {showInput ? (
          <FlexBox
            customWidth={CustomWidthE.half}
            onMouseEnter={() => setBorder(true)}
            onMouseLeave={() => !showInput && setBorder(false)}
          >
            <Input
              ref={ref}
              value={value}
              bordered={border}
              addonBefore="@"
              onFocus={onFocusHandle}
              onChange={(e) => setValue(e.target.value)}
              onBlur={onBlurHandle}
              onPressEnter={onPressEnterHandle}
            />
          </FlexBox>
        ) : (
          <Button type="link" onClick={() => onAddButtonClickHandle()}>
            {t("add.account")}
          </Button>
        )}
      </>
    );
  };

  useEffect(() => {
    showInput && ref.current?.focus();
  }, [showInput]);

  useEffect(() => {
    setValue(account?.profile_id || "");
  }, [account]);

  return (
    <Wrapper>
      {account && !showInput ? (
        <>
          <FlexBox
            customWidth="92%"
            justifyContent="flex-start"
            flexWrap="wrap"
            gap={rem(16)}
          >
            {account.related_profile?.network_profile_id ? (
              <BlueLink
                text={`@${account.profile_id}`}
                type={account.status === "confirmed" ? "default" : "grey"}
                onClick={() => openAccountProfile(account)}
              />
            ) : (
              <Paragraph
                paragraph={`@${account.profile_id}`}
                paragraphSize="small"
              />
            )}
            {account.status === "pending" && (
              <Button onClick={() => confirm()} size="small">
                {t("confirm")}
              </Button>
            )}
            {account.status === "conflict" && (
              <Alert
                showIcon
                type="warning"
                message={t("conflict.alert")}
                action={
                  <StyledButton size="small" onClick={() => setOpenModal(true)}>
                    {t("resolve")}
                  </StyledButton>
                }
              />
            )}
          </FlexBox>
          {account.network !== networkProfile.provider && (
            <EditButtonWrapper customWidth="8%" active={editVisible}>
              <Button
                onClick={() => setShowInput(true)}
                type="text"
                icon={<EditOutlined />}
              />
            </EditButtonWrapper>
          )}
        </>
      ) : (
        newAccountInput()
      )}

      {account && related_profile && (
        <Modal
          open={openModal}
          onCancel={() => setOpenModal(false)}
          onOk={() => onResolveButtonHandle(related_profile.network_profile_id)}
          okText={t("ok")}
          cancelText={t("cancel")}
        >
          <ModalInsideWrapper>
            <InfoCircleOutlined
              style={{
                fontSize: rem(22),
                color: theme.color.yellowPressedColor,
              }}
            />
            <FlexBox
              flexDirection="column"
              alignItems="flex-start"
              gap={rem(8)}
            >
              <Paragraph
                paragraph={t("conflict.title")}
                paragraphSize="big"
                fontWeight={600}
              />
              <Paragraph paragraph={t("conflict.description")} />
              <FlexBox
                justifyContent="space-around"
                customWidth={CustomWidthE.full}
              >
                <AccountWrapper>
                  <Avatar
                    name="test"
                    src={networkProfile.avatar_url}
                    network={networkProfile.provider}
                    size={40}
                  />
                  <Paragraph paragraph={`CRM @${networkProfile.handle}`} />
                </AccountWrapper>
                <IconWrapper>
                  <DoubleRightOutlined
                    style={{
                      fontSize: rem(12),
                      color: theme.color.primaryColor,
                    }}
                  />
                </IconWrapper>
                <AccountWrapper>
                  <Avatar
                    name="test"
                    src={related_profile.avatar_url}
                    network={account.network}
                    size={40}
                  />
                  <Button
                    type="link"
                    onClick={() =>
                      onPreviewProfileButtonClicked(
                        related_profile.network_profile_id,
                      )
                    }
                  >
                    {`CRM @${related_profile.profile_id}`}
                  </Button>
                </AccountWrapper>
              </FlexBox>
            </FlexBox>
          </ModalInsideWrapper>
        </Modal>
      )}
    </Wrapper>
  );
};

export default NetworkAccount;
