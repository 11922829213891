import styled from "@emotion/styled";
import { InputNumber, Select, theme } from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import { CityT } from "../types";

const { useToken } = theme;

const defaultPercentage = 10;

const Wrapper = styled(FlexBox)<{ hidden: boolean; backgroundColor: string }>(
  ({ hidden, backgroundColor }) => ({
    display: hidden ? "none" : "flex",
    flexDirection: "column",
    width: "100%",
    paddingLeft: rem(25),
    gap: rem(10),
    backgroundColor: backgroundColor,
    borderRadius: rem(4),
    padding: rem(8),
  }),
);

export type CityFilterT = {
  name: string;
  percentage: number;
  enabled: boolean;
};

interface CitiesSelectProps {
  cities: CityT[];
  hidden: boolean;
  setCities: Dispatch<SetStateAction<CityFilterT[]>>;
}

const CitiesSelect: FC<CitiesSelectProps> = ({ cities, hidden, setCities }) => {
  const [cityValues, setCityValues] = useState<CityFilterT[]>([]);

  const { token } = useToken();

  const { t } = useTranslate("brands.influencer_database.filter.countries");
  const { t: translateCity } = useTranslate("cities");

  const options = cities.map((city) => ({
    label: translateCity(city.name),
    value: city.name,
  }));

  const optionsWithoutSelected = options.filter((option) => {
    return !cityValues.find((city) => city.name === option.value);
  });

  const handleInputOnChange = (value: string): void => {
    setCityValues((prev) => [
      ...prev,
      { name: value, percentage: defaultPercentage, enabled: true },
    ]);
  };

  const onCheckboxChange = (city: CityFilterT, checked: boolean): void => {
    setCityValues((prev) =>
      prev.map((prevCity) =>
        prevCity.name === city.name
          ? { ...prevCity, enabled: checked }
          : prevCity,
      ),
    );
  };

  const onPercentageChange = (
    city: CityFilterT,
    percentage: number | null,
  ): void => {
    setCityValues((prev) =>
      prev.map((prevCity) =>
        prevCity.name === city.name
          ? { ...prevCity, percentage: percentage || defaultPercentage }
          : prevCity,
      ),
    );
  };

  useEffect(() => {
    setCities(cityValues.filter((city) => city.enabled));
  }, [cityValues]);

  return (
    <Wrapper hidden={hidden} backgroundColor={token.colorPrimaryBg}>
      <Select
        showSearch
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        value={null}
        style={{ width: "100%" }}
        options={optionsWithoutSelected}
        placeholder={t("placeholder")}
        onChange={handleInputOnChange}
      />
      {cityValues.map((city) => (
        <FlexBox
          key={city.name}
          justifyContent="space-between"
          customWidth={CustomWidthE.full}
        >
          <Checkbox
            checked={city.enabled}
            onChange={(e) => onCheckboxChange(city, e.target.checked)}
          >
            {translateCity(city.name)}
          </Checkbox>
          <InputNumber
            style={{ width: rem(130) }}
            addonBefore=">"
            addonAfter="%"
            min={10}
            max={50}
            value={city.percentage}
            onChange={(value) => onPercentageChange(city, value)}
          />
        </FlexBox>
      ))}
    </Wrapper>
  );
};

export default CitiesSelect;
