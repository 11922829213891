import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
//import { ReactQueryDevtools } from "react-query/devtools";
import EntryFilesRouter from "./EntryFilesRouter";

export default ({
  id,
  props,
  loader,
  translations,
  locale,
}: React.ComponentProps<typeof EntryFilesRouter>): JSX.Element => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <EntryFilesRouter
        id={id}
        props={props}
        translations={translations}
        locale={locale}
        loader={loader}
      />
    </QueryClientProvider>
  );
};
