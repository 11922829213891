import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ContactT } from "../features/influencer-database-detail/types";

export const resolveContactConflict = async (
  networkProfileId: string,
  conflictNetworkProfileId: string,
): Promise<ContactT> => {
  return axios
    .post(
      getApiUrl(
        ApiRoutesE.NETWORK_PROFILES_CONTACT_RESOLVE_CONFLICT,
        networkProfileId,
      ),
      { conflict_network_profile_id: conflictNetworkProfileId },
    )
    .then((res) => res.data.contact);
};
