import styled from "@emotion/styled";
import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation, useQueryClient } from "react-query";
import BlueLink from "../../../../../components/BlueLink";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import VisibleWrapper from "../../../../../components/VisibleWrapper";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import Dropdown from "../../../../../ui/Dropdown";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import { createMedia } from "../../../data-access/createMedia";
import { createMediaMutation } from "../../../data-access/mutation/createMediaMutation";
import { moveMediaToMediaGroupMutation } from "../../../data-access/mutation/moveMediaToMediaGroupMutation";
import { updateMedia } from "../../../data-access/updateMedia";
import { ExistedOutputT, MediaGroupT, MediaT } from "../../../types";

const HANDLES_HEIGHT = 24;

type LinePartStyleT = {
  precentageWidth: number;
  active: boolean;
};

export const AbsoluteTopWrapper = styled(FlexBox)({
  position: "absolute",
  width: `calc(100% - ${rem(24)})`,
  height: rem(24),
  top: 0,
  zIndex: 1,
});

const HandlesWrapper = styled(FlexBox)({
  height: rem(HANDLES_HEIGHT),
  flex: 1,
});

const LinePart = styled(FlexBox)<LinePartStyleT>(
  ({ precentageWidth, active }) => ({
    width: `calc(${precentageWidth}% - ${rem(3)})`,
    margin: `0 ${rem(3)} 0 0`,
    height: rem(4),
    borderRadius: rem(2),
    backgroundColor: active
      ? theme.color.textGreyDarkColor
      : theme.color.greyColor,
    transition: "all 0.25s",
    transitionTimingFunction: theme.timing.default,
  }),
);

const ArrowWrapper = styled(VisibleWrapper)({
  position: "absolute",
  top: 0,
  width: "100%",
  justifyContent: "space-between",
});

const Arrow = styled(FlexBox)({
  padding: rem(5),
  borderRadius: rem(4),
  backgroundColor: theme.color.textLightGreyColor,
  boxShadow: "0px 2px 9px rgb(0 0 0 / 10%)",
  transition: "all 0.25s",
  transitionTimingFunction: theme.timing.default,
  cursor: "pointer",
  ["&:hover"]: {
    backgroundColor: theme.color.textGreyColor,
  },
});

const DropdownWrapper = styled(FlexBox)({
  padding: `${rem(16)} ${rem(12)}`,
  cursor: "pointer",
  minWidth: "100%",
  justifyContent: "flex-start",

  ["&:hover"]: {
    backgroundColor: theme.color.backgroundColor,
  },
});

type StorySetHandlesT = {
  mediaGroup: MediaGroupT;
  currentCard: { index: number; id: string };
  setCurrentCard: Dispatch<SetStateAction<{ index: number; id: string }>>;
  removeVisible: boolean;
  liveAddedMediaGroup?: boolean;
};

const StorySetHandles: FC<StorySetHandlesT> = ({
  mediaGroup,
  currentCard,
  setCurrentCard,
  removeVisible,
  liveAddedMediaGroup,
}) => {
  const { medias } = mediaGroup;
  const [mediasCount, setMediasCount] = useState<number>(medias.length);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [existedOutput, setExistedOutput] = useState<
    ExistedOutputT | undefined
  >();
  const [existedMedia, setExistedMedia] = useState<MediaT | undefined>();

  const { addedQueryStack, queryStack, campaign, setExistedOutputsState } =
    useContext(MediaGroupsHelperContext);
  const { t } = useTranslate("brands.campaign.media_groups.buttons");

  const currentQueryStack = liveAddedMediaGroup ? addedQueryStack : queryStack;

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    () =>
      createMedia({
        media: { kind: "story", media_group_id: mediaGroup.id },
      }),
    {
      onSuccess: (data) => {
        createMediaMutation(
          queryClient,
          data,
          mediaGroup.id,
          currentQueryStack,
        );
      },
    },
  );

  const { mutate: mutateMedia } = useMutation(
    (existedOutput: ExistedOutputT) => {
      const {
        media_url,
        timestamp,
        comments,
        likes,
        impressions,
        reach,
        permalink,
      } = existedOutput;

      return createMedia({
        media: {
          media_group_id: mediaGroup.id,
          kind: "story",
          published_at: timestamp,
          url: permalink,
          image_urls: media_url ? [media_url] : undefined,
          likes: likes,
          comments: comments,
          reach: reach,
          impressions: impressions,
        },
      });
    },
    {
      onSuccess: (data) => {
        createMediaMutation(
          queryClient,
          data,
          mediaGroup.id,
          currentQueryStack,
        );
      },
    },
  );

  const { mutate: mutateUpdateMedia } = useMutation(updateMedia, {
    onSuccess: () => {
      if (existedMedia) {
        setTimeout(() => {
          moveMediaToMediaGroupMutation(
            queryClient,
            currentQueryStack,
            existedMedia,
            mediaGroup,
            clearStatesAfterRemoving,
          );
        }, 300);
      }
    },
  });

  const clearStatesAfterRemoving = (): void => {};

  const addStoryManuallyHandle = (): void => {
    if (!isLoading) {
      mutate();
    }
  };

  const addStoryFromExistingHandle = (): void => {
    if (campaign) {
      setExistedOutputsState({
        modalAttributes: [
          {
            variant: "output",
            requestParams: {
              type: "story",
              partnership_id: mediaGroup.partnership.id,
            },
          },
          {
            variant: "media",
            requestParams: {
              type: "story",
              campaign_id: campaign.id,
              kinds: ["story_set"],
              partnership_ids: [mediaGroup.partnership.id],
              exclude_ids: [mediaGroup.id],
            },
          },
        ],
        setExistedOutput,
        setExistedMedia,
      });
    }
  };

  useEffect(() => {
    existedOutput && mutateMedia(existedOutput);
  }, [existedOutput]);

  useEffect(() => {
    if (existedMedia) {
      mutateUpdateMedia({
        id: existedMedia.id,
        updatedMediaData: {
          media: {
            ...existedMedia,
            media_group_id: mediaGroup.id,
          },
        },
      });
    }
  }, [existedMedia]);

  useEffect(() => {
    if (mediasCount !== medias.length) {
      setCurrentCard({
        index: medias.length - 1,
        id: medias[medias.length - 1].id,
      });
    }

    setMediasCount(medias.length);
  }, [medias]);

  return (
    <AbsoluteTopWrapper justifyContent="space-between" flexDirection="row">
      <HandlesWrapper position="relative">
        <ArrowWrapper visible={mediasCount > 1}>
          <Arrow
            onClick={() =>
              setCurrentCard((prevState) =>
                prevState.index === 0
                  ? prevState
                  : {
                      index: prevState.index - 1,
                      id: medias[prevState.index - 1].id,
                    },
              )
            }
          >
            <Icon
              icon={IconTypeE.arrowLeft}
              size="mini"
              color={theme.color.whiteColor}
            />
          </Arrow>
          <Arrow
            onClick={() =>
              setCurrentCard((prevState) =>
                prevState.index + 1 === mediasCount
                  ? prevState
                  : {
                      index: prevState.index + 1,
                      id: medias[prevState.index + 1].id,
                    },
              )
            }
          >
            <Icon
              icon={IconTypeE.arrowRight}
              size="mini"
              color={theme.color.whiteColor}
            />
          </Arrow>
        </ArrowWrapper>
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          {medias.length > 1 &&
            medias.map((_, index) => (
              <LinePart
                key={index}
                precentageWidth={100 / medias.length}
                active={currentCard.index === index}
              />
            ))}
        </FlexBox>
      </HandlesWrapper>
      <Spacer size="mini" />
      <FlexBox
        onMouseEnter={() => setActiveDropdown(true)}
        onMouseLeave={() => setActiveDropdown(false)}
      >
        <BlueLink
          icon={IconTypeE.plus}
          text={t("add_story.title")}
          iconSize="mini"
          marginTop={rem(0)}
          paragraphSize="small"
        />
        <Dropdown active={activeDropdown} width={rem(150)}>
          <FlexBox flexDirection="column" customWidth={CustomWidthE.full}>
            <DropdownWrapper onClick={addStoryManuallyHandle}>
              <Icon icon={IconTypeE.edit} size="small" />
              <Spacer size="mini" />
              <Paragraph paragraph={t("add_story.manual")} cursor="pointer" />
            </DropdownWrapper>
            <DropdownWrapper onClick={addStoryFromExistingHandle}>
              <Icon icon={IconTypeE.igStory} size="small" />
              <Spacer size="mini" />
              <Paragraph paragraph={t("add_story.existing")} cursor="pointer" />
            </DropdownWrapper>
          </FlexBox>
        </Dropdown>
      </FlexBox>
    </AbsoluteTopWrapper>
  );
};

export default StorySetHandles;
