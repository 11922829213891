import styled from "@emotion/styled";
import { InputNumber, Radio, Space } from "antd";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import CitiesSelect, { CityFilterT } from "../components/CitiesSelect";
import FilterContainer from "../components/FilterContainer";
import { FilterDataT, SearchQueryT } from "../types";

const defaultPercentage = 10;

const Wrapper = styled(FlexBox)({
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  gap: rem(10),
});

const StyledRadioGroup = styled(Radio.Group)({
  width: "100%",
});

const StyledInput = styled(InputNumber)<{ hidden: boolean }>(({ hidden }) => ({
  display: hidden ? "none" : "flex",
}));

interface CountriesCitiesProps {
  data: FilterDataT;
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
}

const CountriesCities: FC<CountriesCitiesProps> = ({
  data,
  searchQuery,
  setFilterSearchQuery,
}) => {
  const cities = data?.cities;

  const [country, setCountry] = useState<"cz" | "sk">();
  const [czPercentage, setCzPercentage] = useState<number>(defaultPercentage);
  const [skPercentage, setSkPercentage] = useState<number>(defaultPercentage);
  const [skCities, setSkCities] = useState<CityFilterT[]>([]);
  const [czCities, setCzCities] = useState<CityFilterT[]>([]);

  const { t } = useTranslate("brands.influencer_database.filter.countries");

  const setQuery = (): void => {
    const cities = country === "cz" ? czCities : skCities;
    const percentage = country === "cz" ? czPercentage : skPercentage;

    setFilterSearchQuery({
      countries: country ? [`${country};${percentage}`] : [],
      cities: cities.map((city) => `${city.name};${city.percentage}`),
    });
  };

  const onPercentageChange = (value: number | null): void => {
    if (!value) {
      return;
    }

    if (country === "cz") {
      setCzPercentage(value);
    }
    if (country === "sk") {
      setSkPercentage(value);
    }
  };

  const clearCountry = (): void => {
    const { countries, cities } = searchQuery;

    if (countries.length === 0 && cities.length === 0) {
      setCountry(undefined);
    }
  };

  useEffect(() => {
    clearCountry();
  }, [searchQuery]);

  useEffect(() => {
    setQuery();
  }, [country, skCities, czCities, czPercentage, skPercentage]);

  return (
    <FilterContainer t={t}>
      <StyledRadioGroup
        name="countriesCities"
        value={country}
        defaultValue={undefined}
        onChange={(e) => setCountry(e.target.value)}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Radio value={undefined}>{t("none")}</Radio>
          <Wrapper>
            <FlexBox
              customWidth={CustomWidthE.full}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Radio value="cz">{t("cz")}</Radio>
              <StyledInput
                hidden={country !== "cz"}
                addonBefore=">"
                addonAfter="%"
                min={10}
                max={50}
                style={{ width: rem(130) }}
                defaultValue={defaultPercentage}
                value={czPercentage}
                onChange={(value) => onPercentageChange(value as number)}
              />
            </FlexBox>
            {searchQuery.provider_eq === "instagram" && (
              <CitiesSelect
                cities={cities.filter((c) => c.country === "cz")}
                hidden={country !== "cz"}
                setCities={setCzCities}
              />
            )}
          </Wrapper>
          <Wrapper>
            <FlexBox
              customWidth={CustomWidthE.full}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Radio value="sk">{t("sk")}</Radio>
              <StyledInput
                hidden={country !== "sk"}
                addonBefore=">"
                addonAfter="%"
                min={10}
                max={50}
                style={{ width: rem(130) }}
                defaultValue={defaultPercentage}
                value={skPercentage}
                onChange={(value) => onPercentageChange(value as number)}
              />
            </FlexBox>
            {searchQuery.provider_eq === "instagram" && (
              <CitiesSelect
                cities={cities.filter((c) => c.country === "sk")}
                hidden={country !== "sk"}
                setCities={setSkCities}
              />
            )}
          </Wrapper>
        </Space>
      </StyledRadioGroup>
    </FilterContainer>
  );
};

export default CountriesCities;
