import axios from "axios";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { BrandAffinityT } from "../features/influencer-database-detail/types";

export const getBrandAffinities = async (
  id: string,
  pastDays: number,
): Promise<BrandAffinityT[]> => {
  return axios
    .get(
      getUrlWithQuery(
        getApiUrl(ApiRoutesE.NETWORK_PROFILE_BRAND_AFFINITY, id),
        {
          past_days: pastDays,
        },
      ),
    )
    .then((res) => res.data.brand_affinities);
};
