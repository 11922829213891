import styled from "@emotion/styled";
import FlexBox, { AlignT, CustomWidthE } from "../../components/FlexBox";
import { OverflowOptionsT } from "../../components/VisibleWrapper";
import { rem } from "../../styling/theme";

const TableColumn = styled(FlexBox)<{
  columnWidth?: CustomWidthE | string;
  justifyContent?: AlignT;
  padding?: string;
  overflow?: OverflowOptionsT;
}>(
  ({
    columnWidth = "40%",
    justifyContent = "flex-start",
    padding = `${rem(10)} ${rem(16)}`,
    overflow = "visible",
  }) => ({
    padding,
    justifyContent,
    minWidth: columnWidth,
    width: columnWidth,
    overflow,
  }),
);

export default TableColumn;
