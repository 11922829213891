import styled from "@emotion/styled";
import { Button, Divider } from "antd";
import { omit } from "lodash";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import FlexBox from "../../../../components/FlexBox";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useTranslate } from "../../../../hooks/useTranslate";
import { breakpoints, rem, theme } from "../../../../styling/theme";
import { NetworkProfileT } from "../../../types";
import AgeGoups from "./filters/AgeGroups";
import CountriesCities from "./filters/CountriesCities";
import Credibility from "./filters/Credibility";
import EngagementRate from "./filters/EngagementRate";
import Followers from "./filters/Followers";
import FollowersGender from "./filters/FollowersGender";
import FollowersInterests from "./filters/FollowersInterests";
import InfluencerGender from "./filters/InfluencerGender";
import InfluencerInterests from "./filters/InfluencerInterests";
import Lists from "./filters/Lists";
import MentionsHashtags from "./filters/MentionsHashtags";
import Networks from "./filters/Networks";
import Tags from "./filters/Tags";
import { defaultSearchQuery } from "./InfluencerDatabaseContainer";
import { FilterDataT, SearchQueryT } from "./types";

const StyledDivider = styled(Divider)({
  margin: `${rem(16)} 0`,
});

const Scroll = styled(FlexBox)({
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  overflowY: "scroll",
  padding: rem(20),

  [theme.media.md]: {
    padding: rem(20),
  },
});

const Actions = styled(FlexBox)<{ show: boolean }>(({ show }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.color.greyColor}`,
  padding: rem(10),
  gap: rem(12),

  [theme.media.md]: {
    display: show ? "flex" : "none",
  },
}));

const ShowResultsButton = styled(Button)({
  width: "100%",
});

const CloseButton = styled(Button)({
  width: "100%",
});

type FilterT = {
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
  data: FilterDataT | undefined;
  isLoading: boolean;
  usedFilter: boolean;
  listNetworkProfiles: NetworkProfileT[] | undefined;
  setListNetworkProfiles: (
    networkProfiles: NetworkProfileT[] | undefined,
  ) => void;
  setOptions: Dispatch<SetStateAction<NetworkProfileT[]>>;
  hiddenFilter: boolean;
  setHiddenFilter: Dispatch<SetStateAction<boolean>>;
};

const Filter: FC<FilterT> = ({
  searchQuery,
  setFilterSearchQuery,
  data,
  isLoading,
  usedFilter,
  listNetworkProfiles,
  setListNetworkProfiles,
  setOptions,
  hiddenFilter,
  setHiddenFilter,
}) => {
  const defaultQuery = omit(defaultSearchQuery, ["provider_eq"]);

  const { breakePoint } = useResponsive(breakpoints.md);

  const { t } = useTranslate("brands.influencer_database.filter");

  const onClearHandle = (): void => {
    setFilterSearchQuery(defaultQuery);
    setHiddenFilter(true);
  };

  const afterProviderChangeHandle = (): void => {
    setOptions([]);
    onClearHandle();
  };

  return (
    <>
      {isLoading || !data ? (
        <PlaceholderLoader type={PlaceholdersTypeE.table_item} />
      ) : (
        <>
          <Scroll>
            <Lists setListNetworkProfiles={setListNetworkProfiles} />
            {listNetworkProfiles === undefined && (
              <>
                <StyledDivider dashed />
                <Networks
                  searchQuery={searchQuery}
                  setFilterSearchQuery={setFilterSearchQuery}
                  afterChangeHandle={afterProviderChangeHandle}
                />
                <StyledDivider dashed />
                <Followers
                  data={data}
                  searchQuery={searchQuery}
                  setFilterSearchQuery={setFilterSearchQuery}
                />
                <StyledDivider dashed />
                <Tags
                  searchQuery={searchQuery}
                  setFilterSearchQuery={setFilterSearchQuery}
                  brandTags={data.tags}
                />
                <StyledDivider dashed />
                <EngagementRate
                  searchQuery={searchQuery}
                  setFilterSearchQuery={setFilterSearchQuery}
                />
                {searchQuery.provider_eq === "instagram" && (
                  <>
                    <StyledDivider dashed />
                    <Credibility
                      searchQuery={searchQuery}
                      setFilterSearchQuery={setFilterSearchQuery}
                    />
                  </>
                )}
                <StyledDivider dashed />
                <AgeGoups
                  searchQuery={searchQuery}
                  setFilterSearchQuery={setFilterSearchQuery}
                />
                <StyledDivider dashed />
                <InfluencerGender
                  searchQuery={searchQuery}
                  setFilterSearchQuery={setFilterSearchQuery}
                />
                <StyledDivider dashed />
                <FollowersGender
                  searchQuery={searchQuery}
                  setFilterSearchQuery={setFilterSearchQuery}
                />
                <StyledDivider dashed />
                {searchQuery.provider_eq === "instagram" && (
                  <>
                    <FollowersInterests
                      data={data}
                      searchQuery={searchQuery}
                      setFilterSearchQuery={setFilterSearchQuery}
                    />
                    <StyledDivider dashed />
                    <InfluencerInterests
                      data={data}
                      searchQuery={searchQuery}
                      setFilterSearchQuery={setFilterSearchQuery}
                    />
                    <StyledDivider dashed />
                    <MentionsHashtags
                      searchQuery={searchQuery}
                      setFilterSearchQuery={setFilterSearchQuery}
                    />
                  </>
                )}
                <StyledDivider dashed />
                <CountriesCities
                  data={data}
                  searchQuery={searchQuery}
                  setFilterSearchQuery={setFilterSearchQuery}
                />
                <StyledDivider dashed />
              </>
            )}
          </Scroll>
          {listNetworkProfiles === undefined && (
            <Actions show={usedFilter}>
              {breakePoint && (
                <ShowResultsButton
                  type="primary"
                  onClick={() => setHiddenFilter(true)}
                >
                  {t("show_results")}
                </ShowResultsButton>
              )}
              <CloseButton onClick={onClearHandle}>
                {t("clear_filters")}
              </CloseButton>
            </Actions>
          )}
        </>
      )}
    </>
  );
};

export default Filter;
