import { Radio, Tooltip } from "antd";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { PartnershipT } from "../../../../types";
import { RadioNetworkT } from "./Filter";
import {
  isCampaignPartnership,
  PartnershipOrCampaignPartnershipT,
} from "./FilterInfluencers";

type mapToPartnershipsT = (
  data: PartnershipOrCampaignPartnershipT[],
) => PartnershipT[];
const mapToPartnerships: mapToPartnershipsT = (data) => {
  return data.map((item) =>
    isCampaignPartnership(item) ? item.partnership : item,
  );
};

type FilterNetworkT = {
  partnerships: PartnershipOrCampaignPartnershipT[];
  setPartnershipIds: Dispatch<SetStateAction<string[]>>;
  network: RadioNetworkT;
  setNetwork: Dispatch<SetStateAction<RadioNetworkT>>;
  onNetworkChangeHandle: (
    partnerships: PartnershipT[] | undefined,
    setPartnershipIds: (values: string[]) => void,
  ) => void;
};

const FilterNetwork: FC<FilterNetworkT> = ({
  partnerships,
  setPartnershipIds,
  network,
  setNetwork,
  onNetworkChangeHandle,
}) => {
  const listPartnerships = mapToPartnerships(partnerships);
  const hasTiktokers =
    listPartnerships.map((p) => p.network).filter((n) => n === "tiktok")
      .length > 0;

  const { t } = useTranslate("brands.campaign.outputs.filter.network");

  useEffect(() => {
    onNetworkChangeHandle(listPartnerships, setPartnershipIds);
  }, [network]);

  return (
    <>
      <Radio.Group
        onChange={(val) => setNetwork(val.target.value)}
        defaultValue={"all"}
      >
        <Radio value={"all"}>{t("all")}</Radio>
        <Radio value={"instagram"}>{t("instagram")}</Radio>
        <Tooltip title={hasTiktokers ? undefined : t("tiktok_tooltip")}>
          <Radio value={"tiktok"} disabled={!hasTiktokers}>
            {t("tiktok")}
          </Radio>
        </Tooltip>
      </Radio.Group>
    </>
  );
};

export default FilterNetwork;
