import styled from "@emotion/styled";
import { Button, Drawer, Tooltip } from "antd";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { ApplicationContext } from "../../../../../context-providers/ApplicationContextProvider";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import { EmptyStatePlaceholder } from "../../../../../ui/icons/PlaceHolders";
import { SubscriptionContext } from "../../../context-providers/SubscriptionsContextProvider";
import { createCampaignPartnership } from "../../../data-access/createCampaignPartnership";
import { getCampaignPartnerships } from "../../../data-access/getCampaignPartnerships";
import { createCampaignPartnershipMutation } from "../../../data-access/mutation/createCampaignPartnershipMutation";
import { updateCurrentUsageMutation } from "../../../data-access/mutation/updateCurrentUsageMutation";
import LimitHeader from "../../../ui/LimitHeader";
import { useCampaign } from "../CampaignDetailContainer";
import AddNewModal from "./AddNewModal";
import Table from "./table/Table";

const EmptyStateWrapper = styled(FlexBox)({
  flexDirection: "column",
  width: "50%",
  padding: rem(50),
  gap: rem(24),
});

const Partnerships: FC = () => {
  const { campaign } = useCampaign();

  const [addNewModal, setAddNewModal] = useState<boolean>(false);
  const [instagramHandles, setInstagramHandles] = useState<string[]>([]);
  const [tiktokHandles, setTiktokHandles] = useState<string[]>([]);

  const { session } = useContext(ApplicationContext);
  const { getFeatureUsage } = useContext(SubscriptionContext);

  const usage = getFeatureUsage(session, "reporting-influencers");
  const overLimit = usage
    ? instagramHandles.length + tiktokHandles.length + usage.used > usage.quota
    : false;

  const { t } = useTranslate("brands.campaign.partnerships");

  const {
    data: partnerships,
    isLoading,
    isRefetching,
  } = useQuery(
    [QueryKeys.PARTNERSHIPS, campaign.id],
    () => campaign && getCampaignPartnerships(campaign.id),
  );

  const queryClient = useQueryClient();
  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    () =>
      campaign
        ? createCampaignPartnership(
            campaign.id,
            instagramHandles.join(","),
            tiktokHandles.join(","),
          )
        : Promise.reject(),
    {
      onSuccess: (data) => {
        campaign &&
          createCampaignPartnershipMutation(
            queryClient,
            campaign.id,
            data,
            () => setAddNewModal(false),
          );
        updateCurrentUsageMutation(
          queryClient,
          "reporting-influencers",
          instagramHandles.length + tiktokHandles.length,
        );
        setInstagramHandles([]);
        setTiktokHandles([]);
      },
    },
  );

  const submitButton = (): JSX.Element => {
    return (
      <Tooltip title={overLimit && t("form.overlimit")}>
        <Button
          type="primary"
          disabled={
            (instagramHandles.length === 0 && tiktokHandles.length === 0) ||
            isCreateLoading ||
            overLimit
          }
          onClick={() => create()}
          loading={isCreateLoading}
        >
          {t("form.submit")}
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      {partnerships && (
        <>
          <LimitHeader
            onClickHandle={setAddNewModal}
            submitTitle={t("form.title")}
            entitlementFeature="reporting-influencers"
            contentPosition="right"
          />
          <Table
            campaign={campaign}
            partnerships={partnerships}
            dataIsLoading={isLoading || isRefetching}
            emptyState={
              <EmptyStateWrapper>
                <EmptyStatePlaceholder />
                <Paragraph paragraph={t("form.empty_title")} fontWeight={600} />
                <AddNewModal
                  instagramHandles={instagramHandles}
                  tiktokHandles={tiktokHandles}
                  setInstagramHandles={setInstagramHandles}
                  setTiktokHandles={setTiktokHandles}
                  campaign={campaign}
                />
                {submitButton()}
              </EmptyStateWrapper>
            }
          />
        </>
      )}

      <Drawer
        open={addNewModal}
        title={t("form.title")}
        onClose={() => setAddNewModal(false)}
        footer={
          <FlexBox justifyContent="flex-end" gap={rem(12)}>
            <Button onClick={() => setAddNewModal(false)}>
              {t("form.cancel")}
            </Button>
            {submitButton()}
          </FlexBox>
        }
      >
        <AddNewModal
          instagramHandles={instagramHandles}
          tiktokHandles={tiktokHandles}
          setInstagramHandles={setInstagramHandles}
          setTiktokHandles={setTiktokHandles}
          campaign={campaign}
        />
      </Drawer>
    </>
  );
};

export default Partnerships;
