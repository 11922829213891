import styled from "@emotion/styled";
import { Input, Radio, Space } from "antd";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import FlexBox from "../../../../../components/FlexBox";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { IconTypeE } from "../../../../../ui/icons/Icon";
import FilterContainer from "../components/FilterContainer";
import RadioItem from "../components/RadionItem";
import { CredibilityEngagementRangeT, SearchQueryT } from "../types";

const StyledInput = styled(Input)({
  width: "100px",
});

export enum EngagementRateTierE {
  average = 1.5,
  good = 3,
  excellent = 5,
}

interface EngagementRateT {
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
}

const EngagementRate: FC<EngagementRateT> = ({
  searchQuery,
  setFilterSearchQuery,
}) => {
  const [engagementRange, setEngagementRange] = useState<
    CredibilityEngagementRangeT | undefined
  >();
  const [credibilityFrom, setCredibilityFrom] = useState<number | undefined>();
  const { t } = useTranslate(
    "brands.influencer_database.filter.engagement_rate",
  );

  const setQuery = (
    range: CredibilityEngagementRangeT,
    from: number | undefined,
  ): void => {
    setFilterSearchQuery({
      engagement_rate_range: range,
      engagement_rate_from: from,
    });
  };

  const handleRadioOnChange = (value: CredibilityEngagementRangeT): void => {
    if (value === "custom" && credibilityFrom === undefined) {
      setEngagementRange(value);
      return;
    }

    setEngagementRange(value);
    setQuery(value, credibilityFrom);
  };

  const handleInputOnChange = (value: number): void => {
    setCredibilityFrom(value);
    setQuery("custom", value);
  };

  useEffect(() => {
    setEngagementRange(searchQuery.engagement_rate_range);
  }, [searchQuery]);

  return (
    <FilterContainer t={t}>
      <Radio.Group
        name="engagementRate"
        value={engagementRange}
        defaultValue={undefined}
        onChange={(e) => handleRadioOnChange(e.target.value)}
      >
        <Space direction="vertical">
          <Radio value={undefined}>{t("none")}</Radio>
          <RadioItem
            value="excellent"
            paragraph={t("excellent")}
            description={t("more", { value: EngagementRateTierE.excellent })}
            icon={IconTypeE.smileVery}
          />
          <RadioItem
            value="good"
            paragraph={t("good")}
            description={t("more", { value: EngagementRateTierE.good })}
            icon={IconTypeE.smileSlightly}
          />
          <RadioItem
            value="average"
            paragraph={t("average")}
            description={t("more", { value: EngagementRateTierE.average })}
            icon={IconTypeE.smileNeutral}
          />
          <FlexBox
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
          >
            <Radio value="custom">{t("custom")}</Radio>
            <StyledInput
              max={100}
              min={0}
              disabled={engagementRange !== "custom"}
              onChange={(e) => handleInputOnChange(e.target.valueAsNumber)}
              addonAfter="%"
              type="number"
              value={credibilityFrom}
            />
          </FlexBox>
        </Space>
      </Radio.Group>
    </FilterContainer>
  );
};

export default EngagementRate;
