import styled from "@emotion/styled";

export const CloseOverlay = styled.div({
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  backgroundColor: "transparent",
  opacity: 0,
  zIndex: 3,
});
