import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../features/Placeholders/PlaceholderLoader";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { theme } from "../../../../../styling/theme";
import { VerticalScrollView } from "../../../../../ui/VerticalScrollView";
import { PartnershipT } from "../../../types";
import InfluencerTile from "../../../ui/InfluencerTile";

type OutputOwnershipT = {
  campaignPartnerships: PartnershipT[] | undefined;
  isLoading: boolean;
  refetch: () => void;
  partnershipId: string;
  setPartnershipId: Dispatch<SetStateAction<string>>;
};

const OutputOwnership: FC<OutputOwnershipT> = ({
  campaignPartnerships,
  isLoading,
  partnershipId,
  setPartnershipId,
}) => {
  const { t } = useTranslate("shared.influencers");

  const getContent = (): JSX.Element | null => {
    if (isLoading) {
      return (
        <PlaceholderLoader
          type={PlaceholdersTypeE.influencers_list}
          count={4}
          direction="column"
        />
      );
    }

    if (campaignPartnerships) {
      if (campaignPartnerships?.length === 0) {
        return (
          <FlexBox fullHeight={true}>
            <Paragraph
              paragraph={t("not_found")}
              color={theme.color.textGreyColor}
            />
          </FlexBox>
        );
      }

      return (
        <>
          {campaignPartnerships.map((campaignPartnership) => {
            return (
              <InfluencerTile
                key={campaignPartnership.id}
                partnership={campaignPartnership}
                active={partnershipId === campaignPartnership.id}
                onClickHandle={() => setPartnershipId(campaignPartnership.id)}
                type="radio"
              />
            );
          })}
        </>
      );
    }

    return null;
  };

  return (
    <>
      <VerticalScrollView
        customWidth={CustomWidthE.full}
        justifyContent="flex-start"
        flexDirection="column"
        maxHeight={180}
        fullHeight={true}
      >
        {getContent()}
      </VerticalScrollView>
    </>
  );
};

export default OutputOwnership;
