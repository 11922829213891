import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Heading from "../../../../../components/Heading";
import { IllustrationWrapper } from "../../../../../components/NoResults";
import Panel from "../../../../../components/Panel";
import Paragraph from "../../../../../components/Paragraph";
import Popover from "../../../../../components/Popover";
import Spacer from "../../../../../components/Spacer";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import {
  getIllustration,
  IllustrationTypeE,
} from "../../../../../ui/Illustrations";
import { Tab } from "../charts-components/engagement/Engagement";
import { CardsMapT, CardsTypeT, SocialNetworkProviderT } from "../types";

const Card = styled(Panel)({
  margin: rem(8),
  width: `calc(33.33% - ${rem(16)})`,

  [theme.media.maxLg]: {
    width: `calc(50% - ${rem(16)})`,
  },

  [theme.media.maxSm]: {
    width: "100%",
    margin: `${rem(8)} 0`,
  },
});

const LabelWrapper = styled(FlexBox)({
  cursor: "default",
});

const CardPart = styled(FlexBox)({
  padding: rem(24),
});

const ChartPart = styled(CardPart)({
  paddingTop: rem(10),
});

const OpacityWrapper = styled(FlexBox)({
  opacity: 0.2,
});

type GraphCardT = {
  cardChartData: CardsMapT;
  card: CardsTypeT;
  provider: SocialNetworkProviderT;
};

const GraphCard: FC<GraphCardT> = ({ cardChartData, card, provider }) => {
  const { t } = useTranslate("brands.influencer_database.detail");
  const { type, element, graphOptions } = cardChartData;
  const {
    alternative_value,
    value,
    top_right_first,
    top_right_second,
    data,
    verified,
  } = card;

  const [currentHeading, setCurrentHeading] = useState<string | null>(value);

  const Graph = element !== "without" ? element : false;

  type getFirstTextT = (text: number | string) => JSX.Element;
  const getFirstText: getFirstTextT = (text) => {
    if (typeof text == "number") {
      return (
        <>
          <Spacer size="small" direction="vertical" />
          <Paragraph
            paragraph={`${text}%`}
            color={text > 0 ? theme.color.successColor : theme.color.errorColor}
            fontWeight={600}
            paragraphSize="big"
          />
          <Spacer size="micro" direction="vertical" />
        </>
      );
    }

    return (
      <>
        <Spacer size="micro" direction="vertical" />
        <Paragraph paragraph={`${text}`} color={theme.color.textGreyColor} />
        <Spacer size="micro" direction="vertical" />
      </>
    );
  };

  type getChartT = (Element: React.FC<any>) => JSX.Element;
  const getChart: getChartT = (Element) => {
    if (data) {
      return (
        <ChartPart
          customWidth={CustomWidthE.full}
          fullHeight={true}
          alignItems="flex-end"
        >
          <Element
            callBackForYTicks={graphOptions.callBackForYTicks}
            callBackForTooltipLabel={graphOptions.callBackForTooltipLabel}
            data={data}
          />
        </ChartPart>
      );
    }

    return (
      <IllustrationWrapper>
        <OpacityWrapper customWidth={CustomWidthE.full} flexDirection="column">
          {getIllustration(IllustrationTypeE.missing_chart)}
          <Spacer direction="vertical" />
          <Paragraph paragraph={t("card.others.no_data")} />
        </OpacityWrapper>
      </IllustrationWrapper>
    );
  };

  return (
    //TODO: [FE/TODO] - Unify global Card component
    <Card key={type} flexDirection="column" justifyContent="space-between">
      <CardPart
        justifyContent="space-between"
        alignItems="stretch"
        customWidth={CustomWidthE.full}
      >
        <FlexBox
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {currentHeading && (
            <Heading heading={`${currentHeading}`} spaceBottom="micro" />
          )}
          <LabelWrapper alignItems="flex-start">
            <Paragraph
              paragraph={t(`metrics.${provider}.${type}.title`)}
              color={theme.color.textGreyColor}
            />
            {alternative_value && (
              <>
                {":"}
                <Spacer size="micro" />
                <Tab
                  onClick={() => setCurrentHeading(value)}
                  active={currentHeading === value}
                >
                  {"Post"}
                </Tab>
                <Spacer size="micro" />
                {"/"}
                <Spacer size="micro" />
                <Tab
                  onClick={() => setCurrentHeading(alternative_value)}
                  active={currentHeading === alternative_value}
                >
                  {"Stories"}
                </Tab>
              </>
            )}
            <Spacer size="micro" />
            <Popover
              renderTrigger={() => (
                <Icon
                  icon={IconTypeE.infoCircle}
                  color={theme.color.textGreyColor}
                  size="small"
                />
              )}
              content={t(`metrics.${provider}.${type}.description`)}
              width={250}
            />
          </LabelWrapper>
        </FlexBox>
        <FlexBox
          flexDirection="column"
          justifyContent={"flex-start"}
          alignItems="flex-end"
        >
          {top_right_first && getFirstText(top_right_first)}
          {top_right_second && (
            <Paragraph
              paragraph={`${top_right_second}`}
              color={theme.color.textGreyColor}
            />
          )}
        </FlexBox>
      </CardPart>
      {Graph && getChart(Graph as React.FC<any>)}
      {/* <CardFooter dataOrigin={verified} provider={provider} /> */}
    </Card>
  );
};

export default GraphCard;
