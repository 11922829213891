import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ApprovalRequestWithAuthorT } from "../../types";

export const getApprovalRequestData = async (
  approvalRequestId: string,
): Promise<ApprovalRequestWithAuthorT> =>
  axios
    .get(getApiUrl(ApiRoutesE.APPROVAL_REQUESTS_INFLUENCER, approvalRequestId))
    .then((res) => res.data.approval_request);
