import { Form, Modal } from "antd";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Container from "../../../../components/Container";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import Select from "../../../../features/Select";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { createUsers } from "../../data-access/createUsers";
import { getUsers } from "../../data-access/getUsers";
import { createUsersMutation } from "../../data-access/mutation/createUsersMutation";
import LimitHeader from "../../ui/LimitHeader";
import Table from "./Table";

type InvalidEntryT = {
  email: string;
  reason: string;
};

const UsersContainer: FC = () => {
  const [addNewModal, setAddNewModal] = useState<boolean>(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [invalidEmails, setInvalidEmails] = useState<InvalidEntryT[]>([]);

  const [form] = Form.useForm();
  const { t } = useTranslate("brands.users.index");
  const { session } = useContext(ApplicationContext);

  const queryClient = useQueryClient();
  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    () =>
      createUsers({
        users: emails.map((email) => ({ email })),
      }),
    {
      onSuccess: (data) => {
        createUsersMutation(queryClient, data.users, clearStates);
      },
    },
  );

  const {
    data: users,
    isLoading,
    isRefetching,
  } = useQuery(QueryKeys.USERS, () => getUsers());

  const brandAdmin = users?.filter((user) =>
    user.roles.includes("brand_admin"),
  )[0];

  const clearStates = (): void => {
    setAddNewModal(false);
    setEmails([]);
    form.resetFields();
  };

  const checkExistingEmails = (_: any): Promise<void> => {
    if (invalidEmails.length === 0) {
      return Promise.resolve();
    }

    const existingEmails = invalidEmails
      .filter((entry) => entry.reason === "existing")
      .map((entry) => entry.email);

    if (existingEmails.length === 0) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error(
        `${t("invitation_modal.existing_emails")}: ${existingEmails.join(
          ", ",
        )}.`,
      ),
    );
  };

  const checkEmails = (_: any): Promise<void> => {
    if (invalidEmails.length === 0) {
      return Promise.resolve();
    }

    const invalidFormattedEmails = invalidEmails
      .filter((entry) => entry.reason === "invalid")
      .map((entry) => entry.email);

    if (invalidFormattedEmails.length === 0) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error(
        `${t("invitation_modal.invalid_emails")}: ${invalidFormattedEmails.join(
          ", ",
        )}.`,
      ),
    );
  };

  const handleEmailsChanged = (emails: string[]): void => {
    const invalidFormattedEmails = emails.filter(
      (email) => !/^\S+@\S+\.\S+$/.test(email),
    );
    const existingEmails = emails.filter(
      (email) => users?.some((user) => user.email === email),
    );

    setInvalidEmails([
      ...invalidFormattedEmails.map((email) => ({ email, reason: "invalid" })),
      ...existingEmails.map((email) => ({ email, reason: "existing" })),
    ]);
    setEmails(emails);
  };

  return (
    <Container>
      <LimitHeader
        title={t("header")}
        entitlementFeature="users"
        onClickHandle={setAddNewModal}
        submitTitle={t("invite_users")}
        disableCreate={!session?.roles.includes("brand_admin")}
        disableCreateMessage={t("invite_users_not_admin", {
          name: brandAdmin?.name,
          email: brandAdmin?.email,
        })}
      />
      <Table dataIsLoading={isLoading || isRefetching} users={users} />

      <Modal
        open={addNewModal}
        onCancel={() => setAddNewModal(false)}
        onOk={() => create()}
        title={t("invitation_modal.header")}
        okText={t("invitation_modal.invite")}
        cancelText={t("invitation_modal.cancel")}
        okButtonProps={{
          disabled:
            emails.length === 0 || invalidEmails.length > 0 || isCreateLoading,
        }}
      >
        <Paragraph paragraph={t("invitation_modal.emails")} fontWeight={600} />
        <Spacer direction="vertical" />
        <Form form={form}>
          <Form.Item
            name="emails"
            rules={[
              { validator: checkEmails },
              { validator: checkExistingEmails },
            ]}
          >
            <Select
              mode="tags"
              placeholder={t("invitation_modal.emails_placeholder")}
              onChange={handleEmailsChanged}
              tokenSeparators={[","]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
        <Spacer direction="vertical" />
      </Modal>
    </Container>
  );
};

export default UsersContainer;
