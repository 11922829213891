import { QueryClient } from "react-query";
import { MediaGroupT, MediaT, QueryStackT } from "../../types";

type moveMediaToMediaGroupMutationT = (
  queryClient: QueryClient,
  queryStack: QueryStackT,
  mediaToMove: MediaT,
  medaGroupToReceive: MediaGroupT,
  afterMutationHandle: () => void,
) => void;

export const moveMediaToMediaGroupMutation: moveMediaToMediaGroupMutationT = (
  queryClient,
  queryStack,
  mediaToMove,
  medaGroupToReceive,
  afterMutationHandle,
) => {
  queryClient.setQueryData<MediaGroupT[] | undefined>(
    queryStack,
    (prevState) => {
      if (prevState) {
        const currentMediaGroupIndex = prevState.findIndex(
          (mediaGroup) => mediaGroup.id === mediaToMove.media_group_id,
        );
        const receiveMediaGroupIndex = prevState.findIndex(
          (mediaGroup) => mediaGroup.id === medaGroupToReceive.id,
        );

        if (currentMediaGroupIndex > -1 && receiveMediaGroupIndex > -1) {
          const currentMediaGroup = prevState[currentMediaGroupIndex];

          const newCurrentMediaGroup = {
            ...currentMediaGroup,
            medias: [
              ...currentMediaGroup.medias.filter(
                (media) => media.id !== mediaToMove.id,
              ),
            ],
          };

          const newState = prevState;
          newState[currentMediaGroupIndex] = newCurrentMediaGroup;

          const newMedaGroupToReceive = {
            ...medaGroupToReceive,
            medias: [...medaGroupToReceive.medias, mediaToMove],
          };
          newState[receiveMediaGroupIndex] = newMedaGroupToReceive;

          return newState;
        }
      }

      return prevState;
    },
  );

  afterMutationHandle();
};
