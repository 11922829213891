import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { CompetitiveAnalysisT } from "../../types";
import { updateCurrentUsageMutation } from "./updateCurrentUsageMutation";

export const createCompetitiveAnalysisMutation = (
  queryClient: QueryClient,
  data: CompetitiveAnalysisT,
  afterMutationHandle?: () => void,
): void => {
  queryClient.setQueryData<CompetitiveAnalysisT[]>(
    QueryKeys.COMPETITIVE_ANALYSES,
    (prevState) => {
      if (prevState) {
        return [data, ...prevState];
      }

      return [data];
    },
  );

  updateCurrentUsageMutation(queryClient, "competitive-analyses");

  afterMutationHandle && afterMutationHandle();
};
