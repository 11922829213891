import * as React from "react";
import { FC } from "react";
import { Line } from "react-chartjs-2";
import { getDateTime } from "../../../../../helpers/date";
import { capitalizeFirstLetter } from "../../../../../helpers/formaters";
import { theme } from "../../../../../styling/theme";
import { StandardChartDataT } from "../types";

export type LineChartT = {
  data: StandardChartDataT;
  callBackForYTicks?: (value: any, index: any) => string;
  callBackForTooltipLabel?: any;
  color?: string;
};

const LineChart: FC<LineChartT> = ({
  data,
  color = "#2F80ED",
  callBackForYTicks = (val: any) => val,
  callBackForTooltipLabel = (context: any) => context.formattedValue,
}) => {
  const labels = data
    .map((s) => Object.keys(s))
    .reduce((prev, curr) => prev.concat(curr));

  const values = data
    .map((s) => Object.values(s))
    .reduce((prev, curr) => prev.concat(curr));

  const chartData = {
    labels: labels.map((l) =>
      capitalizeFirstLetter(getDateTime(l, "month").substring(0, 3)),
    ),
    datasets: [
      {
        data: values.map((v) => v),
        borderColor: color,
        pointBackgroundColor: color,
        pointBorderColor: color,
        pointBorderWidth: 1,
        pointHitRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        left: 10,
        right: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: callBackForTooltipLabel,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: { weight: "500", size: 14 },
          color: theme.color.textLightGreyColor,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: callBackForYTicks,
          font: { weight: "500", size: 14 },
          color: theme.color.textLightGreyColor,
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;
