import { InstagramOutlined, YoutubeOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Radio, Space } from "antd";
import * as React from "react";
import { FC } from "react";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import { TikTokIcon } from "../../../../../ui/icons/CustomIcons";
import FilterContainer from "../components/FilterContainer";
import { ProviderT, SearchQueryT } from "../types";

const Label = styled.span({
  paddingLeft: rem(8),
});

type NetworksT = {
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
  afterChangeHandle: () => void;
};

const Networks: FC<NetworksT> = ({
  searchQuery,
  setFilterSearchQuery,
  afterChangeHandle,
}) => {
  const { t } = useTranslate("brands.influencer_database.filter.networks");

  const onClearHandle = (value: ProviderT): void => {
    setFilterSearchQuery({ provider_eq: value });
    afterChangeHandle();
  };

  return (
    <FilterContainer t={t} tooltip={false}>
      <Radio.Group
        name="provider"
        value={searchQuery.provider_eq}
        onChange={(e) => onClearHandle(e.target.value)}
      >
        <Space direction="vertical">
          <Radio value="instagram">
            <InstagramOutlined rev="" />
            <Label>Instagram</Label>
          </Radio>
          <Radio value="tiktok">
            <TikTokIcon />
            <Label>TikTok</Label>
          </Radio>
          <Radio value="youtube">
            <YoutubeOutlined rev="" />
            <Label>YouTube</Label>
          </Radio>
        </Space>
      </Radio.Group>
    </FilterContainer>
  );
};

export default Networks;
