import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { ContactT } from "../../features/influencer-database-detail/types";

export const removeContactNoteMutation = (
  queryClient: QueryClient,
  id: string,
): void => {
  queryClient.setQueryData<ContactT | undefined>(
    QueryKeys.NETWORK_PROFILES_CONTACT,
    (prevState) => {
      if (prevState) {
        const newState = {
          ...prevState,
          notes: prevState.notes.filter((item) => item.id !== id),
        };
        return newState;
      }

      return prevState;
    },
  );
};
