import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";
import Button from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../components/Heading";
import Spacer from "../../../../components/Spacer";
import Input from "../../../../features/Input";
import { getUrlWithQuery } from "../../../../helpers/getUrlWithQuery";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { useTranslate } from "../../../../hooks/useTranslate";
import FormNote from "../../../../ui/FormNote";
import { updateInfluencerEmail } from "../../data-access/updateInfluencerEmail";
import { ApprovalPageStateT } from "../../types";

type UpdateEmailModalT = {
  initialEmail: string;
  approvalRequestId: string;
  setApprovalPageState: Dispatch<SetStateAction<ApprovalPageStateT>>;
};

const UpdateEmailModal: FC<UpdateEmailModalT> = ({
  approvalRequestId,
  initialEmail,
  setApprovalPageState,
}) => {
  const [email, setEmail] = useState<string>(initialEmail);

  const { t } = useTranslate("influencer_root.approval_request.email");
  const { refetch: patchInfluencerEmail, isLoading } = useQuery(
    QueryKeys.EMAIL_ADDRESS,
    () =>
      updateInfluencerEmail(
        getUrlWithQuery(
          getApiUrl(ApiRoutesE.APPROVAL_REQUESTS_AUTHORIZATION_EMAIL),
          {
            approval_request_id: approvalRequestId,
          },
        ),
        { user: { email } },
      ),
    {
      enabled: false,
      retry: false,
      onSuccess: () => setApprovalPageState("email_updated"),
    },
  );

  useEffect(() => {
    setEmail(initialEmail);
  }, [initialEmail]);

  useEffect(() => {
    setApprovalPageState("loading");
  }, [isLoading]);

  const onClickHandle = (): void => {
    if (email.length > 0) {
      patchInfluencerEmail();
    }
  };

  return (
    <FlexBox
      flexDirection="column"
      alignItems="stretch"
      customWidth={CustomWidthE.full}
    >
      <Heading
        heading={t("title")}
        headingType={HeadingTypeT.h2}
        spaceBottom="big"
      />
      <Input value={email} setValue={setEmail} label={t("label")} />

      <FormNote text={t("note")} />
      <Spacer size="big" direction="vertical" />
      <Spacer size="big" direction="vertical" />
      <Button onClick={onClickHandle} customWidth={CustomWidthE.full}>
        {t("confirm")}
      </Button>
    </FlexBox>
  );
};

export default UpdateEmailModal;
