import axios from "axios";
import { SessionT } from "../../types";

type updateInfluencerEmailT = (
  url: string,
  payload: {
    user: {
      email: string;
    };
  },
) => Promise<SessionT>;

export const updateInfluencerEmail: updateInfluencerEmailT = async (
  url,
  payload,
) =>
  axios.patch(url, payload, { withCredentials: true }).then((res) => res.data);
