import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Popconfirm } from "antd";
import TextArea, { TextAreaRef } from "antd/es/input/TextArea";
import * as React from "react";
import { FC, useContext, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import FlexBox from "../../../../../../components/FlexBox";
import Paragraph from "../../../../../../components/Paragraph";
import { ApplicationContext } from "../../../../../../context-providers/ApplicationContextProvider";
import { getDateTime } from "../../../../../../helpers/date";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../../styling/theme";
import { OpenToastT } from "../../../../context-providers/ToastContextProvider";
import { removeContactNoteMutation } from "../../../../data-access/mutation/removeContactNoteMutation";
import { updateContactNoteMutation } from "../../../../data-access/mutation/updateContactNoteMutation";
import { removeContactNote } from "../../../../data-access/removeContactNote";
import { updateContactNote } from "../../../../data-access/updateContactNote";
import { ContactNoteT } from "../../types";

const ActionsWrapper = styled(FlexBox)({});

const Wrapper = styled(FlexBox)({
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingBottom: rem(12),
});

const Header = styled(FlexBox)({
  width: "100%",
  justifyContent: "space-between",
});

const StyledTextArea = styled(TextArea)({
  padding: 0,
});

type NoteT = {
  note: ContactNoteT;
  networkProfileId: string;
  openToast: OpenToastT;
};

const Note: FC<NoteT> = ({ note, networkProfileId, openToast }) => {
  const [editId, setEditId] = useState<string>();
  const [areaText, setAreaText] = useState<string>(note.text);

  const ref = useRef<TextAreaRef>(null);

  const { session } = useContext(ApplicationContext);

  const { t } = useTranslate("brands.influencer_database.crm.notes");

  const queryClient = useQueryClient();

  const { mutate: update } = useMutation(
    (params: { id: string; text: string }) =>
      updateContactNote(params.id, networkProfileId, params.text),
    {
      onSuccess: (data) => {
        updateContactNoteMutation(queryClient, data);
        openToast({
          type: "success",
          message: t("messages.update_success"),
        });
      },
    },
  );

  const { mutate: destroy } = useMutation(
    (id: string) => removeContactNote(id, networkProfileId),
    {
      onSuccess: (_, id) => {
        removeContactNoteMutation(queryClient, id);
        openToast({
          type: "success",
          message: t("messages.remove_success"),
        });
      },
    },
  );

  const onEditClickHandle = (noteId: string): void => {
    setEditId(noteId);
    ref.current?.focus();
  };

  const onBlurHandle = (): void => {
    setEditId(undefined);
    if (areaText.length > 0) {
      update({ id: note.id, text: areaText });
    } else {
      setAreaText(note.text);
    }
  };

  return (
    <Wrapper key={note.id}>
      <Header>
        <FlexBox gap={rem(8)}>
          <Paragraph
            paragraph={note.user.nick_or_name}
            paragraphSize="small"
            fontWeight={600}
          />
          <Paragraph
            paragraph={getDateTime(note.created_at, "shortDate")}
            color={theme.color.textLightGreyColor}
            paragraphSize="micro"
          />
        </FlexBox>
        {note.user.id === session?.id && (
          <ActionsWrapper>
            <Button
              onClick={() => onEditClickHandle(note.id)}
              type="text"
              icon={
                <EditOutlined style={{ color: theme.color.iconGreyColor }} />
              }
            />
            <Popconfirm
              title={t("delete.title")}
              description={t("delete.description")}
              onConfirm={() => destroy(note.id)}
              okText={t("delete.ok")}
              cancelText={t("delete.cancel")}
            >
              <Button
                type="text"
                icon={
                  <DeleteOutlined
                    style={{ color: theme.color.iconGreyColor }}
                  />
                }
              />
            </Popconfirm>
          </ActionsWrapper>
        )}
      </Header>
      <StyledTextArea
        autoSize
        ref={ref}
        disabled={note.id !== editId}
        value={areaText}
        onChange={(e) => setAreaText(e.target.value)}
        onBlur={onBlurHandle}
        onFocus={(e) =>
          e.target.setSelectionRange(
            e.target.value.length,
            e.target.value.length,
          )
        }
        style={
          editId === note.id
            ? {}
            : {
                border: "1px solid transparent",
                color: theme.color.textGreyDarkColor,
                backgroundColor: "transparent",
                cursor: "default",
              }
        }
      />
    </Wrapper>
  );
};

export default Note;
