import {
  CalendarOutlined,
  DownOutlined,
  LeftOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Dropdown } from "antd";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useContext } from "react";
import { useQuery } from "react-query";
import ArrayTags from "../../../../components/ArrayTags";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { getDateTime } from "../../../../helpers/date";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { useTranslate } from "../../../../hooks/useTranslate";
import { hexToRgb, rem } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { ToastContext } from "../../context-providers/ToastContextProvider";
import { getCampaignPPTX } from "../../data-access/getCampaignPPTX";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { CampaignT } from "../../types";

const Wrapper = styled(FlexBox)({
  justifyContent: "space-between",
  width: "100%",
  backgroundColor: designToken.colorWhite,
  paddingRight: rem(16),
  borderRadius: designToken.borderRadiusLG,
});

const HeaderContent = styled(FlexBox)({
  padding: `${rem(12)} ${rem(12)} ${rem(12)} ${rem(8)}`,
});

const HeaderTitle = styled(FlexBox)({
  fontWeight: 600,
  gap: rem(8),
});

const LeftContent = styled(FlexBox)({
  padding: rem(4),
  alignSelf: "stretch",
});

const BackButton = styled(Button)({
  height: "100%",
});

const Dot = styled(FlexBox)({
  width: rem(6),
  height: rem(6),
  borderRadius: "50%",
  backgroundColor: designToken.colorBorderSecondary,
});

const Highlight = styled(FlexBox)({
  backgroundColor: hexToRgb(designToken.colorPrimaryBg, 0.5),
  padding: `${rem(4)} ${rem(8)}`,
  gap: rem(8),
  borderRadius: rem(99),
});

const PowerPointWrapper = styled(FlexBox)({
  justifyContent: "flex-start",
  gap: rem(4),
  cursor: "pointer",
});

type HeaderT = {
  campaign: CampaignT;
  setEditModal: Dispatch<SetStateAction<boolean>>;
};

const Header: FC<HeaderT> = ({ campaign, setEditModal }) => {
  const basePath = getApiUrl(ApiRoutesE.CAMPAIGN_EXPORT, campaign.id);

  const navigate = useRouterNavigate();

  const { openToast } = useContext(ToastContext);
  const { t } = useTranslate("brands.campaign");

  const { refetch } = useQuery(
    [QueryKeys.PPTXJSON, campaign.id],
    () => getCampaignPPTX(campaign.id),
    {
      enabled: false,
      onSuccess: () => {
        openToast({ type: "success", message: t("export.requested") });
      },
    },
  );

  const exportItems = [
    {
      key: "pptx",
      onClick: () => refetch(),
      label: (
        <PowerPointWrapper>
          <Icon icon={IconTypeE.pptx} color={"#D24826"} />
          PowerPoint
        </PowerPointWrapper>
      ),
    },
    {
      key: "xlsx",
      label: (
        <a href={`${basePath}.xlsx`}>
          <FlexBox justifyContent="flex-start" gap={rem(4)}>
            <Icon icon={IconTypeE.xlsx} color={"#1E7145"} />
            Excel
          </FlexBox>
        </a>
      ),
    },
    {
      key: "csv",
      label: (
        <a href={`${basePath}.csv`}>
          <FlexBox justifyContent="flex-start" gap={rem(4)}>
            <Icon icon={IconTypeE.csv} color={"#1E7145"} />
            csv
          </FlexBox>
        </a>
      ),
    },
  ];

  return (
    <Wrapper>
      <LeftContent>
        <BackButton
          type="text"
          icon={<LeftOutlined />}
          onClick={() => navigate(NavigationKeyE.Campaigns)}
        />
        <HeaderContent>
          <FlexBox flexDirection="column" alignItems="flex-start" gap={rem(8)}>
            <HeaderTitle>
              {campaign.client.name}
              <Dot />
              {campaign.title}
            </HeaderTitle>
            <FlexBox gap={rem(8)}>
              {campaign.instagram_mentions.length > 0 && (
                <Highlight>
                  <Icon icon={IconTypeE.socInstagram} />
                  <ArrayTags array={campaign.instagram_mentions} prefix="" />
                </Highlight>
              )}
              {campaign.tiktok_mentions.length > 0 && (
                <Highlight>
                  <Icon icon={IconTypeE.socTiktok} />
                  <ArrayTags array={campaign.tiktok_mentions} prefix="" />
                </Highlight>
              )}
              {campaign.hashtags.length > 0 && (
                <Highlight>
                  <Paragraph paragraph="#" color={designToken.colorPrimary} />
                  <ArrayTags array={campaign.hashtags} prefix="" />
                </Highlight>
              )}
              <Highlight>
                <CalendarOutlined style={{ color: designToken.colorPrimary }} />
                {[
                  getDateTime(campaign.from, "shortDate"),
                  getDateTime(campaign.to, "shortDate"),
                ].join(" - ")}
              </Highlight>
            </FlexBox>
          </FlexBox>
        </HeaderContent>
      </LeftContent>
      <FlexBox gap={rem(12)}>
        <Dropdown menu={{ items: exportItems }}>
          <Button>
            {t("export.title")}
            <DownOutlined />
          </Button>
        </Dropdown>
        <Button icon={<SettingOutlined />} onClick={() => setEditModal(true)} />
      </FlexBox>
    </Wrapper>
  );
};

export default Header;
