import { DatePicker } from "antd";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import Select from "../../../../../features/Select";
import {
  getDateForRangeDayFromToday,
  getSystemDate,
} from "../../../../../helpers/date";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import FilterContainer from "../components/FilterContainer";
import { SearchQueryT } from "../types";

const MyDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);

interface MentionsHashtagsT {
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
}

const MentionsHashtags: FC<MentionsHashtagsT> = ({
  searchQuery,
  setFilterSearchQuery,
}) => {
  const { t } = useTranslate(
    "brands.influencer_database.filter.mentions_hashtags",
  );

  const disabledDate = (current: Date): boolean => {
    return current && current < getDateForRangeDayFromToday(-365);
  };

  return (
    <FilterContainer t={t}>
      <FlexBox
        gap={rem(16)}
        customWidth={CustomWidthE.full}
        flexDirection="column"
      >
        <Select
          allowClear
          value={searchQuery.mentions}
          mode="tags"
          maxTagCount="responsive"
          notFoundContent={null}
          style={{ width: "100%" }}
          placeholder="nike, adidas, ..."
          onChange={(values) => setFilterSearchQuery({ mentions: values })}
          options={[]}
          addonBefore="@"
          tokenSeparators={[","]}
        />
        <Select
          allowClear
          value={searchQuery.hashtags}
          mode="tags"
          maxTagCount="responsive"
          notFoundContent={null}
          style={{ width: "100%" }}
          placeholder="summer2023, shoes, ..."
          onChange={(values) => setFilterSearchQuery({ hashtags: values })}
          options={[]}
          addonBefore="#"
          tokenSeparators={[","]}
        />
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          customWidth={CustomWidthE.full}
          gap={rem(4)}
        >
          <Paragraph paragraph={t("outputs_from")} paragraphSize="micro" />
          <MyDatePicker
            allowClear={false}
            picker="month"
            value={new Date(searchQuery.outputs_from)}
            placeholder={t("date_picker_placeholder")}
            onChange={(value) =>
              value &&
              setFilterSearchQuery({ outputs_from: getSystemDate(value) })
            }
            style={{ width: "100%" }}
            disabledDate={disabledDate}
            disabled={
              searchQuery.mentions.length === 0 &&
              searchQuery.hashtags.length === 0
            }
          />
        </FlexBox>
      </FlexBox>
    </FilterContainer>
  );
};

export default MentionsHashtags;
