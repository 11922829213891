import styled from "@emotion/styled";
import FlexBox from "../../components/FlexBox";
import VisibleWrapper from "../../components/VisibleWrapper";
import { rem } from "../../styling/theme";

export const ActionWrapper = styled(VisibleWrapper)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  height: "auto",
  padding: `0 ${rem(16)} ${rem(16)} ${rem(16)}`,
  zIndex: 1,
});

export const Content = styled(FlexBox)<{ padding?: string }>(
  ({ padding = `${rem(16)} ${rem(16)} 0 ${rem(16)}` }) => ({
    flexGrow: 1,
    padding,
  }),
);

export const TopBar = styled(FlexBox)({
  padding: `${rem(8)} ${rem(12)}`,
});
