import { QueryClient } from "react-query";
import { SessionT } from "../../entry-files/types";
import { QueryKeys } from "../../helpers/queryKeys";

export const updateSessionMutation = (
  queryClient: QueryClient,
  data: SessionT,
  afterMutationHandle?: () => void,
): void => {
  queryClient.setQueryData<SessionT>(QueryKeys.SESSION, () => {
    return data;
  });

  afterMutationHandle && afterMutationHandle();
};
