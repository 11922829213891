type isStringEmptyT = (string: string) => boolean;
export const isStringEmpty: isStringEmptyT = (string) => {
  return string === "";
};

type _oT = (obj: HTMLCollectionOf<Element>) => Array<Element>;
export const _o: _oT = (obj) => Object.values(obj);

type globalClassStateT = "overflow-hidden" | "modal-open";
type getGlobalClassStateT = (type: globalClassStateT) => boolean;
export const getGlobalClassState: getGlobalClassStateT = (type) => {
  const appElement = document.getElementById("app");
  return appElement.classList.contains(type);
};

type setGlobalClassStateT = (type: globalClassStateT, state: boolean) => void;
export const setGlobalClassState: setGlobalClassStateT = (type, state) => {
  const appElement = document.getElementById("app");

  if (appElement) {
    const classExist = appElement.classList.contains(type);

    if (state) {
      if (!classExist) {
        appElement.classList.add(type);
        return;
      }

      console.warn(`/${type}/ is already set!`);
      return;
    }

    if (!state) {
      if (classExist) {
        appElement.classList.remove(type);
      }

      return;
    }

    console.warn(`/${type}/ is not set!`);
    return;
  }
};

type setScrollT = (byModal?: boolean) => void;
export const disableScroll: setScrollT = (byModal) => {
  if (byModal) {
    setGlobalClassState("modal-open", true);
  }

  setGlobalClassState("overflow-hidden", true);
};

export const allowScroll: setScrollT = (byModal) => {
  if (byModal) {
    setGlobalClassState("modal-open", false);
  }

  setGlobalClassState("overflow-hidden", false);
};

type serializeObjectT = {
  name: string;
  value: string;
};
type serializeArrayT = (form: HTMLFormElement) => Array<serializeObjectT>;
export const serializeArray: serializeArrayT = (form) => {
  const finalArray = [];

  Array.prototype.slice.call(form.elements).forEach((field) => {
    if (
      !field.name ||
      field.disabled ||
      ["file", "reset", "submit", "button"].indexOf(field.type) > -1
    )
      return;

    if (field.type === "select-multiple") {
      Array.prototype.slice.call(field.options).forEach((option) => {
        if (!option.selected) return;
        finalArray.push({
          name: field.name,
          value: option.value,
        });
      });

      return;
    }

    if (["checkbox", "radio"].indexOf(field.type) > -1 && !field.checked) {
      return;
    }

    finalArray.push({
      name: field.name,
      value: field.value,
    });
  });

  return finalArray;
};

type getParamsT = (array: Array<serializeObjectT>) => string;
export const getParams: getParamsT = (array) => {
  const paramsArray = [];

  array.map((object) => {
    paramsArray.push(
      Object.keys(object)
        .map((k) => {
          return encodeURIComponent(object[k]);
        })
        .join("=")
    );
  });

  return paramsArray.join("&");
};

type debounceFunctionT = () => void;
type debounceT = (func: debounceFunctionT, wait: number) => void;
export const debounce: debounceT = (func, wait) => {
  let timeout;

  return function () {
    const context = this,
      args = arguments;
    const later = (): void => {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

type addToInnerHTMLT = (
  element: HTMLElement,
  content: string,
  onTheEnd: boolean
) => void;
export const addToInnerHTML: addToInnerHTMLT = (
  element,
  content,
  onTheEnd = true
) => {
  if (onTheEnd) {
    element.innerHTML = element.innerHTML + content;

    return;
  }

  element.innerHTML = content + element.innerHTML;
};

type putSpacesToThousandT = (num: number) => string | number;
export const putSpacesToThousand: putSpacesToThousandT = (num) => {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return num;
};

type numberToTextT = (num: number) => string;
export const numberToText: numberToTextT = (num) => {
  const stringNum = num.toString();
  const length = stringNum.length;

  if (length > 6 && num % 1000000 == 0) {
    const num = parseInt(stringNum.slice(0, length - 6));

    return putSpacesToThousand(num) + "M";
  }

  if (length > 3 && num % 1000 == 0) {
    const num = parseInt(stringNum.slice(0, length - 3));

    return putSpacesToThousand(num) + "k";
  }

  return `${num}`;
};
