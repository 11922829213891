import { QueryClient } from "react-query";
import uniqid from "uniqid";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { AddedMediaGroupsStateT, MediaGroupT } from "../../types";
import { getEmptyMediaGroup } from "../createMediaGroup";

type createMediaGroupMutationT = (
  queryClient: QueryClient,
  data: MediaGroupT,
) => void;

export const createMediaGroupMutation: createMediaGroupMutationT = (
  queryClient,
  data,
) => {
  queryClient.setQueryData<AddedMediaGroupsStateT | undefined>(
    QueryKeys.CAMPAIGN_MEDIA_GROUPS_ADDED,
    (prevState) => {
      if (prevState) {
        const { id, kind, medias, partnership, network } = data;

        const newMediaGroup = getEmptyMediaGroup(uniqid());
        const newState = [
          newMediaGroup,
          {
            id,
            temporary_id: prevState[0].temporary_id,
            kind,
            medias,
            partnership,
            network,
          },
        ];

        return prevState.length > 1
          ? [...newState, ...prevState.slice(1)]
          : newState;
      }

      return prevState;
    },
  );
};
