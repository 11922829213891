import { Radio, Space } from "antd";
import * as React from "react";
import { FC } from "react";
import { useTranslate } from "../../../../../hooks/useTranslate";
import FilterContainer from "../components/FilterContainer";
import { GenderTypeT, SearchQueryT } from "../types";

interface InfluencerGenderT {
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
}

const InfluencerGender: FC<InfluencerGenderT> = ({
  searchQuery,
  setFilterSearchQuery,
}) => {
  const { t } = useTranslate(
    "brands.influencer_database.filter.influencer_gender",
  );

  const handleOnChange = (value: GenderTypeT | undefined): void => {
    setFilterSearchQuery({
      influencer_gender: value,
    });
  };

  return (
    <FilterContainer t={t} tooltip={false}>
      <Radio.Group
        name="influencerGender"
        value={searchQuery.influencer_gender}
        onChange={(e) => handleOnChange(e.target.value)}
      >
        <Space direction="vertical">
          <Radio value={undefined}>{t("none")}</Radio>
          <Radio value="female">{t("female")}</Radio>
          <Radio value="male">{t("male")}</Radio>
        </Space>
      </Radio.Group>
    </FilterContainer>
  );
};

export default InfluencerGender;
