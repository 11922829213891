import { QueryClient } from "react-query";
import { MediaGroupT, QueryStackT } from "../../types";

type moveMediaToMediaGroupMutationT = (
  queryClient: QueryClient,
  queryStack: QueryStackT,
  mediaGroupToMerge: MediaGroupT,
  medaGroupToReceive: MediaGroupT,
  afterMutationHandle: () => void,
) => void;

export const mergeMediaToMediaGroupMutation: moveMediaToMediaGroupMutationT = (
  queryClient,
  queryStack,
  mediaGroupToMerge,
  medaGroupToReceive,
  afterMutationHandle,
) => {
  queryClient.setQueryData<MediaGroupT[] | undefined>(
    queryStack,
    (prevState) => {
      if (prevState) {
        const receiveMediaGroupIndex = prevState.findIndex(
          (mediaGroup) => mediaGroup.id === medaGroupToReceive.id,
        );

        if (receiveMediaGroupIndex > -1) {
          const newMedaGroupToReceive = {
            ...medaGroupToReceive,
            medias: medaGroupToReceive.medias.concat(mediaGroupToMerge.medias),
          };
          prevState[receiveMediaGroupIndex] = newMedaGroupToReceive;

          const newState = prevState.filter(
            (mediaGroup) => mediaGroup.id !== mediaGroupToMerge.id,
          );

          return newState;
        }
      }

      return prevState;
    },
  );

  afterMutationHandle();
};
