import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useQuery } from "react-query";
import Button from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../../components/Heading";
// import { getPendingModalContent } from "../../../../../components/Modal";
import { IllustrationWrapper } from "../../../../../components/NoResults";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import Input from "../../../../../features/Input";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import {
  getIllustration,
  IllustrationTypeE,
} from "../../../../../ui/Illustrations";
import { createIgHandle } from "../../../data-access/createIgHandle";
import { NewBrandHandleResponseT } from "./AddBrandHandle";

const StyledInput = styled(Input)({
  width: "100%",
  borderRadius: rem(3),
});

const ResponseWrapper = styled(FlexBox)({
  padding: rem(20),
});

type BrandHandleModalT = {
  status: NewBrandHandleResponseT;
  setStatus: Dispatch<SetStateAction<NewBrandHandleResponseT>>;
  closeModal: () => void;
};

const BrandHandleModal: FC<BrandHandleModalT> = ({
  status,
  setStatus,
  closeModal,
}) => {
  const [brandHandle, setBrandHandle] = useState<string>("");

  const { t } = useTranslate(
    "brands.influencer_database.detail.brand_affinity.add",
  );

  const { isLoading, isRefetching, refetch } = useQuery(
    QueryKeys.IG_HANDLES,
    () => createIgHandle(brandHandle),
    {
      enabled: false,
      onSuccess: (data) => {
        const { success } = data;

        setStatus(success ? "done" : "isTracking");

        setTimeout(() => {
          closeModal();
          setBrandHandle("");
        }, 3000);
      },
    },
  );

  const getContent = (): JSX.Element | null => {
    if (!status) {
      return (
        <FlexBox flexDirection="column" alignItems="stretch">
          <Heading heading={t("action")} headingType={HeadingTypeT.h2} />
          <Paragraph
            paragraph={t("modal_text")}
            color={theme.color.textGreyColor}
          />
          <Spacer direction="vertical" size="big" />
          <Paragraph paragraph={t("brand_name")} fontWeight={600} />
          <Spacer direction="vertical" size="micro" />
          <FlexBox>
            {"@"}
            <Spacer size="micro" />
            <StyledInput
              value={brandHandle}
              setValue={setBrandHandle}
              placeholder={"lafluenceapp"}
              clearButton={true}
              size="large"
            />
          </FlexBox>
          <Spacer direction="vertical" size="micro" />
          <Paragraph paragraphSize="small" paragraph={t("instagram_text")} />
          <Spacer direction="vertical" size="big" />
          <Button
            disabled={isLoading || isRefetching || brandHandle.length < 3}
            onClick={refetch}
            customWidth={CustomWidthE.full}
          >
            {t("button")}
          </Button>
        </FlexBox>
      );
    }

    if (status === "isTracking") {
      return (
        <ResponseWrapper flexDirection="column">
          <IllustrationWrapper size={rem(180)}>
            {getIllustration(IllustrationTypeE.yellow_books)}
          </IllustrationWrapper>
          <Paragraph
            paragraph={t("already_tracking.heading")}
            align="center"
            paragraphSize="large"
            fontWeight={600}
          />
          <Spacer direction="vertical" />
          <Paragraph align="center" paragraph={t("already_tracking.text")} />
        </ResponseWrapper>
      );
    }

    if (status === "done") {
      return (
        <ResponseWrapper flexDirection="column">
          <IllustrationWrapper size={rem(180)}>
            {getIllustration(IllustrationTypeE.success_books)}
          </IllustrationWrapper>
          <Paragraph
            paragraph={t("success.heading")}
            align="center"
            paragraphSize="large"
            fontWeight={600}
          />
          <Spacer direction="vertical" />
          <Paragraph align="center" paragraph={t("success.text")} />
        </ResponseWrapper>
      );
    }

    return null;
  };

  return getContent();
};

export default BrandHandleModal;
