import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Panel from "../../../components/Panel";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { getDateTime } from "../../../helpers/date";
import { rem, theme } from "../../../styling/theme";
import Icon, { IconTypeE } from "../../../ui/icons/Icon";
import { ApprovalT } from "../types";

const Wrapper = styled(Panel)<{ sideMargin: number }>(({ sideMargin }) => ({
  margin: rem(sideMargin),
  width: `calc(33% - ${rem(sideMargin * 2)})`,

  [theme.media.maxMd]: {
    width: `calc(50% - ${rem(sideMargin * 2)})`,
  },

  [theme.media.maxMi]: {
    width: "100%",
  },
}));

const IconWrapper = styled(FlexBox)<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    padding: rem(6),
    borderRadius: rem(4),
    backgroundColor,
  }),
);

const Devider = styled.div({
  width: "100%",
  paddingBottom: rem(20),
  marginBottom: rem(20),
  borderBottom: `1px solid ${theme.color.greyColor}`,
});

type ApprovalCardT = {
  approval: ApprovalT;
  sideMargin: number;
  t: (scope: I18n.Scope, options?: I18n.TranslateOptions | undefined) => string;
};

const ApprovalCard: FC<ApprovalCardT> = ({ sideMargin, approval, t }) => {
  const { email, company, created_at, mention } = approval;

  return (
    <Wrapper
      sideMargin={sideMargin}
      padding={`${rem(32)} ${rem(24)}`}
      flexDirection="column"
      customWidth={CustomWidthE.full}
      alignItems="flex-start"
    >
      <Heading
        heading={company}
        headingType={HeadingTypeT.h3}
        spaceBottom={4}
      />
      <Paragraph paragraph={`${t("by")} ${email}`} />
      <Devider />
      <FlexBox customWidth={CustomWidthE.full} alignItems="flex-start">
        <FlexBox
          customWidth={CustomWidthE.half}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Paragraph
            paragraph={t("mention")}
            paragraphSize="small"
            color={theme.color.textGreyColor}
          />
          <Spacer direction="vertical" size="small" />
          <FlexBox>
            <IconWrapper backgroundColor="rgba(155, 81, 224, 0.2)">
              <Icon icon={IconTypeE.at} color="#9B51E0" />
            </IconWrapper>
            <Spacer size="micro" />
            <Paragraph fontWeight={500} paragraph={mention} />
          </FlexBox>
        </FlexBox>
        <FlexBox
          customWidth={CustomWidthE.half}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Paragraph
            paragraph={t("created_at")}
            paragraphSize="small"
            color={theme.color.textGreyColor}
          />
          <Spacer direction="vertical" size="small" />
          <FlexBox>
            <IconWrapper backgroundColor="rgba(47, 128, 237, 0.2)">
              <Icon icon={IconTypeE.edit} color="#2F80ED" />
            </IconWrapper>
            <Spacer size="micro" />
            <Paragraph
              fontWeight={500}
              paragraph={getDateTime(created_at, "shortDate")}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {/* <Spacer direction="vertical" size="big" />
      <Spacer direction="vertical" size="big" />
      <FlexBox customWidth={CustomWidthE.full} justifyContent="space-between">
        <Button
          customWidth={CustomWidthE.half}
          size={ButtonSizeE.small}
          type={ButtonTypeE.grey}
        >
          t("cancel_approve")
        </Button>
        <Spacer />
        <Button
          customWidth={CustomWidthE.full}
          size={ButtonSizeE.small}
          href={media_groups_path}
        >
          {t("show_outputs")}
        </Button>
      </FlexBox> */}
    </Wrapper>
  );
};

export default ApprovalCard;
