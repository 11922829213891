import * as React from "react";
import { createContext, FC } from "react";
import { PermissionT } from "../entry-files/types";

type PermissionContextT = {
  isAllowedTo: (permission: PermissionT) => boolean;
};

const defaultBehaviour: PermissionContextT = {
  isAllowedTo: () => false,
};

export const PermissionContext =
  createContext<PermissionContextT>(defaultBehaviour);

type Props = {
  permissions: PermissionT[];
};

export const PermissionContextProvider: FC<Props> = ({
  permissions,
  children,
}) => {
  const isAllowedTo = (permission: PermissionT): boolean =>
    permissions.includes(permission);

  return (
    <PermissionContext.Provider value={{ isAllowedTo }}>
      {children}
    </PermissionContext.Provider>
  );
};
