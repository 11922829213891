import * as React from "react";
import { Dispatch, FC, SetStateAction, useLayoutEffect, useRef } from "react";
import { useResponsive } from "../../hooks/useResponsive";
import ReferencialWrapper from "../../ui/cards-ui/ReferencialWrapper";

type ReferencialCardT = {
  setReferencialCardWidth: Dispatch<SetStateAction<number>>;
  forceRecalculateWidth?: boolean;
};

const ReferencialCard: FC<ReferencialCardT> = ({
  setReferencialCardWidth,
  forceRecalculateWidth,
}) => {
  const referencialCard = useRef<HTMLDivElement>(null);
  const { windowWidth } = useResponsive();

  useLayoutEffect(() => {
    if (referencialCard.current) {
      const { width } = referencialCard.current.getBoundingClientRect();
      setReferencialCardWidth(width);
    }
  }, [referencialCard, forceRecalculateWidth, windowWidth]);

  return <ReferencialWrapper ref={referencialCard} />;
};

export default ReferencialCard;
