import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ContactNoteT } from "../features/influencer-database-detail/types";

export const updateContactNote = async (
  id: string,
  networkProfileId: string,
  text: string,
): Promise<ContactNoteT> => {
  const payload = { note: { text } };
  return axios
    .patch(
      getApiUrl(ApiRoutesE.NETWORK_PROFILES_CONTACT_NOTE, id, networkProfileId),
      payload,
    )
    .then((res) => res.data.contact_note);
};
