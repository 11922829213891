import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../components/FlexBox";
import { rem, theme } from "../../styling/theme";
import { getIcon, ICON_SAFE_AREA } from "./Icons";

export type IconSizeT =
  | "micro"
  | "small"
  | "default"
  | "big"
  | "large"
  | "mini"
  | "monster"
  | number;

export enum IconTypeE {
  app = "app",
  at = "at",
  alarm = "alarm",
  archived = "archived",
  arrowBottom = "arrowBottom",
  arrowDoubleDown = "arrowDoubleDown",
  arrowLeft = "arrowLeft",
  arrowLeftCircle = "arrowLeftCircle",
  arrowRightCircle = "arrowRightCircle",
  arrowRight = "arrowRight",
  arrowRefresh = "arrowRefresh",
  attachment = "attachment",
  bell = "bell",
  bottomArrow = "bottomArrow",
  bin = "bin",
  calendar = "calendar",
  chart = "chart",
  chatBubble = "chatBubble",
  check = "check",
  checkCircle = "checkCircle",
  checkCircleFillded = "checkCircleFillded",
  chevron = "chevron",
  cross = "cross",
  crossCircle = "crossCircle",
  csv = "csv",
  document = "document",
  documentBold = "documentBold",
  dots = "dots",
  download = "download",
  edit = "edit",
  eye = "eye",
  eyeStrike = "eyeStrike",
  euro = "euro",
  facebookLogo = "facebookLogo",
  filter = "filter",
  filterFilled = "filterFillded",
  folder = "folder",
  graph = "graph",
  heart = "heart",
  hashtag = "hashtag",
  igPost = "igPost",
  igStory = "igStory",
  igVideo = "igVideo",
  image = "image",
  info = "info",
  infoCircle = "infoCircle",
  key = "key",
  link = "link",
  list = "list",
  linkExternal = "linkExternal",
  loadArrow = "loadArrow",
  logo = "logo",
  moneyBag = "moneyBag",
  magnifyingGlass = "magnifyingGlass",
  magnifyingGlassZoomIn = "magnifyingGlassZoomIn",
  megaphone = "megaphone",
  messageQuestion = "messageQuestion",
  minus = "minus",
  newOffer = "newOffer",
  note = "note",
  person = "person",
  play = "play",
  plus = "plus",
  plusSquare = "plusSquare",
  pptx = "pptx",
  reportingPrivate = "reportingPrivate",
  reportingPublic = "reportingPublic",
  reportingManual = "reportingManual",
  sadDoc = "sadDoc",
  send = "send",
  share = "share",
  shield = "shield",
  shieldKey = "shieldKey",
  smileNeutral = "smileNeutral",
  smileSlightly = "smileSlightly",
  smileVery = "smileVery",
  smileSad = "smileSad",
  socialNetwork = "socialNetwork",
  socTiktok = "socTiktok",
  socDefault = "socDefault",
  socFacebook = "socFacebook",
  socInstagram = "socInstagram",
  socYoutube = "socYoutube",
  sort = "sort",
  star = "star",
  starRating = "starRating",
  stop = "stop",
  story = "story",
  table = "table",
  thumbs = "thumbs",
  time = "time",
  tiles = "tiles",
  upload = "upload",
  users = "users",
  wallet = "wallet",
  workflow = "workflow",
  xlsx = "xlsx",
}

type IconStyleT = {
  size: number;
  visible: boolean;
  rotate: "0deg" | "90deg" | "180deg" | "270deg";
  withShadow: boolean;
};

type getIconSizeT = (size: IconSizeT) => number;
export const getIconSize: getIconSizeT = (size) => {
  switch (size) {
    case "micro":
      return 8;

    case "mini":
      return 12;

    case "small":
      return 16;

    case "default":
      return 20;

    case "big":
      return 24;

    case "large":
      return 32;

    case "monster":
      return 48;

    default:
      return size;
  }
};

const IconWrapper = styled(FlexBox)<IconStyleT>(
  ({ size, visible, rotate, withShadow, zIndex }) => ({
    minWidth: rem(size + ICON_SAFE_AREA),
    width: rem(size + ICON_SAFE_AREA),
    height: rem(size + ICON_SAFE_AREA),
    opacity: visible ? 1 : 0,
    transform: `rotateX(${rotate})`,
    transition: "all 0.2s",
    transitionTimingFunction: theme.timing.default,
    filter: withShadow ? "drop-shadow(3px 3px 2px rgba(0, 0, 0, .4))" : "none",
    cursor: "inherit",
    zIndex,
  }),
);

export type IconRotateT = "0deg" | "90deg" | "180deg" | "270deg";

type IconT = {
  icon: IconTypeE;
  size?: IconSizeT;
  className?: string;
  color?: string;
  visible?: boolean;
  rotate?: IconRotateT;
  withShadow?: boolean;
  zIndex?: number | "auto";
  onClick?: () => void;
};

const Icon: FC<IconT> = ({
  size = "default",
  color = theme.color.textColor,
  icon,
  className,
  visible = true,
  rotate = "0deg",
  withShadow = false,
  zIndex = 1,
  onClick,
}) => {
  return (
    <IconWrapper
      onClick={onClick}
      visible={visible}
      className={className}
      rotate={rotate}
      size={getIconSize(size)}
      withShadow={withShadow}
      zIndex={zIndex}
    >
      {getIcon(icon, color)}
    </IconWrapper>
  );
};

export default Icon;
