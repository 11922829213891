import styled from "@emotion/styled";
import FlexBox from "../../components/FlexBox";

export const ClearCarouselWrapper = styled(FlexBox)`
  .carousel-root {
    width: 100%;
    height: 100%;
    outline: none;
  }

  .carousel {
    position: relative;
    width: 100%;
  }

  .carousel img {
    width: auto;
    display: inline-block;
    pointer-events: none;
    vertical-align: top;
    border: 0;
  }

  .carousel .carousel {
    position: relative;
  }

  .carousel .control-arrow {
    display: none;
  }

  .carousel .thumbs-wrapper {
    display: none;
  }

  .carousel.carousel-slider {
    position: relative;
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    height: 100%;
    transition: height 0.15s ease-in;
  }

  .carousel .slider-wrapper.axis-horizontal .slider {
    display: flex;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
  }

  .carousel .slider-wrapper.axis-vertical {
    display: flex;
  }

  .carousel .slider-wrapper.axis-vertical .slider {
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
    height: 100%;
  }

  .carousel .slider.animated {
    transition: all 0.35s ease-in-out;
  }

  .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
  }

  .carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0;
  }

  .carousel .slide .legend {
    display: none;
  }

  .carousel .control-dots {
    display: none;
  }

  .carousel .carousel-status {
    display: none;
  }

  .carousel:hover .slide .legend {
    opacity: 1;
  }
`;
