import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import { rem } from "../../../styling/theme";
import {
  AnimationOverlay,
  Avatar,
  CardWrapper,
  Line,
} from "../PlaceholderLoader";

const Wrapper = styled(FlexBox)({
  position: "relative",
  width: "100%",
  borderRadius: rem(16),
  minHeight: rem(40),
  overflow: "hidden",
  alignItems: "normal",
});

type InfluencerItemPlaceholderT = {
  withAvatar?: boolean;
  withBorder?: boolean;
};

const InfluencerItemPlaceholder: FC<InfluencerItemPlaceholderT> = ({
  withAvatar = true,
  withBorder,
}) => (
  <Wrapper customWidth={CustomWidthE.full}>
    <CardWrapper
      customWidth={CustomWidthE.full}
      withBorder={withBorder}
      noPadding={true}
    >
      <AnimationOverlay />
      <FlexBox customWidth={CustomWidthE.full} alignItems="center">
        {withAvatar && <Avatar size={25} />}
        <FlexBox customWidth={CustomWidthE.full} alignItems="flex-start">
          <Line noMarginBottom={true} />
        </FlexBox>
      </FlexBox>
    </CardWrapper>
  </Wrapper>
);

export default InfluencerItemPlaceholder;
