import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { CampaignT, PayloadCampaignT } from "../types";

export const updateCampaign = async (
  campaignId: string,
  payload: {
    campaign: PayloadCampaignT;
  },
): Promise<CampaignT> => {
  return axios
    .patch(getApiUrl(ApiRoutesE.CAMPAIGN, campaignId), payload)
    .then((res) => res.data.campaign);
};
