import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem } from "../styling/theme";

export type SpacerSizeT =
  | "micro"
  | "mini"
  | "small"
  | "default"
  | "big"
  | number;
type SpacerDirectionT = "horizontal" | "vertical";

type SpacerT = {
  size?: SpacerSizeT;
  direction?: SpacerDirectionT;
};

type SpacerStyleT = {
  size: number;
  direction: SpacerDirectionT;
};

type getSpacerSizeT = (size: SpacerSizeT) => number;
export const getSpacerSize: getSpacerSizeT = (size) => {
  switch (size) {
    case "micro":
      return 4;

    case "mini":
      return 8;

    case "small":
      return 12;

    case "default":
      return 16;

    case "big":
      return 32;

    default:
      return size;
  }
};

const StyledSpacer = styled.div<SpacerStyleT>(({ size, direction }) =>
  direction === "horizontal"
    ? {
        paddingRight: rem(size),
        cursor: "inherit",
      }
    : {
        paddingBottom: rem(size),
        cursor: "inherit",
      },
);

const Spacer: FC<SpacerT> = ({
  size = "default",
  direction = "horizontal",
}) => <StyledSpacer direction={direction} size={getSpacerSize(size)} />;

export default Spacer;
