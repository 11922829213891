import { Input as AntInput, InputRef } from "antd";
import * as React from "react";
import { Dispatch, FC, ReactNode, SetStateAction } from "react";
import FlexBox, { CustomWidthE } from "../components/FlexBox";
import InputWrapper from "../ui/InputWrapper";

type InputT = {
  value: string | number;
  setValue?: Dispatch<SetStateAction<string>>;
  name?: string;
  clearButton?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  label?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  ref?: React.Ref<InputRef>;
  regExp?: RegExp;
  size?: "small" | "middle" | "large";
  prefix?: ReactNode;
};

const Input: FC<InputT> = ({
  value,
  setValue = () => {},
  name,
  clearButton = false,
  disabled = false,
  placeholder,
  className,
  label,
  onBlur,
  onFocus,
  ref,
  regExp,
  size,
  prefix,
}) => {
  type handleOnChangeT = (value: string) => void;
  const handleOnChange: handleOnChangeT = (value) => {
    if (regExp) {
      const filteredValue = value.replace(/-/g, "");

      const typedValue = filteredValue === "" ? "" : parseInt(filteredValue);
      const initLimit = 2147483647;
      const initLimitCondition =
        typeof typedValue === "number" && typedValue > initLimit;

      if (regExp.exec(filteredValue)) {
        setValue(() => {
          if (initLimitCondition) {
            return `${initLimit}`;
          }

          return typedValue === "" ? "" : `${typedValue}`;
        });
      }

      return;
    }

    setValue(() => value);
  };

  return (
    <InputWrapper label={label}>
      <FlexBox customWidth={CustomWidthE.full} position="relative">
        <AntInput
          ref={ref}
          name={name}
          value={value}
          disabled={disabled}
          onChange={(e) => handleOnChange(e.target.value)}
          placeholder={placeholder}
          classNames={{ input: className }}
          onBlur={onBlur}
          onFocus={onFocus}
          allowClear={clearButton}
          size={size}
          addonBefore={prefix}
        />
      </FlexBox>
    </InputWrapper>
  );
};

export default Input;
