import styled from "@emotion/styled";
import FlexBox from "../components/FlexBox";
import { rem } from "../styling/theme";

export const VerticalScrollView = styled(FlexBox)<{ maxHeight: number }>(
  ({ maxHeight }) => ({
    maxHeight: rem(maxHeight),
    overflow: "scroll",
  }),
);
