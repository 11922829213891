import * as React from "react";
import { FC } from "react";
import { useQuery } from "react-query";
import Container from "../../../../components/Container";
import { GridWrapper } from "../../../../components/GridWrapper";
import Heading, { HeadingTypeT } from "../../../../components/Heading";
import NoResults from "../../../../components/NoResults";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { IllustrationTypeE } from "../../../../ui/Illustrations";
import TableContentWrapper from "../../../../ui/table/TableContentWrapper";
import { SessionT } from "../../../types";
import { getApprovals } from "../../data-access/getApprovals";
import ApprovalCard from "../../ui/ApprovalCard";
import InfluencerMainPanel from "../approval-request/InfluencerMainPanel";

const SIDE_MARGIN = 12;

type ApprovalsContainerT = {
  session: SessionT;
};

const ApprovalsContainer: FC<ApprovalsContainerT> = ({ session }) => {
  const { t } = useTranslate("influencer_root");

  const { data, isLoading } = useQuery(QueryKeys.APPROVALS, getApprovals);

  const getContent = (): JSX.Element => {
    if (isLoading) {
      return (
        <PlaceholderLoader
          type={PlaceholdersTypeE.camapign_card}
          count={3}
          perRow={3}
          direction="row"
          justifyContent="flex-start"
        />
      );
    }

    if (data && data.length === 0) {
      return (
        <TableContentWrapper isWhite={true}>
          <NoResults
            illustrationSize={350}
            illustration={IllustrationTypeE.approved_posts}
            message={t("sections.approvals.empty_title")}
          />
          <Spacer direction="vertical" />
          <Paragraph paragraph={t("sections.approvals.empty_text")} />
        </TableContentWrapper>
      );
    }

    return (
      <GridWrapper sidesMargin={SIDE_MARGIN}>
        {data?.map((approval) => {
          return (
            <ApprovalCard
              key={approval.id}
              t={t}
              sideMargin={SIDE_MARGIN}
              approval={approval}
            />
          );
        })}
      </GridWrapper>
    );
  };

  return (
    <Container brandNavigation={false}>
      <InfluencerMainPanel
        handle={session.nick_or_name}
        profileImageUrl={session.avatar_url}
      >
        <Heading
          heading={`${t("welcome")}, ${session.nick_or_name}`}
          fontWeight={600}
        />
      </InfluencerMainPanel>
      <Heading
        heading={t("approvals_title")}
        headingType={HeadingTypeT.h2}
        fontWeight={600}
      />
      {getContent()}
    </Container>
  );
};

export default ApprovalsContainer;
