import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

export const hideCampaignMediaGroup = async (
  campaignMediaGroupId: string,
  campaignId: string,
): Promise<{}> => {
  return axios
    .patch(
      getApiUrl(
        ApiRoutesE.CAMPAIGN_MEDIA_GROUPS_HIDE,
        campaignMediaGroupId,
        campaignId,
      ),
      {},
    )
    .then((res) => res.data);
};
