import { CSSObject } from "@emotion/react";

export const AntiShake: CSSObject = {
  backfaceVisibility: "hidden",
  transform: "translateZ(0) scale(1.0, 1.0)",
};

export const RemoveInputStyles: CSSObject = {
  fontFamily: '"Inter", sans-serif',
  border: "none",
  backgroundImage: "none",
  backgroundColor: "transparent",
  boxShadow: "none",
  WebkitAppearance: "none",
  outline: "none",
};
