import { Form, FormInstance, Input } from "antd";
import * as React from "react";
import { FC } from "react";
import { useTranslate } from "../../../../hooks/useTranslate";

export type CompetitiveAnalysisFieldTypeT = {
  handle: string;
};

type CreateFormT = {
  form: FormInstance;
  onFinishHandle: () => void;
};

const CreateForm: FC<CreateFormT> = ({ form, onFinishHandle }) => {
  const { t } = useTranslate("brands.competitive_analyses.index");

  return (
    <Form
      form={form}
      onFinish={onFinishHandle}
      name="newCompetitiveAnalysis"
      layout="vertical"
      style={{ width: "100%" }}
      requiredMark={false}
      validateTrigger={["onSubmit"]}
    >
      <Form.Item<CompetitiveAnalysisFieldTypeT>
        label={t("modal.handle")}
        style={{ width: "100%" }}
        name="handle"
        rules={[{ required: true }]}
      >
        <Input addonBefore="@" placeholder={t("modal.handle_placeholder")} />
      </Form.Item>
    </Form>
  );
};

export default CreateForm;
