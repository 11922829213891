import styled from "@emotion/styled";
import { theme } from "../styling/theme";

export const Overlay = styled.div<{ zIndex?: number }>(
  ({ zIndex = "auto" }) => ({
    display: "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.color.overlayColor,
    zIndex: zIndex,
  }),
);
