import { QueryClient } from "react-query";
import { MediaToRemoveStateT } from "../../features/media-groups/MediaGoupsContainer";
import { MediaGroupT, QueryStackT } from "../../types";

type removeMediaMutationT = (
  queryClient: QueryClient,
  mediaToRemove: MediaToRemoveStateT,
  afterMutationHandle: () => void,
  queryStack: QueryStackT,
) => void;

export const removeMediaMutation: removeMediaMutationT = (
  queryClient,
  mediaToRemove,
  afterMutationHandle,
  queryStack,
) => {
  queryClient.setQueryData<MediaGroupT[] | undefined>(
    queryStack,
    (prevState) => {
      if (prevState) {
        const currentMediaGroupIndex = prevState.findIndex(
          (mediaGroup) => mediaGroup.id === mediaToRemove.mediaGroupId,
        );

        if (currentMediaGroupIndex > -1) {
          const currentMediaGroup = prevState[currentMediaGroupIndex];

          const newCurrentMediaGroup = {
            ...currentMediaGroup,
            medias: [
              ...currentMediaGroup.medias.filter(
                (media) => media.id !== mediaToRemove.id,
              ),
            ],
          };

          const newState = prevState;
          newState[currentMediaGroupIndex] = newCurrentMediaGroup;

          return newState;
        }
      }

      return prevState;
    },
  );

  afterMutationHandle();
};
