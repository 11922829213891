import { Checkbox as AntCheckbox } from "antd";
import * as React from "react";
import { FC } from "react";

type CheckBoxT = {
  active: boolean;
  disabled?: boolean;
  onClickHandle?: () => void;
  size?: "mini" | "micro";
};

const CheckBox: FC<CheckBoxT> = ({
  active,
  onClickHandle,
  disabled = false,
  size = "mini",
  children,
}) => {
  return (
    <AntCheckbox checked={active} disabled={disabled} onChange={onClickHandle}>
      {children}
    </AntCheckbox>
  );
};

export default CheckBox;
