import axios from "axios";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { HostedPortalSessionT } from "../types";

export const generatePortalSession = async (
  customerId: string,
): Promise<HostedPortalSessionT> => {
  return axios
    .get(
      getUrlWithQuery(getApiUrl(ApiRoutesE.SUBSCRIPTIONS_PORTAL), {
        customer_id: customerId,
      }),
    )
    .then((res) => res.data);
};
