import { DeleteOutlined, PieChartOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Popconfirm, Popover, Table as AntTable } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormInstance } from "antd/lib";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import BlueLink from "../../../../components/BlueLink";
import EmptyIntroducingContainer from "../../../../components/EmptyIntroductingContainer";
import FlexBox from "../../../../components/FlexBox";
import Loader from "../../../../components/Loader";
import Panel from "../../../../components/Panel";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { designToken } from "../../../../helpers/antDesign";
import { getDateTime } from "../../../../helpers/date";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { NetworkProfileT } from "../../../types";
import { ToastContext } from "../../context-providers/ToastContextProvider";
import { removeOverlapAnalysisMutation } from "../../data-access/mutation/removeOverlapAnalysisMutation";
import { removeOverlapAnalysis } from "../../data-access/removeOverlapAnalysis";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { OverlapAnalysisT } from "../../types";
import CreateForm from "./CreateForm";

const emptyImg = require("../../../../assets/overlapAnalisysEmpty.png");

const StyledPanel = styled(Panel)<{ fullHeight: boolean }>(
  ({ fullHeight }) => ({
    width: "100%",
    overflow: "hidden",
    minHeight: fullHeight ? `calc(100dvh - ${rem(96)})` : "auto",
    alignItems: "stretch",
  }),
);

const DetailsWrapper = styled(FlexBox)<{
  open: boolean;
  marginLeft?: number;
}>(({ open, marginLeft }) => ({
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  height: open ? rem(224) : 0,
  transition: "height 0.5s",
  overflow: "hidden",
  marginLeft: marginLeft && rem(marginLeft),
  gap: rem(24),
}));

export type PartnershipToRemoveT = {
  id: string;
  removed: boolean;
};

interface DataType {
  key: string;
  overlap: OverlapAnalysisT;
}

type TableT = {
  dataIsLoading: boolean;
  onAddNewCampaignClickHandle: () => void;
  overlapAnalyses?: OverlapAnalysisT[];
  form: FormInstance;
  onFinishHandle: () => void;
  loading: boolean;
  suggestions: NetworkProfileT[];
  onTypeChangeHandle: (value: string) => void;
  onSubmitHandle: () => void;
};

const Table: FC<TableT> = ({
  dataIsLoading,
  onAddNewCampaignClickHandle = () => {},
  overlapAnalyses,
  form,
  onFinishHandle,
  loading,
  suggestions,
  onTypeChangeHandle,
  onSubmitHandle,
}) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const { t } = useTranslate("brands.overlap_analyses.index");

  const { openToast } = useContext(ToastContext);
  const navigate = useRouterNavigate();

  const queryClient = useQueryClient();
  const { mutate: destroy } = useMutation(
    (id: string) => removeOverlapAnalysis(id),
    {
      onSuccess: (_, id) => {
        removeOverlapAnalysisMutation(queryClient, id);
        openToast({
          type: "success",
          message: t("remove_success"),
        });
      },
    },
  );

  const showStatus = (overlapAnalysis: OverlapAnalysisT): JSX.Element => {
    switch (overlapAnalysis.status) {
      case "no_data":
        return (
          <Popover content={<Paragraph paragraph={t("no_data_info_html")} />}>
            <FlexBox justifyContent="flex-start">
              <Paragraph paragraph={t("no_data")} />
              <Spacer size="mini" />
              <Icon
                icon={IconTypeE.infoCircle}
                color={theme.color.textGreyColor}
                size="small"
                zIndex={"auto"}
              />
            </FlexBox>
          </Popover>
        );
      case "pending":
        return <Paragraph paragraph={t("pending")} />;
      case "finished":
        return (
          <Button
            type="primary"
            onClick={() =>
              navigate(NavigationKeyE.OverlapAnalyses, overlapAnalysis.id)
            }
          >
            {t("show")}
          </Button>
        );
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("table.title"),
      dataIndex: "title",
      render: (_, record) => record.overlap.title,
      sorter: (a, b) => a.overlap.title.localeCompare(b.overlap.title),
    },
    {
      title: t("table.handles"),
      dataIndex: "handles",
      render: (_, record) => record.overlap.handles.join(", "),
    },
    {
      title: t("table.created_at"),
      dataIndex: "created_at",
      render: (_, record) =>
        getDateTime(record.overlap.created_at, "shortDate"),
      sorter: (a, b) =>
        a.overlap.created_at.localeCompare(b.overlap.created_at),
    },
    {
      title: undefined,
      dataIndex: "action",
      width: "20%",
      render: (_, record) => (
        <FlexBox justifyContent="space-between">
          {showStatus(record.overlap)}
          {record.overlap.status === "no_data" && (
            <Popconfirm
              title={t("delete_confirmation")}
              onConfirm={() => destroy(record.overlap.id)}
              okText={t("modal.ok")}
              cancelText={t("modal.cancel")}
            >
              <Button
                type="text"
                icon={<DeleteOutlined />}
                style={{ color: designToken.colorTextTertiary }}
              />
            </Popconfirm>
          )}
        </FlexBox>
      ),
    },
  ];

  const getTableContent = (): JSX.Element => {
    if (overlapAnalyses && overlapAnalyses.length > 0) {
      const data: DataType[] = overlapAnalyses.map((overlap) => ({
        key: overlap.id,
        overlap: overlap,
      }));

      return (
        <AntTable
          style={{ width: "100%" }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      );
    }

    if (dataIsLoading) {
      return <Loader />;
    }

    return (
      <>
        <EmptyIntroducingContainer imageSrc={emptyImg}>
          <FlexBox flexDirection="column" gap={rem(24)} alignItems="flex-start">
            <Paragraph paragraph={t("empty.title")} fontWeight={600} />
            <Paragraph paragraph={t("empty.subtitle")} />
          </FlexBox>
          <BlueLink
            text={showDetails ? t("empty.show_less") : t("empty.show_more")}
            onClick={() => setShowDetails((prev) => !prev)}
            paragraphSize="small"
            icon={IconTypeE.arrowBottom}
            rotate={showDetails ? "180deg" : "0deg"}
            iconPosition="after"
            iconColor={designToken.colorPrimary}
          />
          <DetailsWrapper open={showDetails}>
            <FlexBox
              flexDirection="column"
              alignItems="flex-start"
              gap={rem(8)}
            >
              <FlexBox gap={rem(8)}>
                <PieChartOutlined
                  style={{ color: theme.color.secondaryColor }}
                />
                <Paragraph
                  paragraph={t("empty.maximum.title")}
                  fontWeight={600}
                  paragraphSize="small"
                />
              </FlexBox>
              <Paragraph
                paragraph={t("empty.maximum.description")}
                color={designToken.colorTextSecondary}
                paragraphSize="small"
              />
            </FlexBox>
            <FlexBox
              flexDirection="column"
              alignItems="flex-start"
              gap={rem(8)}
            >
              <FlexBox gap={rem(8)}>
                <PieChartOutlined
                  style={{ color: theme.color.secondaryColor }}
                />
                <Paragraph
                  paragraph={t("empty.unique.title")}
                  fontWeight={600}
                  paragraphSize="small"
                />
              </FlexBox>
              <Paragraph
                paragraph={t("empty.unique.description")}
                color={designToken.colorTextSecondary}
                paragraphSize="small"
              />
            </FlexBox>
          </DetailsWrapper>
          <Paragraph paragraph={t("empty.add_new")} fontWeight={600} />
          <CreateForm
            form={form}
            onFinishHandle={onFinishHandle}
            suggestions={suggestions ? suggestions : []}
            onTypeChangeHandle={onTypeChangeHandle}
          />
          <Button onClick={onSubmitHandle} type="primary" loading={loading}>
            {t("modal.create")}
          </Button>
        </EmptyIntroducingContainer>
      </>
    );
  };

  return (
    <StyledPanel fullHeight={overlapAnalyses?.length === 0}>
      {getTableContent()}
    </StyledPanel>
  );
};

export default Table;
