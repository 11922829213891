import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { debounce } from "lodash";
import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import AvatarTile from "../../../../../components/AvatarTile";
import { QueryKeys } from "../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { NetworkProfileT, NetworkProviderT } from "../../../../types";
import { SubscriptionContext } from "../../../context-providers/SubscriptionsContextProvider";
import { getNetworkProfileSuggestions } from "../../../data-access/getNetworkProfileSuggestions";
import { NavigationKeyE } from "../../../router";

const { Option } = Select;

type SearchT = {
  provider: NetworkProviderT;
  options: NetworkProfileT[];
  setOptions: Dispatch<SetStateAction<NetworkProfileT[]>>;
};

const Search: FC<SearchT> = ({ provider, options, setOptions }) => {
  const [term, setTerm] = useState<string>();

  const { isTrial, openTrialModal } = useContext(SubscriptionContext);
  const { t } = useTranslate("brands.influencer_database.search");

  const { refetch } = useQuery(
    `${QueryKeys.NETWORK_PROFILE_SUGGESTIONS}-${provider}`,
    () =>
      getNetworkProfileSuggestions({
        term: term || "",
        provider: provider,
        database: "true",
      }),
    {
      enabled: false,
      onSuccess: (data) => setOptions(data),
    },
  );

  const handleOnSelect = (id: string): void => {
    if (isTrial) {
      openTrialModal();
      return;
    }
    window.open(
      `${window.location.origin}${NavigationKeyE.Database}/${id}/overview`,
      "_blank",
    );
  };

  const search = useRef(debounce(() => refetch(), 500)).current;
  useEffect(() => {
    if (term && term.length > 2) {
      search();
    }
  }, [term]);

  return (
    <>
      <Select
        showSearch
        value={term}
        placeholder={t("placeholder")}
        notFoundContent={null}
        filterOption={false}
        onSearch={setTerm}
        onSelect={(value) => handleOnSelect(value)}
        suffixIcon={<SearchOutlined rev="" />}
        style={{ width: "100%" }}
        dropdownRender={(list) => (
          <>
            <div>{list}</div>
          </>
        )}
      >
        {options.length > 0 &&
          options.map((option) => (
            <Option key={option.id} value={option.id}>
              <AvatarTile
                key={option.id}
                imageUrl={option.avatar_url}
                handle={option.profile_id}
                name={option.display_name}
                justifyContent="flex-start"
              />
            </Option>
          ))}
      </Select>
    </>
  );
};

export default Search;
