import { theme } from "../../../../../../styling/theme";
import { GeoTypeT } from "./Geo";

export const getOptionsGeo = (
  values: string[],
  labels: string[],
  key: "cities" | "countries",
) => {
  return {
    responsive: true,
    indexAxis: "y" as const,
    layout: {
      padding: {
        top: 5,
        right: 50,
      },
    },
    plugins: {
      customTicks: {
        borderColor: theme.color.greyColor,
        borderDash: [4, 8],
        dataValues: {
          [key]: values.map(
            (item) => `${Math.round(parseFloat(item) * 10) / 10}%`,
          ),
        },
        dataLabels: {
          [key]: labels,
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: ({ formattedValue }: any) => `${formattedValue}%`,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      x: {
        min: 0,
        max: 100,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
  };
};

export const getCustomTicksGeo = (key: GeoTypeT): any => ({
  id: `customTicks`,
  beforeDraw(chart: any, _: any, options: any) {
    const {
      ctx,
      chartArea: { left, height, width },
    } = chart;

    const values = options.dataValues[key];
    const labels = options.dataLabels[key];
    const part = height / values.length / 2;

    ctx.setLineDash(options.borderDash);
    ctx.strokeStyle = options.borderColor;
    ctx.fillStyle = theme.color.textGreyColor;
    ctx.textAlign = "right";
    ctx.font = "bold 14px sans-serif";
    ctx.beginPath();

    values.forEach((item: any, index: number) => {
      const currentPart = part * (index * 2 + 1);
      ctx.moveTo(left, currentPart);
      ctx.lineTo(width, currentPart);
      ctx.fillText(item, width + 50, currentPart + 5);
    });

    ctx.fillStyle = theme.color.textLightGreyColor;
    ctx.textAlign = "left";
    ctx.font = "12px sans-serif";

    labels.forEach((item: any, index: number) => {
      const currentPart = part * (index * 2 + 1);
      ctx.fillText(item, 7, currentPart - 8);
    });

    ctx.stroke();
  },
});

export const getCustomTicksGeoExport = (key: GeoTypeT): any => ({
  id: `customTicks`,
  beforeDraw(chart: any, _: any, options: any) {
    const {
      ctx,
      chartArea: { left, height, width },
    } = chart;

    const values = options.dataValues[key];
    const labels = options.dataLabels[key];
    const part = height / values.length / 2;

    ctx.setLineDash(options.borderDash);
    ctx.strokeStyle = options.borderColor;
    ctx.fillStyle = theme.color.textGreyColor;
    ctx.textAlign = "right";
    ctx.font = "bold 18px sans-serif";
    ctx.beginPath();

    values.forEach((item: any, index: number) => {
      const currentPart = part * (index * 2 + 1);
      ctx.moveTo(left, currentPart);
      ctx.lineTo(width, currentPart);
      ctx.fillText(item, width + 50, currentPart + 5);
    });

    ctx.fillStyle = theme.color.textGreyColor;
    ctx.textAlign = "left";
    ctx.font = "bold 14px sans-serif";

    labels.forEach((item: any, index: number) => {
      const currentPart = part * (index * 2 + 1);
      ctx.fillText(item, 7, currentPart - 12);
    });

    ctx.stroke();
  },
});
