type TimeOptionsKey =
  | "fullNumeric"
  | "fullDate"
  | "weekDayTime"
  | "shortDate"
  | "time"
  | "month"
  | "dayMonth";
type TimeOptionsT = Record<TimeOptionsKey, {}>;

const options: TimeOptionsT = {
  fullNumeric: {
    hour: "numeric",
    minute: "numeric",
    month: "numeric",
    day: "numeric",
    year: "numeric",
  },
  fullDate: {
    hour: "numeric",
    minute: "numeric",
    weekday: "short",
    month: "long",
    day: "numeric",
  },
  weekDayTime: {
    hour: "numeric",
    minute: "numeric",
    weekday: "short",
  },
  time: {
    hour: "numeric",
    minute: "numeric",
  },
  shortDate: {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  },
  month: {
    month: "long",
  },
  dayMonth: {
    day: "numeric",
    month: "numeric",
  },
};

export const getDateTime = (
  date: string | undefined,
  typeKey: TimeOptionsKey,
): string => {
  if (!date) return "";

  const d = trimTimezone(date);
  return new Intl.DateTimeFormat("cs", options[typeKey]).format(new Date(d));
};

export const getDateTimeDynamicOption = (dateString: string): string => {
  const date = new Date(trimTimezone(dateString));
  const today = new Date();

  const diffInTime = date.getTime() - today.getTime();
  const diffInDays = Math.abs(diffInTime / (1000 * 3600 * 24));

  if (diffInDays > 7) {
    return new Intl.DateTimeFormat("cs", options["shortDate"]).format(date);
  }

  if (diffInDays > 0.5) {
    return new Intl.DateTimeFormat("cs", options["weekDayTime"]).format(date);
  }

  return new Intl.DateTimeFormat("cs", options["time"]).format(date);
};

export const getDateForRangeDayFromToday = (days: number): Date => {
  return new Date(Date.now() + days * 24 * 60 * 60 * 1000);
};

export const getStringDate = (e: Date): string => {
  const monthNum = `${e.getMonth() + 1}`;
  const yearNumm = `${e.getFullYear()}`;
  const dayNum = `${e.getDate()}`;

  const addZeroToSingleDigit = (digit: string): string => {
    return digit.length < 2 ? `0${digit}` : digit;
  };

  return `${yearNumm}-${addZeroToSingleDigit(monthNum)}-${addZeroToSingleDigit(
    dayNum,
  )}`;
};
export const getSystemDate = (date: Date): string => {
  return date.toISOString().split("T")[0];
};

const trimTimezone = (dateString: string): string => {
  return dateString.split("+")[0].trim();
};
