import { _o } from "./helpers";

export const textAreaHeightInit = (): void => {
  const textAreas = document.getElementsByClassName("textarea-dynamic-height");

  const handleHeight = (textArea: HTMLTextAreaElement): void => {
    const scrollBumper = 50;
    const defaultHeight = textArea.dataset.defaultHeight;

    if (textArea.value.length < 1) {
      textArea.setAttribute("style", `height:${defaultHeight}px`);

      return;
    }

    if (textArea.clientHeight < textArea.scrollHeight) {
      textArea.setAttribute(
        "style",
        `height:${textArea.scrollHeight + scrollBumper}px`
      );
    }
  };

  if (textAreas) {
    _o(textAreas).forEach((textArea) => {
      textArea.addEventListener("input", (e) =>
        handleHeight(e.target as HTMLTextAreaElement)
      );

      handleHeight(textArea as HTMLTextAreaElement);
    });
  }
};
