import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import { rem, theme } from "../../../styling/theme";
import Icon, { IconTypeE } from "../../../ui/icons/Icon";
import { AnimationOverlay } from "../PlaceholderLoader";

const Wrapper = styled(FlexBox)<{ perRow: number }>(({ perRow }) => ({
  position: "relative",
  width: `calc(${100 / perRow}% - ${rem(8)})`,
  margin: rem(4),
  height: rem(170),
  border: `1px solid ${theme.color.greyLightColor}`,
  backgroundColor: theme.color.whiteColor,
  borderRadius: rem(8),
  overflow: "hidden",

  [theme.media.maxLg]: {
    width: "32%",
  },

  [theme.media.maxSm]: {
    width: "49%",
  },

  [theme.media.maxS]: {
    width: "100%",
    margin: 0,
  },
}));

const Content = styled(FlexBox)({
  backgroundColor: theme.color.greyLightColor,
});

const OutputBoxPlaceholder: FC<{ perRow: number }> = ({ perRow }) => (
  <Wrapper perRow={perRow}>
    <AnimationOverlay />

    <Content fullHeight={true} customWidth={CustomWidthE.full}>
      <Icon icon={IconTypeE.image} size={50} color={theme.color.whiteColor} />
    </Content>
  </Wrapper>
);

export default OutputBoxPlaceholder;
