import debounce from "lodash/debounce";
import { useEffect, useRef, useState } from "react";

type useScrollT = (debounce?: number) => {
  currentOffset: number;
};

export const useScroll: useScrollT = (debounceTime = 150) => {
  const WINDOW_CONST = typeof window !== "undefined" && window;

  const [currentOffset, setCurrentOffset] = useState(0);

  const handleScroll = (): void => {
    setCurrentOffset(WINDOW_CONST ? WINDOW_CONST.pageYOffset : 0);
  };

  const debounceScroll = useRef(debounce(handleScroll, debounceTime)).current;

  useEffect(() => {
    WINDOW_CONST && WINDOW_CONST.addEventListener("scroll", debounceScroll);
    return (): void => {
      WINDOW_CONST &&
        WINDOW_CONST.removeEventListener("scroll", debounceScroll);
    };
  }, [WINDOW_CONST, debounceScroll]);

  return {
    currentOffset,
  };
};
