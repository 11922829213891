$(document).on("click", ".turbo-link", function (e) {
  e.preventDefault();
  const _ = $(this)[0];

  const {
    dataset: { url },
  } = _;

  if (url) {
    Turbolinks.visit(url);
  }
});

$(document).on("focus", ".datepicker", function () {
  const history = $(this).data("history") === true;
  $(this).datepicker({
    autoclose: true,
    format: "dd.mm.yyyy",
    startDate: history ? "" : new Date(),
    maxViewMode: 0,
  });
});

$(document).on("change", ".tac_payment", function () {
  if ($(this).is(":checked")) {
    $(this).closest("form").find("input[type=submit]").attr("disabled", null);
  } else {
    $(this).closest("form").find("input[type=submit]").attr("disabled", "");
  }
});

window.setOptions = function () {
  $(".filter_countries .inp-select").each(function (index, sel) {
    var select = $(sel);
    if (select.val() === null || select.val().length == 0) {
      select.find("option:eq(0)").attr("disabled", true);
    } else {
      select.find("option:eq(0)").removeAttr("disabled");
    }
  });
};
