import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { ListT } from "../../types";

export const updateListsMutation = (
  queryClient: QueryClient,
  data: ListT,
  afterMutationHandle?: () => void,
): void => {
  queryClient.setQueryData<ListT[]>(QueryKeys.LISTS, (prevState) => {
    if (prevState) {
      const findIndex = prevState.findIndex((list) => list.id === data.id);

      // list was not found, so it was just created
      if (findIndex === -1) {
        return [...prevState, data];
      }

      const newState = prevState;
      newState[findIndex] = data;
      return newState;
    }

    return [data];
  });

  afterMutationHandle && afterMutationHandle();
};
