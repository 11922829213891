type putSpacesToThousandT = (num: number | string) => string | number;
export const putSpacesToThousand: putSpacesToThousandT = (num) => {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return num;
};

type numberToTextT = (number: number, toFixed?: number) => string;
export const numberToText: numberToTextT = (number, toFixed = 1) => {
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return `${number}`;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return `${scaled.toFixed(toFixed) + suffix}`;
};

type capitalizeFirstLetterT = (string: string) => string;
export const capitalizeFirstLetter: capitalizeFirstLetterT = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

type roundNumberT = (value: number, precision?: number) => number;
export const roundNumber: roundNumberT = (value, precision = 0) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const getColorFromString = (str: string): string => {
  const hash = str.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

  return `hsl(${hash % 360}, 60%, 60%)`;
};

export const devideStringToTwo = (str: string): string[] => {
  const half = Math.ceil(str.length / 2);
  return [str.slice(0, half), str.slice(half)];
};

export const formatTableValue = (
  value: number | null,
  fixed: number = 1,
): string | number => {
  if (value !== null) {
    if (value === 0) {
      return "0";
    } else if (value < 1) {
      return value.toFixed(fixed);
    } else {
      return putSpacesToThousand(value.toFixed(fixed));
    }
  } else {
    return "-";
  }
};

export const numberWithSpaces = (val: number | string): string => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
