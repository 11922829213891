import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import BlueLink from "../../../../../components/BlueLink";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { getDateTime } from "../../../../../helpers/date";
import { MixpanelEventT, trackEvent } from "../../../../../helpers/trackEvent";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import TableColumn from "../../../../../ui/table/TableColumn";
import TableItemWrapper from "../../../../../ui/table/TableItemWrapper";
import { ExistedOutputT } from "../../../types";
import { BrandAffinityT } from "../types";

const MinWidth = styled.div({
  minWidth: rem(47),
});

type TableItemT = {
  item: BrandAffinityT;
  setCurrentMedias: Dispatch<SetStateAction<ExistedOutputT[] | null>>;
  last?: boolean;
};

const TableItem: FC<TableItemT> = ({
  item,
  setCurrentMedias,
  last = false,
}) => {
  const { t } = useTranslate(
    "brands.influencer_database.detail.brand_affinity",
  );

  const onClickHandle = (item: BrandAffinityT): void => {
    setCurrentMedias(item.medias);

    trackEvent(MixpanelEventT.databaseDetailBrandAffinityShowDetail, {
      mention: item.id,
    });
  };

  return (
    <TableItemWrapper
      key={item.id}
      last={last}
      renderChildren={(hover) => {
        return (
          <>
            <TableColumn>
              <BlueLink
                text={`@${item.id}`}
                href={`https://instagram.com/${item.id}`}
                target="_blank"
              />
            </TableColumn>
            <TableColumn columnWidth="20%">
              <Paragraph
                paragraph={getDateTime(item.medias[0].timestamp, "shortDate")}
              />
            </TableColumn>
            <TableColumn>
              <MinWidth>
                <Paragraph
                  paragraph={`${item.medias.length} x`}
                  paragraphSize="big"
                  fontWeight={500}
                  align="right"
                />
              </MinWidth>
              {item.medias.length > 0 && (
                <>
                  <Spacer size="small" />
                  <BlueLink
                    onClick={() => onClickHandle(item)}
                    text={`(${t("preview")})`}
                  />
                </>
              )}
            </TableColumn>
          </>
        );
      }}
    />
  );
};

export default TableItem;
