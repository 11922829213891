import styled from "@emotion/styled";
import { InputNumber, Radio, Slider, Space } from "antd";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import FilterContainer from "../components/FilterContainer";
import { GenderTypeT, SearchQueryT } from "../types";

const FromWrapper = styled(FlexBox)<{ hidden: boolean }>(({ hidden }) => ({
  display: hidden ? "none" : "flex",
  paddingLeft: rem(25),
}));

const SliderWrapper = styled.div({
  width: "100%",
});

const StyledSpace = styled(Space)({
  width: "100%",
});

const StyledRadioGroup = styled(Radio.Group)({
  width: "100%",
});

interface FollowersGenderT {
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
}

const FollowersGender: FC<FollowersGenderT> = ({
  searchQuery,
  setFilterSearchQuery,
}) => {
  const [genderType, setGenderType] = useState<GenderTypeT | undefined>();
  const [maleFrom, setMaleFrom] = useState<number>(51);
  const [femaleFrom, setFemaleFrom] = useState<number>(51);
  const { t } = useTranslate(
    "brands.influencer_database.filter.followers_gender",
  );

  const handleInputOnChange = (
    genderType: GenderTypeT,
    value?: number | null,
  ): void => {
    const setValue = genderType === "female" ? setFemaleFrom : setMaleFrom;
    const from = value
      ? value
      : genderType === "female"
        ? femaleFrom
        : maleFrom;

    setGenderType(genderType);
    setValue(from);

    setFilterSearchQuery({
      followers_gender_type: genderType,
      followers_gender_from: genderType && from,
    });
  };

  const setState = (searchQuery: SearchQueryT): void => {
    const { followers_gender_type, followers_gender_from } = searchQuery;
    setGenderType(followers_gender_type);

    if (followers_gender_type === "male") {
      setMaleFrom(followers_gender_from || 51);
    } else {
      setFemaleFrom(followers_gender_from || 51);
    }
  };

  useEffect(() => {
    setState(searchQuery);
  }, [searchQuery]);

  return (
    <FilterContainer t={t} tooltip={false}>
      <StyledRadioGroup
        name="gender"
        value={genderType}
        defaultValue={undefined}
        onChange={(e) => handleInputOnChange(e.target.value)}
      >
        <StyledSpace direction="vertical">
          <Radio value={undefined}>{t("none")}</Radio>
          <FlexBox flexDirection="column" alignItems="flex-start">
            <Radio value="female">{t("female")}</Radio>
            <FromWrapper
              flexDirection="row"
              customWidth={CustomWidthE.full}
              hidden={genderType !== "female"}
            >
              <SliderWrapper>
                <Slider
                  min={51}
                  max={100}
                  value={femaleFrom}
                  onChange={(value) => handleInputOnChange("female", value)}
                />
              </SliderWrapper>
              <InputNumber
                max={100}
                min={51}
                disabled={genderType !== "female"}
                onChange={(value) => handleInputOnChange("female", value)}
                addonBefore="≥"
                addonAfter="%"
                type="number"
                value={femaleFrom}
              />
            </FromWrapper>
          </FlexBox>
          <FlexBox flexDirection="column" alignItems="flex-start">
            <Radio value="male">{t("male")}</Radio>
            <FromWrapper
              flexDirection="row"
              customWidth={CustomWidthE.full}
              hidden={genderType !== "male"}
            >
              <SliderWrapper>
                <Slider
                  min={51}
                  max={100}
                  value={maleFrom}
                  onChange={(value) => handleInputOnChange("male", value)}
                />
              </SliderWrapper>
              <InputNumber
                min={51}
                max={100}
                disabled={genderType !== "male"}
                onChange={(value) => handleInputOnChange("male", value)}
                addonBefore="≥"
                addonAfter="%"
                type="number"
                value={maleFrom}
              />
            </FromWrapper>
          </FlexBox>
        </StyledSpace>
      </StyledRadioGroup>
    </FilterContainer>
  );
};

export default FollowersGender;
