import styled from "@emotion/styled";
import { Tabs, TabsProps } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import * as React from "react";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { Route, Routes, useMatch, useParams } from "react-router-dom";
import BlueLink from "../../../../components/BlueLink";
import Container from "../../../../components/Container";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../components/Heading";
import Panel from "../../../../components/Panel";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import StickyHeader from "../../../../components/StickyHeader";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { MixpanelEventT } from "../../../../helpers/trackEvent";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useTranslate } from "../../../../hooks/useTranslate";
import { breakpoints, rem, theme } from "../../../../styling/theme";
import AvatarWithCircles from "../../../../ui/AvatarWithCircles";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import { getNetworkProfile } from "../../data-access/getNetworkProfile";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import Actions from "./components/Actions";
import Cards from "./components/Cards";
import ExportContainer from "./components/ExportContainer";
import Crm from "./Crm";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
);

const ContentWrapper = styled(Panel)({
  flexDirection: "column",
  borderRadius: rem(8),

  [theme.media.maxSm]: {
    marginTop: rem(16),
  },
});

const StyledTabs = styled(Tabs)({
  width: "100%",
  padding: `0 ${rem(16)}`,

  ".ant-tabs-nav": {
    marginBottom: 0,
  },
});

const HandleWrapper = styled(FlexBox)({
  display: "flex",

  [theme.media.maxSm]: {
    display: "none",
  },
});

const InfluencerDatabaseDetailContainer: FC = () => {
  const { id } = useParams() as { id: string };

  const [exportType, setExportType] = useState<"copy" | "save" | null>(null);
  const [exportPrice, setExportPrice] = useState<string>("");
  const [exportDescription, setExportDescription] = useState<string>("");

  const MAX_BIO_LENGTH = 150;
  const { tabletVersion } = useResponsive(breakpoints.sm);

  const navigate = useRouterNavigate();
  const match = useMatch(`${NavigationKeyE.Database}/${id}/:key`);
  const activeKey = match ? match.params.key : "overview";

  const { t } = useTranslate("brands.influencer_database.detail");

  const tabItems: TabsProps["items"] = [
    {
      key: "overview",
      label: t("overview"),
    },
    {
      key: "crm",
      label: t("crm"),
    },
  ];

  const { data: networkProfile, isLoading } = useQuery(
    QueryKeys.NETWORK_PROFILE_DETAIL,
    () => getNetworkProfile(id),
    {
      onError: () => {
        navigate(NavigationKeyE.Error);
      },
    },
  );

  const onTabChangeHandle = (key: string): void => {
    navigate(NavigationKeyE.Database, `${id}/${key}`);
  };

  const getContent = (fixed: boolean): JSX.Element => {
    if (!networkProfile || isLoading) {
      return (
        <PlaceholderLoader type={PlaceholdersTypeE.camapign_detail_header} />
      );
    }

    const { handle, avatar_url, name, profile_url, bio, provider } =
      networkProfile;

    return (
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        customWidth={CustomWidthE.full}
        flexDirection="row"
      >
        <FlexBox customWidth={CustomWidthE.full}>
          <FlexBox
            fullHeight
            onClick={() => navigate(NavigationKeyE.Database)}
            cursor="pointer"
          >
            <Spacer />
            <Icon icon={IconTypeE.arrowLeft} size="small" />
          </FlexBox>
          <AvatarWithCircles
            handle={handle}
            profileImageUrl={avatar_url}
            provider={provider}
            avatarSize={fixed ? "mini" : "default"}
          />
          <Spacer />
          <FlexBox
            flexDirection="column"
            customWidth={CustomWidthE.full}
            alignItems="flex-start"
          >
            <FlexBox flexDirection="row" gap={rem(4)} alignItems="center">
              <Heading
                spaceBottom={0}
                heading={name ? name : handle}
                headingType={HeadingTypeT.h3}
              />
              <HandleWrapper>
                <BlueLink
                  text={`@${handle}`}
                  href={profile_url}
                  icon={IconTypeE.linkExternal}
                  target="_blank"
                  iconPosition={"after"}
                  fontWeight={400}
                  marginTop={"0"}
                  event={MixpanelEventT.databaseDetailSocialMediaProfileClick}
                />
              </HandleWrapper>
            </FlexBox>
            <Spacer direction="vertical" size="mini" />

            {!fixed && (
              <>
                <Paragraph truncate={MAX_BIO_LENGTH} paragraph={bio} />
                {bio && bio.length > MAX_BIO_LENGTH && (
                  <BlueLink
                    text={t("view_more")}
                    paragraphSize="small"
                    target="_blank"
                    href={profile_url}
                  />
                )}
              </>
            )}
          </FlexBox>
        </FlexBox>
        <Spacer />
        <Actions
          networkProfileId={id}
          setExportType={setExportType}
          setExportPrice={setExportPrice}
          setExportDescription={setExportDescription}
          exportPrice={exportPrice}
          exportDescription={exportDescription}
          adminRole={true} // TODO
        />
      </FlexBox>
    );
  };

  return (
    <Container>
      <StickyHeader
        renderChildren={(fixed) => getContent(fixed || tabletVersion)}
      />

      {networkProfile && (
        <>
          <ExportContainer
            influencerData={networkProfile}
            exportType={exportType}
            setExportType={setExportType}
            exportPrice={exportPrice}
            exportDescription={exportDescription}
          />
          <ContentWrapper flexDirection="column">
            <StyledTabs
              items={tabItems}
              onChange={onTabChangeHandle}
              activeKey={activeKey}
            />
            <Routes>
              <Route
                path="overview"
                element={<Cards networkProfile={networkProfile} />}
              />
              <Route
                path="crm"
                element={<Crm networkProfile={networkProfile} />}
              />
            </Routes>
          </ContentWrapper>
        </>
      )}
    </Container>
  );
};

export default InfluencerDatabaseDetailContainer;
