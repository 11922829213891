import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { useTranslate } from "../../../../..//hooks/useTranslate";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import { IllustrationWrapper } from "../../../../../components/NoResults";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import { theme } from "../../../../../styling/theme";
import {
  getIllustration,
  IllustrationTypeE,
} from "../../../../../ui/Illustrations";

const MissingChartWrapper = styled(IllustrationWrapper)({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.color.whiteColor,
  height: "100%",
  marginBottom: 0,
});

const OpacityWrapper = styled(FlexBox)({
  opacity: 0.2,
});

const MissingChart: FC = () => {
  const { t } = useTranslate("brands.influencer_database.detail.card");

  return (
    <MissingChartWrapper>
      <OpacityWrapper customWidth={CustomWidthE.full} flexDirection="column">
        {getIllustration(IllustrationTypeE.missing_chart)}
        <Spacer direction="vertical" />
        <Paragraph paragraph={t("others.no_data")} />
      </OpacityWrapper>
    </MissingChartWrapper>
  );
};

export default MissingChart;
