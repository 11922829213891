import axios from "axios";
import uniqid from "uniqid";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import {
  AddedMediaGroupsStateT,
  EmptyMediaGroupT,
  MediaGroupKindT,
  MediaGroupT,
  MediaKindT,
} from "../types";

type createMediaGroupPayloadT = {
  media_group: {
    kind: MediaGroupKindT;
    temporary_id: string;
    partnership_id: string;
    medias_attributes: {
      kind: MediaKindT;
      url?: string;
      likes?: number;
      comments?: number;
      reach?: number;
      impressions?: number;
      swipeups?: number;
      sticker_taps?: number;
      image_urls?: string[];
    }[];
  };
};

export const createMediaGroup = async ({
  campaignId,
  payload,
}: {
  campaignId: string;
  payload: createMediaGroupPayloadT;
}): Promise<MediaGroupT> => {
  return axios
    .post(getApiUrl(ApiRoutesE.CAMPAIGN_MEDIA_GROUPS, campaignId), payload)
    .then((res) => res.data.media_group);
};

export const getEmptyMediaGroup = (tempId: string): EmptyMediaGroupT => {
  return {
    id: null,
    temporary_id: tempId,
    kind: null,
    medias: [],
  };
};

export const getAddedMediaGroup = (): AddedMediaGroupsStateT => {
  return [getEmptyMediaGroup(uniqid())];
};
