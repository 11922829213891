import styled from "@emotion/styled";
import * as React from "react";
import { FC, ReactNode, useState } from "react";
import FlexBox, { CustomWidthE } from "../../components/FlexBox";
import { hexToRgb, rem, theme } from "../../styling/theme";

const Wrapper = styled(FlexBox)<{
  hover: boolean;
  last: boolean;
  temporaryHide: boolean;
  customHeight: string;
}>(({ hover, temporaryHide, last, customHeight }) => ({
  backgroundColor: hover
    ? hexToRgb(theme.color.backgroundColor, 0.4)
    : "transparent",
  borderBottom:
    temporaryHide || last ? "none" : `1px solid ${theme.color.greyLightColor}`,
  borderBottomLeftRadius: last ? rem(8) : 0,
  borderBottomRightRadius: last ? rem(8) : 0,
  transition: "all 0.3s",
  transitionTimingFunction: theme.timing.default,
  visibility: temporaryHide ? "hidden" : "visible",
  height: customHeight,
  maxHeight: temporaryHide ? 0 : customHeight,
  overflow: temporaryHide ? "hidden" : "visible",
}));

type TableItemWrapperT = {
  renderChildren: (hover: boolean) => ReactNode;
  temporaryHide?: boolean;
  first?: boolean;
  last?: boolean;
  customHeight?: string;
};

const TableItemWrapper: FC<TableItemWrapperT> = React.memo(
  ({
    temporaryHide = false,
    last = false,
    renderChildren,
    customHeight = rem(70),
  }) => {
    const [hover, setHover] = useState(false);

    return (
      <Wrapper
        justifyContent="flex-start"
        alignItems="stretch"
        customWidth={CustomWidthE.full}
        last={last}
        customHeight={customHeight}
        hover={hover}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        temporaryHide={temporaryHide}
      >
        {renderChildren(hover)}
      </Wrapper>
    );
  },
);

export default TableItemWrapper;
