import axios from "axios";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { SearchQueryT } from "../features/influencer-database/types";
import { ExistedOutputT } from "../types";

export const getNetworkProfileContentOutputs = async (
  owner: string,
  searchQuery: SearchQueryT,
): Promise<ExistedOutputT[]> => {
  const payload = {
    owner,
    mentions: searchQuery.mentions,
    hashtags: searchQuery.hashtags,
    timestamp: searchQuery.outputs_from,
  };
  const url = getUrlWithQuery(
    getApiUrl(ApiRoutesE.NETWORK_PROFILE_CONTENT_OUTPUTS),
    payload,
  );

  return axios.get(url).then((res) => {
    return res.data.outputs;
  });
};
