import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Divider, Input, Modal, Popconfirm } from "antd";
import { groupBy } from "lodash";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Avatar from "../../../../../components/Avatar";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import { NetworkProfileT } from "../../../../types";
import { removeListsMutation } from "../../../data-access/mutation/removeListsMutation";
import { updateListsMutation } from "../../../data-access/mutation/updateListsMutation";
import { removeList } from "../../../data-access/removeList";
import { updateList } from "../../../data-access/updateList";
import { ListT } from "../../../types";

const Wrapper = styled(FlexBox)({
  flexDirection: "column",
  justifyContent: "space-between",
  paddingTop: rem(10),
  width: "100%",
  height: `calc(100% - ${rem(20)})`,
});

const Scroll = styled(FlexBox)({
  justifyContent: "flex-start",
  flexDirection: "column",
  width: "100%",
  overflowY: "scroll",
});

const NetworkSection = styled(Paragraph)({
  padding: `${rem(16)} 0`,
});

const CloseIcon = styled(DeleteOutlined)({
  color: theme.color.iconGreyColor,

  ":hover svg": {
    color: theme.color.errorColor,
  },
});

const AvatarWrapper = styled(FlexBox)({
  width: "100%",
  padding: `${rem(6)} ${rem(6)}`,

  ":hover": {
    backgroundColor: theme.color.greyLightColor,
  },
});

const RemoveAction = styled(FlexBox)({
  cursor: "pointer",

  ":hover": {
    svg: {
      color: theme.color.primaryColor,
    },
  },
});

type ListsTableT = {
  list: ListT;
  networkProfiles: NetworkProfileT[];
  setListNetworkProfiles: (
    networkProfiles: NetworkProfileT[] | undefined,
  ) => void;
  handleOnClear: () => void;
};

const ListsTable: FC<ListsTableT> = ({
  list,
  networkProfiles,
  setListNetworkProfiles,
  handleOnClear,
}) => {
  const [name, setName] = useState<string>(list.name);
  const [renameModal, setRenameModal] = useState<boolean>(false);

  const groupedProfiles = groupBy(
    networkProfiles,
    (profile) => profile.network,
  );

  const { t } = useTranslate("brands.influencer_database.filter.lists");

  const queryClient = useQueryClient();
  const { mutate: update, isLoading } = useMutation(
    (ids: string[]) => updateList(list.id, ids, name),
    {
      onSuccess: (data) => {
        updateListsMutation(queryClient, data);
        setRenameModal(false);
      },
    },
  );
  const { mutate: deleteList } = useMutation(() => removeList(list.id), {
    onSuccess: () => {
      removeListsMutation(queryClient, list.id);
      handleOnClear();
    },
  });

  const handleRemoveProfile = (profileId: string): void => {
    const ids = networkProfiles
      .filter((profile) => profile.id !== profileId)
      .map((profile) => profile.id);
    update(ids);
  };

  const handleOnOkRename = (): void => {
    update(networkProfiles.map((profile) => profile.id));
  };

  useEffect(() => {
    setListNetworkProfiles(networkProfiles);
  }, [networkProfiles]);

  useEffect(() => {
    setName(list.name);
  }, [list]);

  return (
    <>
      <Wrapper>
        <Scroll>
          {["instagram", "tiktok", "youtube"].map((provider) => {
            if (!groupedProfiles[provider]) {
              return null;
            }

            return (
              <FlexBox
                key={provider}
                flexDirection="column"
                customWidth={CustomWidthE.full}
                alignItems="flex-start"
              >
                <NetworkSection
                  paragraph={t(provider)}
                  paragraphSize="small"
                  color={theme.color.textGreyColor}
                />
                {groupedProfiles[provider].map((profile) => (
                  <AvatarWrapper
                    key={profile.id}
                    justifyContent="space-between"
                  >
                    <FlexBox gap={rem(12)}>
                      <Avatar
                        src={profile.avatar_url}
                        name={profile.profile_id}
                      />
                      {profile.profile_id}
                    </FlexBox>
                    <RemoveAction
                      onClick={() => handleRemoveProfile(profile.id)}
                    >
                      <CloseIcon rev="" />
                    </RemoveAction>
                  </AvatarWrapper>
                ))}
              </FlexBox>
            );
          })}
        </Scroll>
        <FlexBox customWidth={CustomWidthE.full} flexDirection="column">
          <Divider />
          <FlexBox
            gap={rem(12)}
            flexDirection="column"
            customWidth={CustomWidthE.full}
          >
            <Button
              onClick={() => setRenameModal(true)}
              icon={<EditOutlined rev="" />}
              style={{ width: "100%" }}
            >
              {t("rename.button")}
            </Button>
            <Popconfirm
              title={t("delete.title")}
              onConfirm={() => deleteList()}
              okText={t("delete.ok")}
              cancelText={t("delete.cancel")}
            >
              <Button
                icon={<DeleteOutlined rev="" />}
                style={{ width: "100%" }}
              >
                {t("delete.button")}
              </Button>
            </Popconfirm>
          </FlexBox>
        </FlexBox>
      </Wrapper>

      <Modal
        title={t("rename.title")}
        open={renameModal}
        onOk={handleOnOkRename}
        okButtonProps={{
          disabled: name.length < 3 || isLoading,
          loading: isLoading,
        }}
        onCancel={() => setRenameModal(false)}
      >
        <Input
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default ListsTable;
