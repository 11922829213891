import { ArcElement, Chart } from "chart.js";
import * as React from "react";
import { FC } from "react";
import { Bar } from "react-chartjs-2";
import { roundNumber } from "../../../../../../helpers/formaters";
import { theme } from "../../../../../../styling/theme";
import { EngagementHistogramCardDataT } from "../../types";
import { getoptionsEngagementHistogram } from "./options";

Chart.register(ArcElement);

export type EngagementHistogramT = {
  data: EngagementHistogramCardDataT;
};

type getTooltipTitleT = (min?: number, max?: number) => string;
const getTooltipTitle: getTooltipTitleT = (min, max) => {
  const firstPart = "".concat(min ? `${roundNumber(min * 100, 2)}%` : "");
  const secondPart = firstPart.concat(" - ");
  return secondPart.concat(max ? `${roundNumber(max * 100, 2)}%` : "");
};

const EngagementHistogram: FC<EngagementHistogramT> = ({ data }) => {
  const { engagement_rate, items } = data;

  const itemsData = items.map((item) => ({
    [item.total]: item.min ? item.min : 0,
  }));

  const tooltipData = items.map((item) => ({
    [item.min ? item.min : 0]: getTooltipTitle(item.min, item.max),
  }));

  const values = itemsData.map((s) => Object.keys(s)).map((a) => a.join());
  const labels = itemsData.map((s) => Object.values(s)).map((a) => a.join());

  const infPositionIndex = labels.findIndex(
    (item) => parseFloat(item) >= engagement_rate,
  );

  const medianPositionIndex = items.findIndex((item) =>
    Object.keys(item).find((s) => s === "median"),
  );

  const medianValue = items[medianPositionIndex]["min"];

  const chartData = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: theme.color.greyLightColor,
        hoverBackgroundColor: theme.color.darkPrimaryColor,
        barThickness: 6,
        borderRadius: 5,
      },
    ],
  };

  const medianAndUser = {
    id: "medianAndUser",
    afterDraw(chart: any, _: any, options: any) {
      const {
        ctx,
        chartArea: { width, bottom, height },
      } = chart;

      const medianX = (width / items.length) * medianPositionIndex;
      const medianY = height + 25;

      const infPlace = (width / items.length) * infPositionIndex;
      const infX = infPlace - 30;
      const infY = height;

      ctx.strokeStyle = options.greyColor;
      ctx.beginPath();
      ctx.moveTo(medianX - 1, medianY + 1);
      ctx.lineTo(medianX - 1, medianY + 7);
      ctx.lineWidth = 3;
      ctx.stroke();

      ctx.fillStyle = theme.color.textLightGreyColor;
      ctx.textAlign = "center";
      ctx.font = "normal 11px sans-serif";
      ctx.fillText(options.medianText, medianX, medianY + 18);
      ctx.fillStyle = theme.color.textGreyColor;
      ctx.font = "bold 13px sans-serif";
      ctx.fillText(options.medianNumber, medianX, medianY + 33);

      ctx.strokeStyle = options.greyColor;
      ctx.beginPath();
      ctx.moveTo(10, bottom);
      ctx.lineTo(width + 10, bottom);
      ctx.lineWidth = 5;
      ctx.stroke();

      ctx.strokeStyle = options.blueColor;
      ctx.beginPath();
      ctx.moveTo(10, bottom);
      ctx.lineTo(infPlace, bottom);
      ctx.lineWidth = 5;
      ctx.stroke();

      ctx.fillStyle = options.blueColor;
      ctx.beginPath();
      ctx.arc(infPlace - 4, bottom, 4, 0, 10 * Math.PI);
      ctx.fill();
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(infX + 60, infY + 10);
      ctx.arcTo(infX, infY + 10, infX, infY - 20, 5);
      ctx.arcTo(infX, infY - 20, infX + 60, infY - 20, 5);
      ctx.arcTo(infX + 60, infY - 20, infX + 60, infY + 10, 5);
      ctx.arcTo(infX + 60, infY + 10, 0, infY + 10, 5);
      ctx.fill();

      ctx.fillStyle = theme.color.whiteColor;
      ctx.fillText(options.tooltipText, infPlace, infY);
    },
  };

  return (
    <Bar
      data={chartData}
      options={getoptionsEngagementHistogram(
        tooltipData,
        engagement_rate,
        medianValue,
      )}
      plugins={[medianAndUser]}
    />
  );
};

export default EngagementHistogram;
