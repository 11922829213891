import axios from "axios";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { NetworkProfileT, NetworkProviderT } from "../../types";

type PayloadT = {
  term: string;
  provider: NetworkProviderT;
  database?: string;
};

export const getNetworkProfileSuggestions = async (
  payload: PayloadT,
): Promise<NetworkProfileT[]> => {
  const url = getUrlWithQuery(
    getApiUrl(ApiRoutesE.NETWORK_PROFILE_SUGGESTIONS),
    payload,
  );
  return axios.get(url).then((res) => res.data);
};
