import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { AddedMediaGroupsStateT, PartnershipNetworkT } from "../../types";

export const setMediaGroupPendingState = (
  queryClient: QueryClient,
  pending: boolean,
  network: PartnershipNetworkT = "instagram",
): void => {
  queryClient.setQueryData<AddedMediaGroupsStateT | undefined>(
    QueryKeys.CAMPAIGN_MEDIA_GROUPS_ADDED,
    (prevState) => {
      if (prevState) {
        const newState = [{ ...prevState[0], pending, network }];

        return [...newState, ...prevState.slice(1)];
      }

      return prevState;
    },
  );
};
