import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { UserForUserManagementT } from "../types";

type createSingleUserPayloadT = {
  email: string;
};

type createUsersPayloadT = {
  users: createSingleUserPayloadT[];
};

export const createUsers = async (
  payload: createUsersPayloadT,
): Promise<{ users: UserForUserManagementT[] }> => {
  return axios
    .post(getApiUrl(ApiRoutesE.USERS), payload)
    .then((res) => res.data);
};
