import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { HostedPageT } from "../types";

export const prepareSubscriptionProposalCheckout =
  async (): Promise<HostedPageT> => {
    return axios
      .get(getApiUrl(ApiRoutesE.SUBSCRIPTIONS_PROPOSAL_PREPARE))
      .then((res) => res.data);
  };
