import styled from "@emotion/styled";
import { rem, theme } from "../styling/theme";
import FlexBox from "./FlexBox";
import { getSpacerSize, SpacerSizeT } from "./Spacer";

type PanelT = {
  marginBottom?: SpacerSizeT;
  overflow?: "hidden" | "visible";
  padding?: string;
  withShadow?: boolean;
};

const Panel = styled(FlexBox)<PanelT>(
  ({
    marginBottom = "big",
    overflow = "visible",
    padding = rem(0),
    withShadow = false,
  }) => ({
    width: "100%",
    padding,
    marginBottom: rem(getSpacerSize(marginBottom)),
    backgroundColor: theme.color.whiteColor,
    border: `1px solid ${theme.color.greyLightColor}`,
    borderRadius: rem(8),
    boxShadow: withShadow ? `0px 2px 9px rgb(0 0 0 / 10%)` : "none",
    overflow,
  }),
);

export default Panel;
