import { PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputRef,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import { createClient } from "../../../../data-access/createClient";
import { getClients } from "../../../../data-access/getClients";
import { updateClientsMutation } from "../../../../data-access/mutations/updateClientsMutation";
import Select from "../../../../features/Select";
import { designToken } from "../../../../helpers/antDesign";
import { QueryKeys } from "../../../../helpers/queryKeys";
import {
  validateClientTitle,
  validateEmptyString,
} from "../../../../helpers/validators";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { getCampaigns } from "../../data-access/getCampaigns";
import { CampaignT } from "../../types";

const MyDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = MyDatePicker;

const NewBrandTitleWrapper = styled(FlexBox)({
  width: "100%",
  gap: rem(8),
  padding: `0 ${rem(4)}`,
});

type OptionDataT = {
  label: string;
  value: string;
};

export type FormFieldsT = {
  brandId?: string;
  brandName?: string;
  title: string;
  date: [string, string] | [Date, Date];
  instagram_mentions: string[];
  tiktok_mentions: string[];
  hashtags: string[];
};

type AddNewModalT = {
  form: FormInstance<FormFieldsT>;
  newBrandInput: boolean;
  setNewBrandInput: Dispatch<SetStateAction<boolean>>;
  onFinishHandle: () => void;
  mode?: "create" | "edit";
};

const AddNewModal: FC<AddNewModalT> = ({
  form,
  newBrandInput,
  setNewBrandInput,
  onFinishHandle,
  mode = "create",
}) => {
  const [clientOptions, setClientOptions] = useState<OptionDataT[]>([]);
  const [brandSearchValue, setBrandSearchValue] = useState<string>("");

  const isFirstTime = clientOptions.length === 0;

  const [newBrandForm] = useForm();

  const newBrandInputRef = React.useRef<InputRef>(null);
  const campaignTitleInputRef = React.useRef<InputRef>(null);
  const { t } = useTranslate("brands.campaigns.form");

  const { data: clients } = useQuery(QueryKeys.CLIENTS, () => getClients(), {
    onSuccess: (data) => {
      setClientOptions(
        data.map((client) => ({
          label: client.name,
          value: client.id,
        })),
      );
    },
  });

  const { data: campaigns } = useQuery(QueryKeys.CAMPAIGNS, () =>
    getCampaigns(),
  );

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    () => createClient({ name: newBrandForm.getFieldValue("newBrandTitle") }),
    {
      onSuccess: (data) => {
        updateClientsMutation(queryClient, data.client, "create", () => {
          form.setFieldValue("brandId", data.client.id);
        });
        campaignTitleInputRef.current?.focus();
        setNewBrandInput(false);
        newBrandForm.setFieldsValue({ newBrandTitle: "" });
        form.setFieldsValue({ instagram_mentions: [] });
        form.setFieldsValue({ tiktok_mentions: [] });
      },
    },
  );

  const campaignMentions = (
    campaigns: CampaignT[],
    type: "instagram" | "tiktok",
  ): string[] => {
    return [...new Set(campaigns.flatMap((c) => c[`${type}_mentions`]))];
  };

  const onBrandChange = (clientId: string): void => {
    if (campaigns) {
      const brandCampaigns = campaigns.filter((c) => c.client.id === clientId);
      form.setFieldsValue({
        instagram_mentions: campaignMentions(brandCampaigns, "instagram"),
      });
      form.setFieldsValue({
        tiktok_mentions: campaignMentions(brandCampaigns, "tiktok"),
      });
    }
    setNewBrandInput(false);
    newBrandForm.setFieldsValue({ newBrandTitle: "" });
  };

  const validateMentions = (instagram: [], tiktok: []): Promise<any> => {
    if ((instagram && instagram.length > 0) || (tiktok && tiktok.length > 0))
      return Promise.resolve();

    return Promise.reject(t("errors.mentions"));
  };

  const onNewBrandClickHandle = (): void => {
    newBrandForm.setFieldsValue({ newBrandTitle: brandSearchValue });
    setNewBrandInput(true);
  };

  useEffect(() => {
    if (newBrandInput) newBrandInputRef.current?.focus();
  }, [newBrandInput]);

  return (
    <FlexBox
      flexDirection="column"
      alignItems="stretch"
      customWidth={CustomWidthE.full}
      gap={rem(24)}
    >
      <Form
        form={form}
        name="new-campaign"
        layout="vertical"
        requiredMark={false}
        initialValues={{}}
        onFinish={onFinishHandle}
      >
        {isFirstTime ? (
          <Form.Item
            name="brandName"
            label={isFirstTime && t("new_brand")}
            rules={[
              () => ({
                validator: (_, value) =>
                  validateClientTitle(value, clients ? clients : []),
              }),
            ]}
          >
            <Input placeholder={t("placeholders.new_brand")} />
          </Form.Item>
        ) : (
          <>
            <Form.Item
              name="brandId"
              label={t("brand")}
              rules={[{ required: !newBrandInput }]}
            >
              <Select
                showSearch
                disabled={mode === "edit"}
                onSearch={(value) => setBrandSearchValue(value)}
                optionFilterProp="label"
                options={clientOptions}
                placeholder={t("placeholders.brand")}
                onChange={onBrandChange}
                dropdownRender={(list) => (
                  <>
                    {list}
                    <Divider style={{ margin: "4px 0" }} />
                    {newBrandInput ? (
                      <Form
                        form={newBrandForm}
                        onFinish={mutate}
                        initialValues={{ newBrandTitle: brandSearchValue }}
                        style={{ width: "100%" }}
                      >
                        <NewBrandTitleWrapper>
                          <Form.Item
                            style={{ margin: 0, width: "100%" }}
                            name="newBrandTitle"
                            rules={[
                              () => ({
                                validator: (_, value) =>
                                  validateClientTitle(
                                    value,
                                    clients ? clients : [],
                                  ),
                              }),
                            ]}
                          >
                            <Input
                              ref={newBrandInputRef}
                              placeholder={t("placeholders.new_brand")}
                            />
                          </Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                          >
                            OK
                          </Button>
                        </NewBrandTitleWrapper>
                      </Form>
                    ) : (
                      <Button
                        style={{ color: designToken.colorPrimary }}
                        type="text"
                        onClick={onNewBrandClickHandle}
                        icon={<PlusOutlined />}
                      >
                        {t("add_brand")}
                      </Button>
                    )}
                  </>
                )}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="title"
          label={t("title")}
          rules={[
            { required: true },
            { min: 3 },
            () => ({
              validator: (_rule, value) =>
                validateEmptyString(value, t("errors.format")),
            }),
          ]}
        >
          <Input
            ref={campaignTitleInputRef}
            placeholder={t("placeholders.title")}
          />
        </Form.Item>
        <Form.Item name="date" label={t("date")} rules={[{ required: true }]}>
          <RangePicker
            inputReadOnly
            minDate={new Date()}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="instagram_mentions"
          label="Instagram"
          rules={[
            ({ getFieldValue }) => ({
              validator: (_rule, value) =>
                validateMentions(value, getFieldValue("tiktok_mentions")),
            }),
          ]}
        >
          <Select
            allowClear
            mode="tags"
            maxTagCount="responsive"
            notFoundContent={null}
            style={{ width: "100%" }}
            placeholder={t("placeholders.mentions")}
            options={[]}
            addonBefore="@"
            tokenSeparators={[","]}
          />
        </Form.Item>
        <Form.Item
          name="tiktok_mentions"
          label="TikTok"
          rules={[
            ({ getFieldValue }) => ({
              validator: (_rule, value) =>
                validateMentions(getFieldValue("instagram_mentions"), value),
            }),
          ]}
        >
          <Select
            allowClear
            mode="tags"
            maxTagCount="responsive"
            notFoundContent={null}
            style={{ width: "100%" }}
            placeholder={t("placeholders.mentions")}
            options={[]}
            addonBefore="@"
            tokenSeparators={[","]}
          />
        </Form.Item>
        <Form.Item name="hashtags" label={t("hashtags")}>
          <Select
            allowClear
            mode="tags"
            maxTagCount="responsive"
            notFoundContent={null}
            style={{ width: "100%" }}
            placeholder={t("placeholders.hashtags")}
            options={[]}
            addonBefore="#"
            tokenSeparators={[","]}
          />
        </Form.Item>
      </Form>
    </FlexBox>
  );
};

export default AddNewModal;
