import {
  BankOutlined,
  HomeOutlined,
  MailOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Input, InputRef } from "antd";
import { get } from "lodash";
import * as React from "react";
import { FC, RefObject, useEffect, useRef, useState } from "react";
import FlexBox from "../../../../../../components/FlexBox";
import Paragraph from "../../../../../../components/Paragraph";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../../styling/theme";
import { AttributeT } from "../../Crm";
import { BaseContactT } from "../../types";

const Wrapper = styled(FlexBox)({
  justifyContent: "flex-start",
  width: "100%",
  height: rem(48),
  gap: rem(12),
  paddingLeft: rem(8),
  borderRadius: rem(4),

  "&:hover": {
    backgroundColor: theme.color.backgroundColor,
  },
});

const LabelWrapper = styled(FlexBox)({
  width: "20%",

  [theme.media.maxSm]: {
    width: "50%",
  },
});

const IconWrapper = styled(FlexBox)({
  width: rem(32),
  height: rem(32),
  backgroundColor: theme.color.backgroundColor,
  borderRadius: rem(16),
});

const Label = styled(Paragraph)({
  textWrap: "nowrap",
});

const InputsWrapper = styled(FlexBox)({
  justifyContent: "flex-start",
  marginLeft: rem(9),
  gap: rem(12),
  width: "80%",
});

type InputItemT = {
  type: keyof BaseContactT;
  attributes: AttributeT[];
  contact: BaseContactT;
  setContact: (keyPath: AttributeT, value: string) => void;
};

const InputItem: FC<InputItemT> = ({
  type,
  attributes,
  contact,
  setContact,
}) => {
  const [border, setBorder] = useState<boolean>(false);
  const [currentInputRef, setCurrentInputRef] = useState<RefObject<InputRef>>();

  const { t } = useTranslate("brands.influencer_database.crm.contact");

  const refs: RefObject<InputRef>[] = attributes.map(() =>
    useRef<InputRef>(null),
  );
  const ref = refs[0];
  const typeValue = get(contact, type);
  const emptyAddress = {
    street: "",
    city: "",
    zip_code: "",
  };

  const setValue = (value: string, key: AttributeT): void => {
    setContact(key, value);
  };

  const onButtonClickHandle = (inputRef: RefObject<InputRef>): void => {
    setCurrentInputRef(inputRef);
    inputRef.current?.focus();
  };

  const onFocusHandle = (inputRef: RefObject<InputRef>): void => {
    setCurrentInputRef(inputRef);
    setBorder(true);
  };

  const onBlurHandle = (inputRef: RefObject<InputRef>): void => {
    setCurrentInputRef(undefined);
    inputRef.current?.blur();
    setBorder(false);
  };

  const getTypeIcon = (type: keyof BaseContactT): JSX.Element => {
    switch (type) {
      case "phone":
        return <MobileOutlined rev="" />;
      case "email":
        return <MailOutlined rev="" />;
      case "company_number":
        return <BankOutlined rev="" />;
      case "address":
        return <HomeOutlined rev="" />;
      default:
        return <HomeOutlined rev="" />;
    }
  };

  const inputWidth = (attribute: AttributeT): string => {
    switch (attribute) {
      case "address.street":
        return "35%";
      case "address.city":
        return "30%";
      case "address.zip_code":
        return "15%";
      default:
        return "50%";
    }
  };

  const getInput = (attribute: AttributeT): JSX.Element => {
    const inputRef = refs.shift();

    return (
      <FlexBox
        customWidth={inputWidth(attribute)}
        key={attribute}
        onMouseEnter={() => setBorder(true)}
        onMouseLeave={() => !currentInputRef && setBorder(false)}
      >
        {inputRef && (
          <Input
            ref={inputRef}
            value={get(contact, attribute) || ""}
            bordered={border}
            onFocus={() => onFocusHandle(inputRef)}
            onChange={(e) => setValue(e.target.value, attribute)}
            onBlur={() => onBlurHandle(inputRef)}
            onPressEnter={() => onBlurHandle(inputRef)}
          />
        )}
      </FlexBox>
    );
  };

  useEffect(() => {
    currentInputRef && currentInputRef.current?.focus();
  }, [currentInputRef]);

  return (
    <Wrapper>
      <LabelWrapper customWidth="20%" justifyContent="flex-start" gap={rem(12)}>
        <IconWrapper>{getTypeIcon(type)}</IconWrapper>
        <Label paragraph={t(type)} paragraphSize="small" />
      </LabelWrapper>
      {(typeValue === null ||
        typeValue === "" ||
        JSON.stringify(typeValue) === JSON.stringify(emptyAddress)) &&
      !currentInputRef ? (
        <Button type="link" onClick={() => onButtonClickHandle(ref)}>
          {t(`add.${type}`)}
        </Button>
      ) : (
        <InputsWrapper>
          {attributes.map((attribute) => getInput(attribute))}
        </InputsWrapper>
      )}
    </Wrapper>
  );
};

export default InputItem;
