import { ClientT } from "../entry-files/types";

export const validateEmptyString = (
  value: string | undefined,
  error: string,
): Promise<any> => {
  if (
    value === undefined ||
    value.length === 0 ||
    value.replace(/\s/g, "").length > 0
  ) {
    return Promise.resolve();
  }

  return Promise.reject(error);
};

export const validateClientTitle = (
  value: string | undefined,
  clients: ClientT[],
): Promise<any> => {
  if (!value || value.length < 3) return Promise.reject();
  if (clients.length === 0) return Promise.resolve();

  const titles = clients.map((c) => c.name.toLowerCase());
  if (titles.includes(value?.toLowerCase())) return Promise.reject();

  return Promise.resolve();
};
