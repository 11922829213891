import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { CompetitiveAnalysisT } from "../types";

export const getCompetitiveAnalysis = async (
  id: string,
): Promise<CompetitiveAnalysisT> => {
  return axios
    .get(getApiUrl(ApiRoutesE.COMPETITIVE_ANALYSES_DETAIL, id))
    .then((res) => res.data.competitive_analysis);
};
