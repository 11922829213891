type DataToQueryT = {
  [key: string]: string[] | string | number;
};

const getQuery = (data: DataToQueryT): string => {
  const arrayData = {};

  Object.keys(data).forEach((k) => {
    const item = data[k];
    const itemAsString = `${item}`;
    arrayData[k] = Array.isArray(itemAsString) ? itemAsString : [itemAsString];
  });

  return Object.keys(arrayData)
    .filter((k) => arrayData[k])
    .map(
      (k) =>
        `${encodeURIComponent(k)}=${encodeURIComponent(
          arrayData[k].join(","),
        )}`,
    )
    .join("&");
};

export const getUrlWithQuery = (url: string, data?: DataToQueryT): string => {
  if (data && Object.keys(data).length > 0) {
    const query = getQuery(data);

    return `${url}?${query}`;
  }
  return url;
};
