import styled from "@emotion/styled";
import { Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import FlexBox from "../../../../../../components/FlexBox";
import Paragraph from "../../../../../../components/Paragraph";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../../styling/theme";
import { ToastContext } from "../../../../context-providers/ToastContextProvider";
import { createContactNote } from "../../../../data-access/createContactNote";
import { createContactNoteMutation } from "../../../../data-access/mutation/createContactNoteMutation";
import { ContactT } from "../../types";
import Note from "./Note";

const Wrapper = styled(FlexBox)({
  width: "100%",
  paddingLeft: rem(8),
  flexDirection: "column",
  alignItems: "flex-start",
});

const SendWrapper = styled(FlexBox)({
  width: "100%",
  justifyContent: "flex-end",
  padding: `${rem(16)} 0`,
});

const NotesCount = styled(Paragraph)({
  marginBottom: rem(16),
});

type NotesT = {
  contact: ContactT;
  networkProfileId: string;
};

const Notes: FC<NotesT> = ({ contact, networkProfileId }) => {
  const [text, setText] = useState<string>();

  const { openToast } = useContext(ToastContext);

  const { t } = useTranslate("brands.influencer_database.crm.notes");

  const queryClient = useQueryClient();
  const { mutate: create } = useMutation(
    (text: string) => createContactNote(networkProfileId, text),
    {
      onSuccess: (data) => {
        createContactNoteMutation(queryClient, data);
        setText(undefined);
        openToast({
          message: t("messages.create_success"),
          type: "success",
        });
      },
    },
  );

  const onCreateClickHandle = (): void => {
    text && create(text);
  };

  return (
    <Wrapper>
      <TextArea
        rows={4}
        placeholder={t("placeholder")}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <SendWrapper>
        <Button type="primary" onClick={onCreateClickHandle}>
          {t("send")}
        </Button>
      </SendWrapper>
      <NotesCount
        paragraph={t("count", { count: contact.notes.length })}
        color={theme.color.textGreyColor}
        paragraphSize="small"
      />
      {contact.notes.map((note) => (
        <Note
          key={note.id}
          note={note}
          networkProfileId={networkProfileId}
          openToast={openToast}
        />
      ))}
    </Wrapper>
  );
};

export default Notes;
