import styled from "@emotion/styled";
import { Input, Radio, Space } from "antd";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import FlexBox from "../../../../../components/FlexBox";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { IconTypeE } from "../../../../../ui/icons/Icon";
import FilterContainer from "../components/FilterContainer";
import RadioItem from "../components/RadionItem";
import { CredibilityTierE } from "../credibilityHelpers";
import { CredibilityEngagementRangeT, SearchQueryT } from "../types";

const StyledInput = styled(Input)({
  width: "100px",
});

interface CredibilityT {
  searchQuery: SearchQueryT;
  setFilterSearchQuery: (query: Partial<SearchQueryT>) => void;
}

const Credibility: FC<CredibilityT> = ({
  searchQuery,
  setFilterSearchQuery,
}) => {
  const [credibilityRange, setCredibilityRange] = useState<
    CredibilityEngagementRangeT | undefined
  >();
  const [credibilityFrom, setCredibilityFrom] = useState<number | undefined>();
  const { t } = useTranslate("brands.influencer_database.filter.credibility");

  const setQuery = (
    range: CredibilityEngagementRangeT,
    from: number | undefined,
  ): void => {
    setFilterSearchQuery({
      credibility_range: range,
      credibility_from: from,
    });
  };

  const handleRadioOnChange = (value: CredibilityEngagementRangeT): void => {
    if (value === "custom" && credibilityFrom === undefined) {
      setCredibilityRange(value);
      return;
    }

    setCredibilityRange(value);
    setQuery(value, credibilityFrom);
  };

  const handleInputOnChange = (value: number): void => {
    setCredibilityFrom(value);
    setQuery("custom", value);
  };

  useEffect(() => {
    setCredibilityRange(searchQuery.credibility_range);
  }, [searchQuery]);

  return (
    <FilterContainer t={t}>
      <Radio.Group
        name="credibility"
        value={credibilityRange}
        defaultValue={undefined}
        onChange={(e) => handleRadioOnChange(e.target.value)}
      >
        <Space direction="vertical">
          <Radio value={undefined}>{t("none")}</Radio>
          <RadioItem
            value="excellent"
            paragraph={t("excellent")}
            description={t("more", { value: CredibilityTierE.excellent })}
            icon={IconTypeE.smileVery}
          />
          <RadioItem
            value="good"
            paragraph={t("good")}
            description={t("more", { value: CredibilityTierE.good })}
            icon={IconTypeE.smileSlightly}
          />
          <RadioItem
            value="average"
            paragraph={t("average")}
            description={t("more", { value: CredibilityTierE.average })}
            icon={IconTypeE.smileNeutral}
          />
          <FlexBox
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
          >
            <Radio value="custom">{t("custom")}</Radio>
            <StyledInput
              max={100}
              min={0}
              disabled={credibilityRange !== "custom"}
              onChange={(e) => handleInputOnChange(e.target.valueAsNumber)}
              addonAfter="%"
              type="number"
              value={credibilityFrom}
            />
          </FlexBox>
        </Space>
      </Radio.Group>
    </FilterContainer>
  );
};

export default Credibility;
