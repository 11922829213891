import axios from "axios";
import { useEffect, useState } from "react";
import XMLParser from "react-xml-parser";
import { ApiRoutesE, getApiUrl } from "../helpers/routes";
import { PendingFileT } from "./usePendingFile";

type PresignedFieldsT = {
  key: string;
  success_action_status: string;
  acl: string;
  policy: string;
  "x-amz-credential": string;
  "x-amz-algorithm": string;
  "x-amz-date": string;
  "x-amz-signature": string;
};

type PresignedPostT = {
  url: string;
  fields: PresignedFieldsT;
};

type PresignedPostPayloadT = {
  presigned_post: PresignedPostT;
};

type useS3UploadT = { s3fileUrl: string | null; isError?: string };

export const useS3Upload = (pendingFile: PendingFileT): useS3UploadT => {
  const [isError, setIsError] = useState<string>();
  const [s3fileUrl, setS3FileUrl] = useState<string | null>(null);

  const executeS3Upload = async (
    payload: PresignedPostPayloadT,
  ): Promise<void> => {
    const {
      presigned_post: { url, fields },
    } = payload;

    const data = new FormData();

    Object.keys(fields).forEach((key) => data.append(key, fields[key]));
    data.append("file", pendingFile.file);

    const options = {
      method: "POST",
      body: data,
    };

    try {
      const response = await fetch(url, options);
      const xmlText = await response.text();
      const xml = new XMLParser().parseFromString(xmlText);

      if (!response.ok) {
        return Promise.reject("error");
      }

      const xmlFileUrl = xml["children"].filter(
        (child: File) => child.name === "Location",
      )[0].value as string;

      setS3FileUrl(xmlFileUrl);
    } catch (e) {
      setIsError(`File upload to S3 FAILED, ${e}`);
    }
  };

  const execute = async (): Promise<void> => {
    axios
      .get(getApiUrl(ApiRoutesE.UPLOADS_NEW))
      .then((res) => executeS3Upload(res.data))
      .catch((e) => {
        setIsError(`GET Presigned post FAILED, ${e}`);
      });
  };

  useEffect(() => {
    execute();
  }, []);

  return {
    s3fileUrl,
    isError,
  };
};
