import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ApprovalRequestWithAuthorT } from "../../types";

export const sendNotificationApprovalRequest = async (
  id: string | undefined,
  payload: {
    approval_request_notification: {
      email: string;
    };
  },
): Promise<ApprovalRequestWithAuthorT> => {
  return axios
    .post(
      getApiUrl(ApiRoutesE.APPROVAL_REQUESTS_SEND_NOTIFICATION, id),
      payload,
    )
    .then((res) => res.data.approval_request);
};
