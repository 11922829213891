import axios from "axios";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ExistedOutputT } from "../types";

export const getCompetitiveAnalysisOutputs = async (
  id: string,
  per_page: number,
  handle?: string,
  sort: string = "timestamp:desc",
): Promise<ExistedOutputT[]> => {
  return axios
    .get(
      getUrlWithQuery(getApiUrl(ApiRoutesE.COMPETITIVE_ANALYSES_OUTPUTS, id), {
        owner: handle ? handle : "",
        per_page: per_page,
        sort: sort,
      }),
    )
    .then((res) => res.data.outputs);
};
