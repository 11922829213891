import { ConfigProvider, Radio as AntRadio } from "antd";
import * as React from "react";
import { FC } from "react";

type RadioButtonT = {
  active: boolean;
  disabled?: boolean;
  color?: string;
  onClickHandle?: () => void;
};

const RadioButton: FC<RadioButtonT> = ({
  active,
  disabled = false,
  color,
  onClickHandle,
}) => {
  return (
    <>
      {color ? (
        <ConfigProvider
          theme={{
            components: {
              Radio: {
                colorPrimary: color,
              },
            },
          }}
        >
          <AntRadio
            checked={active}
            disabled={disabled}
            onChange={onClickHandle}
          />
        </ConfigProvider>
      ) : (
        <AntRadio
          checked={active}
          disabled={disabled}
          onChange={onClickHandle}
        />
      )}
    </>
  );
};

export default RadioButton;
