import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { CampaignT } from "../../types";

export const updateCampaignMutation = (
  queryClient: QueryClient,
  data: CampaignT,
  afterMutationHandle?: () => void,
): void => {
  queryClient.setQueryData<CampaignT>(
    [QueryKeys.CAMPAIGN_DETAIL, data.id],
    () => {
      return data;
    },
  );

  afterMutationHandle && afterMutationHandle();
};
