import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { SubscriptionPayloadT, SubscriptionT } from "../../types";

export const createSubscription = async (
  payload: SubscriptionPayloadT,
): Promise<SubscriptionT> => {
  return axios
    .post(getApiUrl(ApiRoutesE.SUBSCRIPTIONS_SUBSCRIPTIONS), payload)
    .then((res) => res.data.subscription);
};
