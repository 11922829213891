import styled from "@emotion/styled";
import { Button } from "antd";
import * as React from "react";
import { FC } from "react";
import { useRouteError } from "react-router-dom";
import Container from "../../../components/Container";
import FlexBox from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import { rem } from "../../../styling/theme";
import { ElipsesBg } from "../../../ui/icons/CustomIcons";
import { NavigationKeyE } from "../router";

const src = require("../../../assets/doggo.png");

const Wrapper = styled(FlexBox)({
  height: "100dvh",
  alignItems: "center",
});

const StyledRightElipsesBg = styled(ElipsesBg)({
  position: "absolute",
  top: 0,
  right: 0,
  zIndex: -1,
});

const StyledLeftElipsesBg = styled(ElipsesBg)({
  position: "absolute",
  bottom: 0,
  left: 0,
  zIndex: -1,
  rotate: "180deg",
});

const StyledParagraph = styled(Paragraph)({
  fontSize: rem(24),
});

const Img = styled.img({
  width: rem(358),
  height: rem(323),
});

const ErrorPage: FC = () => {
  const error = useRouteError();
  let errorMessage = "An unexpected error occurred";
  let statusCode = 500;

  if (error instanceof Response) {
    errorMessage = error.statusText;
    statusCode = error.status;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  return (
    <Wrapper>
      <StyledRightElipsesBg />
      <StyledLeftElipsesBg />
      <Container noMargin={true} isRelative={true}>
        <FlexBox>
          <FlexBox flexDirection="column" gap={rem(24)}>
            <FlexBox flexDirection="column" gap={rem(8)}>
              <StyledParagraph paragraph="404" />
              <StyledParagraph
                paragraph="Ooopsies... Nothing's here 😔"
                fontWeight={600}
              />
            </FlexBox>
            <Paragraph paragraph="No good treats here, just an empty bowl. Let's find something else!" />
            <Button href={NavigationKeyE.Database} type="primary">
              Take me home
            </Button>
          </FlexBox>
          <FlexBox>
            <Img src={src} />
          </FlexBox>
        </FlexBox>
      </Container>
    </Wrapper>
  );
};

export default ErrorPage;
