import * as React from "react";
import { FC } from "react";
import Icon, { IconTypeE } from "../ui/icons/Icon";
import FlexBox from "./FlexBox";
import Paragraph from "./Paragraph";
import Spacer from "./Spacer";

type IconTextT = {
  icon: IconTypeE;
  paragraph: string;
};

const IconText: FC<IconTextT> = ({ icon, paragraph }) => {
  return (
    <FlexBox flexDirection="row" justifyContent="flex-start">
      <Icon icon={icon} />
      <Spacer size={"micro"} />
      <Paragraph paragraph={paragraph} paragraphSize="small" />
    </FlexBox>
  );
};

export default IconText;
