import * as React from "react";
import { FC } from "react";
import {
  SubscriptionEntitlementFeaturesT,
  SubscriptionEntitlementT,
} from "../../../types";
import Entitlement from "./Entitlement";

type SubscriptionPlanEntitlementsT = {
  entitlements: SubscriptionEntitlementT[];
};

const SubscriptionPlanEntitlements: FC<SubscriptionPlanEntitlementsT> = ({
  entitlements,
}) => {
  const showEntitlement = (
    featureId: SubscriptionEntitlementFeaturesT,
    showValue = true,
    translationValue = false,
  ): JSX.Element | undefined => {
    const entitlement = entitlements.find((e) => e.feature_id === featureId);

    if (entitlement) {
      return (
        <Entitlement
          entitlement={entitlement}
          showValue={showValue}
          translationValue={translationValue}
        />
      );
    }

    return undefined;
  };

  return (
    <>
      {showEntitlement("users")}
      {showEntitlement("marketplace-access", false)}
      {showEntitlement("reporting-influencers")}
      {showEntitlement("overlap-analyses")}
      {showEntitlement("competitive-analyses")}
      {showEntitlement("support", false, true)}
    </>
  );
};

export default SubscriptionPlanEntitlements;
