import styled from "@emotion/styled";
import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import FlexBox from "../../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../../components/Heading";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import PendingFileUpload from "../../../../../features/files-upload/PendingFileUpload";
import { useMutateMedia } from "../../../../../hooks/useMutateMedia";
import {
  FileStatusTypeE,
  PendingFileT,
} from "../../../../../hooks/usePendingFile";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import { MediaGroupsHelperContext } from "../../../context-providers/MediaGroupsHelperContextProvider";
import LineLoadingIndicator from "../../../ui/LineLoadingIndicator";

const Wrapper = styled(FlexBox)({
  position: "absolute",
  padding: rem(48),
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: theme.color.whiteColor,
  opacity: 0.9,
  zIndex: 2,
});

type UploadInProgressT = {
  mediaId: string;
  visible: boolean;
  pendingFiles: PendingFileT[];
  setPendingFiles: Dispatch<SetStateAction<PendingFileT[]>>;
  liveAddedMedia?: boolean;
};

const UploadInProgress: FC<UploadInProgressT> = ({
  mediaId,
  visible,
  pendingFiles,
  setPendingFiles,
  liveAddedMedia,
}) => {
  const [firtsLoad, setFirstLoad] = useState(true);

  const { addedQueryStack, queryStack } = useContext(MediaGroupsHelperContext);
  const { t } = useTranslate("brands.campaign.media_groups");
  const { mutateMedia } = useMutateMedia(
    mediaId,
    liveAddedMedia ? addedQueryStack : queryStack,
    () => {
      setPendingFiles([]);
    },
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (!firtsLoad && pendingFiles.length > 0) {
        const uploaded = pendingFiles
          .map((file) => file.status === FileStatusTypeE.uploaded && file.link)
          .filter((url) => url) as string[];

        if (pendingFiles.length === uploaded.length) {
          mutateMedia({ image_urls: uploaded });
        }
      }

      setFirstLoad(false);
    }

    return () => {
      mounted = false;
    };
  }, [pendingFiles]);

  if (!visible) {
    return null;
  }

  return (
    <Wrapper
      justifyContent="center"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Heading
        heading={t("upload")}
        headingType={HeadingTypeT.h4}
        spaceBottom="small"
      />
      <LineLoadingIndicator visible={true} />
      <Spacer size={"mini"} direction="vertical" />
      <Paragraph
        paragraph={t("upload_description")}
        color={theme.color.textGreyDarkColor}
      />
      {pendingFiles.map((file) => (
        <PendingFileUpload
          key={file.id}
          pendingFile={file}
          setPendingFiles={setPendingFiles}
        />
      ))}
    </Wrapper>
  );
};

export default UploadInProgress;
