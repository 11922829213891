import { QuestionCircleTwoTone } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Tooltip } from "antd";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { rem, theme } from "../../../../../styling/theme";

const Wrapper = styled(FlexBox)({
  width: "100%",
});

const TitleWrapper = styled(FlexBox)({
  gap: rem(8),
  paddingBottom: rem(12),
});

type FilterContainerT = {
  t: (scope: I18n.Scope, options?: I18n.TranslateOptions | undefined) => string;
  tooltip?: boolean;
};

const FilterContainer: FC<FilterContainerT> = ({
  t,
  tooltip = true,
  children,
}) => {
  return (
    <Wrapper flexDirection="column" alignItems="flex-start">
      <TitleWrapper flexDirection="row" alignItems="center">
        <Paragraph
          paragraph={t("title")}
          paragraphSize="small"
          fontWeight={600}
        />
        {tooltip && (
          <Tooltip title={t("tooltip")} placement="top">
            <QuestionCircleTwoTone
              rev=""
              twoToneColor={theme.color.textLightGreyColor}
            />
          </Tooltip>
        )}
      </TitleWrapper>
      {children}
    </Wrapper>
  );
};

export default FilterContainer;
