import { theme } from "../../../../../../styling/theme";

export const getOptionsAudienceReachibility = (values: string[]) => {
  return {
    responsive: true,
    layout: {
      padding: {
        top: 20,
        right: 10,
      },
    },
    plugins: {
      customTicks: {
        borderColor: theme.color.greyColor,
        borderDash: [4, 8],
        dataValues: values,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: ({ formattedValue }: any) => `${formattedValue}%`,
        },
      },
    },
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          display: false,
          callback: (val: any, index: any) => (index % 2 === 0 ? val : null),
        },
        grid: {
          color: theme.color.greyLightColor,
          drawBorder: false,
        },
      },
      x: {
        ticks: {
          font: { weight: "600", size: 14 },
          color: theme.color.textGreyColor,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
  };
};

export const customTicksAudienceReachibility = {
  id: "customTicks",
  beforeDraw(chart: any, _: any, options: any) {
    const {
      ctx,
      chartArea: { top, width, height },
    } = chart;

    const values = options.dataValues;
    const part = width / values.length / 2;

    ctx.setLineDash(options.borderDash);
    ctx.strokeStyle = options.borderColor;
    ctx.fillStyle = theme.color.textLightGreyColor;
    ctx.textAlign = "center";
    ctx.font = "bold 14px sans-serif";
    ctx.beginPath();

    values.forEach((item: any, index: number) => {
      const currentPart = part * (index * 2 + 1);
      ctx.moveTo(currentPart + 8, top);
      ctx.lineTo(currentPart + 8, height + 20);
      ctx.fillText(item, currentPart + 8, 13);
    });

    ctx.stroke();
  },
};
