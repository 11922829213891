ImageCropper = function (elementId, aspectRatio = 4 / 3) {
  let $image = $(elementId);

  $image.cropper({
    viewMode: 0,
    aspectRatio,
    movable: true,
    rotatable: true,
    scaleable: true,
    zoomable: true,
    zoomOnTouch: true,
    zoomOnWheel: true,
    minContainerWidth: 400,
    minContainerHeight: 200,
    cropBoxResizable: true,
    autoCropArea: 1,
  });
};

window.fileUploadCrop = function (element) {
  if (window.pendingList === undefined) {
    window.pendingList = {};
  }
  let fileInput = $(element);
  window.pendingList[fileInput[0].id] = [];
  fileInput.fileupload({
    fileInput: fileInput,
    url: fileInput.data("url"),
    type: "POST",
    autoUpload: fileInput.data("autoUpload"),
    replaceFileInput: true,
    formData: fileInput.data("formData"),
    paramName: "file", // S3 does not like nested name fields i.e. name="user[avatar_url]"
    dataType: "XML", // S3 returns XML if success_action_status is set to 201
    uploadTemplateId: fileInput.data("scriptId"),
    downloadTemplateId: null,
    filesContainer: $(fileInput).closest(".container").find(".uploaded_files"),
    singleFileUploads: true,
    // previewThumbnail: false
    maxFileSize: 10 * 1000 * 1000, // 10MB
    edit: fileInput.data("edit"),
    add: function (e, data) {
      $.each(data.files, function (index, file) {
        //set new name here
        var newname = data.files[index].name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "_")
          .replace(/[\s\+]/g, "_");
        Object.defineProperty(data.files[index], "name", {
          value: newname,
        });
      });
      $.blueimp.fileupload.prototype.options.add.call(this, e, data);
    },
    done: function (e, data) {
      // extract key and generate URL from response
      let url = $(data.jqXHR.responseXML)
        .find("Location")
        .text()
        .replace(new RegExp("%2F", "g"), "/");
      // let name = url.split('/').pop();
      let context = $(data.context);
      //context.find('.preview').append(url);
      context.find(".cropper-start").remove();
      context.find(".cropper-edit").remove();
      let new_hidden = data.filesContainer
        .closest(".container")
        .find(".file_hidden_field")
        .first()
        .clone();
      context.find(".preview").append(new_hidden);
      new_hidden.val(url);
      new_hidden.removeAttr("disabled");
    },
    submit: function (e, data) {
      let index = $(data.context)
        .closest(".container")
        .find("table tr")
        .index($(data.context)[0]);
      data.files = [window.pendingList[fileInput[0].id][index]];
    },
  });

  fileInput.on("fileuploadadd", (e, data) => {
    if (!data.fileInput) {
      //TODO: preparing to Drag and Drop!
      console.error("WRANING: undefined inputs, Files not found!");
    }
    window.pendingList[data.fileInput[0].id].push(data.files[0]);
  });
};

$(document).on("turbolinks:load", function () {
  $(".file_upload_crop").each(function () {
    fileUploadCrop(this);
  });
});

$(document).on("click", ".cropper-remove", function () {
  let _that = $(this);
  let tr = _that.closest("tr");
  let index = _that.closest(".container").find("table tr").index(tr[0]);
  $("#campaign_image_url").val("");
  window.pendingList[
    _that.closest(".container").find(".file_upload_crop")[0].id
  ].splice(index, 1);
  tr.remove();
  return false;
});

$(document).on("click", ".cropper-apply", function () {
  let _that = $(this);
  _that.hide();
  let tr = _that.closest("tr");
  tr.find(".cropper-start").show();
  tr.find(".cropper-edit").show();
  let index = _that.closest(".container").find("table tr").index(tr[0]);
  let canvas = tr.find(".preview > img");
  let cropped_canvas = canvas.cropper("getCroppedCanvas");

  canvas.cropper("destroy");

  let img = new Image();
  cropped_canvas.toBlob(function (blob) {
    img.src = URL.createObjectURL(blob);
  });
  $(canvas).replaceWith(img);
  cropped_canvas.toBlob(function (blob) {
    blob.name = tr.data("filename");
    window.pendingList[
      _that.closest(".container").find(".file_upload_crop")[0].id
    ][index] = blob;
    _that.closest("tr").find(".cropper-start").trigger("click");
  });
});

$(document).on("click", ".cropper-edit", function () {
  var _that = $(this);
  let tr = _that.closest("tr");
  let table = _that.closest("table")[0];
  let index = _that.closest(".container").find("table tr").index(tr[0]);
  let file =
    window.pendingList[
      _that.closest(".container").find(".file_upload_crop")[0].id
    ][index];

  const reader = new FileReader();
  let preview = tr.find(".preview");
  tr.find(".cropper-apply").show();
  tr.find(".cropper-start").hide();
  tr.find(".cropper-edit").hide();

  reader.onload = (event) => {
    if (event.target.result) {
      var img = new Image();
      img.src = event.target.result;

      const { aspectRatio } = table.dataset;

      preview.html(img);
      new ImageCropper(img, parseFloat(aspectRatio));
    }
  };
  reader.readAsDataURL(file);
});
