import React, { FC, useContext } from "react";
import { PermissionContext } from "../context-providers/PermissionContextProvider";
import { PermissionT } from "../entry-files/types";

type Props = {
  to: PermissionT;
  fallback?: JSX.Element | string;
};

const Restricted: FC<Props> = ({ to, fallback, children }) => {
  const { isAllowedTo } = useContext(PermissionContext);

  if (isAllowedTo(to)) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};

export default Restricted;
