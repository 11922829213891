import * as React from "react";
import { FC, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import CarouselLightBoxArrow from "../../ui/carousel/CarouselLightBoxArrow";
import { ClearCarouselWrapper } from "../../ui/carousel/ClearCarouselWrapper";
import Icon, { IconTypeE } from "../../ui/icons/Icon";
import Button, { ButtonSizeE, ButtonTypeE } from "../Button";
import FlexBox, { CustomWidthE } from "../FlexBox";
import Paragraph from "../Paragraph";
import Spacer from "../Spacer";
import LightBoxItem from "./LightBoxItem";

export type LightBoxStateT = {
  file_urls: string[];
  currentIndex?: number;
};

type LightBoxModalT = {
  lightBoxState: LightBoxStateT;
  close: () => void;
};

const LightBoxModal: FC<LightBoxModalT> = ({ lightBoxState, close }) => {
  const { file_urls, currentIndex = 0 } = lightBoxState;
  const [currentImage, setCurrentImage] = useState<number>(currentIndex);

  return (
    <FlexBox flexDirection="column">
      <FlexBox justifyContent="space-between" customWidth={CustomWidthE.full}>
        <Paragraph paragraph={`${currentImage + 1} / ${file_urls.length}`} />
        <FlexBox customWidth={CustomWidthE.quarter} justifyContent="flex-end">
          <Button
            onClick={() => {
              close();
            }}
            type={ButtonTypeE.clear}
            size={ButtonSizeE.micro}
          >
            <Icon icon={IconTypeE.cross} size="mini" />
          </Button>
        </FlexBox>
      </FlexBox>
      <Spacer direction="vertical" size="micro" />
      <ClearCarouselWrapper flexDirection="column">
        <Carousel
          showArrows={file_urls.length > 1}
          showThumbs={false}
          emulateTouch={true}
          useKeyboardArrows={true}
          selectedItem={currentImage}
          onChange={(index) => setCurrentImage(index)}
          renderArrowPrev={(clickHandler, hasPrev) => (
            <CarouselLightBoxArrow
              disable={hasPrev}
              visible={file_urls.length > 1}
              onClickHandle={() => {
                clickHandler();
              }}
              side="left"
            />
          )}
          renderArrowNext={(clickHandler, hasNext) => (
            <CarouselLightBoxArrow
              disable={hasNext}
              visible={file_urls.length > 1}
              onClickHandle={() => {
                clickHandler();
              }}
              side="right"
            />
          )}
        >
          {file_urls.map((file_url) => (
            <LightBoxItem key={file_url} url={file_url} />
          ))}
        </Carousel>
      </ClearCarouselWrapper>
    </FlexBox>
  );
};

export default LightBoxModal;
