import { capitalizeFirstLetter } from "../../../../../helpers/formaters";
import { NetworkProfileT } from "../../../../types";

const alphabets = Array.from({ length: 26 }, (_, i) =>
  String.fromCharCode(97 + i),
);
const urlPrefix =
  "https://reportee-production.s3.eu-central-1.amazonaws.com/public/example/";

const getRandomElements = (arr: string[], count: number): string[] => {
  return arr.sort(() => Math.random() - 0.5).slice(0, count);
};

const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const demoProfiles = (): NetworkProfileT[] => {
  const keys = Array.from({ length: 15 }, (_, i) => (i + 1).toString());
  const images = keys.map((key) => `${urlPrefix}${key}.jpeg`);

  const profiles: NetworkProfileT[] = [];

  keys.map((key) => {
    const handle = getRandomElements(alphabets, getRandomNumber(5, 11)).join(
      "",
    );
    const name = capitalizeFirstLetter(handle);

    profiles.push({
      id: key,
      profile_id: handle,
      display_name: `${name} ${name}`,
      provider: "instagram",
      network: "instagram",
      credibility: getRandomNumber(65, 99),
      avatar_url: `${urlPrefix}${key}.jpeg`,
      content_search: undefined,
      top_post_urls: getRandomElements(images, 3),
      main_age_group: {
        percentage: getRandomNumber(0.4, 0.8),
        value: getRandomElements(["18", "25", "35"], 1)[0],
      },
      main_country: { percentage: getRandomNumber(60, 90), value: "CZ" },
      main_gender: {
        percentage: getRandomNumber(60, 90),
        value: getRandomElements(["male", "female"], 1)[0] as "male" | "female",
      },
      people_count: getRandomNumber(25000, 400000),
    });
  });

  return profiles;
};
