import axios from "axios";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { HostedPageT } from "../types";

export const generateSubscriptionCheckout = async (
  priceId: string,
): Promise<HostedPageT> => {
  return axios
    .get(
      getUrlWithQuery(getApiUrl(ApiRoutesE.SUBSCRIPTIONS_SUBSCRIPTIONS_NEW), {
        price_id: priceId,
      }),
    )
    .then((res) => res.data);
};
