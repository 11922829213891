import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table as AntTable, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Panel from "../../../../components/Panel";
import Paragraph from "../../../../components/Paragraph";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { designToken } from "../../../../helpers/antDesign";
import { getDateTime } from "../../../../helpers/date";
import { useTranslate } from "../../../../hooks/useTranslate";
import { theme } from "../../../../styling/theme";
import { ToastContext } from "../../context-providers/ToastContextProvider";
import { removeUserMutation } from "../../data-access/mutation/removeUserMutation";
import { reinviteUser } from "../../data-access/reinviteUser";
import { removeUser } from "../../data-access/removeUser";
import { UserForUserManagementT } from "../../types";

interface DataType {
  key: number;
  user: UserForUserManagementT;
}

type TableT = {
  dataIsLoading: boolean;
  users?: UserForUserManagementT[];
};

const Table: FC<TableT> = ({ dataIsLoading, users }) => {
  const [reinviteId, setReinviteId] = useState<number | null>(null);

  const { openToast } = useContext(ToastContext);
  const { session } = useContext(ApplicationContext);

  const { t } = useTranslate("brands.users.index");

  const queryClient = useQueryClient();
  const { mutate: reinvite, isLoading: isReinviteLoading } = useMutation(
    (id: number) => {
      setReinviteId(id);
      return reinviteUser(id);
    },
    {
      onSuccess: () => {
        setReinviteId(null);
        openToast({
          type: "success",
          message: t("resend_invitation_success"),
        });
      },
    },
  );

  const { mutate: destroy } = useMutation((id: number) => removeUser(id), {
    onSuccess: (_, id) => {
      removeUserMutation(queryClient, id);
      openToast({
        type: "success",
        message: t("remove_success"),
      });
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: t("table.name"),
      dataIndex: "name",
      render: (_, record) => {
        const userNameText = `${record.user.name || ""} ${
          record.user.email === session?.email ? t("you_tag") : ""
        }`;

        return (
          <>
            <span>{record.user.email}</span>
            <Paragraph
              paragraph={userNameText}
              truncate="css"
              color={theme.color.textGreyColor}
              cursor="inherit"
            />
          </>
        );
      },
    },
    {
      title: t("table.invited_at"),
      dataIndex: "invited_at",
      render: (_, record) =>
        record.user.invitation_sent_at
          ? getDateTime(record.user.invitation_sent_at, "shortDate")
          : "-",
    },
    {
      title: t("table.last_request_at"),
      dataIndex: "last_request_at",
      render: (_, record) =>
        record.user.last_request_at
          ? getDateTime(record.user.last_request_at, "shortDate")
          : "-",
    },
    {
      title: t("table.status"),
      dataIndex: "status",
      render: (_, record) => {
        return (
          <Tag color={record.user.status === "active" ? "success" : "warning"}>
            {t(`status.${record.user.status}`)}
          </Tag>
        );
      },
    },
    {
      title: t("table.action"),
      dataIndex: "action",
      width: "20%",
      render: (_, record) => {
        if (record.user.status === "pending") {
          return (
            <FlexBox justifyContent="space-between">
              <Button
                type="link"
                onClick={() => reinvite(record.user.id)}
                loading={isReinviteLoading && reinviteId === record.user.id}
                style={{ paddingLeft: 0 }}
              >
                {t("resend_invitation")}
              </Button>
              <Popconfirm
                title={t("delete_modal.delete_confirmation")}
                onConfirm={() => destroy(record.user.id)}
                okText={t("delete_modal.ok")}
                cancelText={t("delete_modal.cancel")}
              >
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  style={{ color: designToken.colorTextTertiary }}
                />
              </Popconfirm>
            </FlexBox>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const getTableContent = (): JSX.Element => {
    if (users && users.length > 0) {
      const data: DataType[] = users?.map((user) => ({
        key: user.id,
        user: user,
      }));

      return (
        <AntTable
          style={{ width: "100%" }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      );
    }

    return (
      <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
        <PlaceholderLoader
          type={PlaceholdersTypeE.table_item}
          count={5}
          direction="column"
        />
      </FlexBox>
    );
  };

  return (
    <>
      <Panel flexDirection="column" position="relative">
        {getTableContent()}
      </Panel>
    </>
  );
};

export default Table;
