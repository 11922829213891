import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${rem(12)};
  padding: ${rem(12)} ${rem(60)} ${rem(12)} ${rem(20)};
  flex-wrap: wrap;
  ${theme.media.lg} {
    flex-wrap: nowrap;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  min-width: ${rem(24)};
  min-height: ${rem(24)};
  justify-content: center;
  background-color: #f2c94c;
  color: ${theme.color.darkPrimaryColor};
  font-weight: 500;
  font-size: ${rem(14)};
  border-radius: 50%;
  margin-right: ${rem(12)};
  ${theme.media.lg} {
    min-width: ${rem(36)};
    min-height: ${rem(36)};
    margin-right: ${rem(8)};
  }
`;

const Link = styled.div`
  background-color: transparent;
  border: none;
  appearance: none;
  color: ${theme.color.darkPrimaryColor};
  font-weight: 600;
  font-size: ${rem(14)};
  cursor: pointer;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  ${theme.media.maxSm} {
    display: none;
  }
`;

const Text = styled.p`
  font-size: ${rem(16)};
  font-weight: 500;
  line-height: ${rem(24)};
  color: ${theme.color.bwBlack};
  margin: 0;
`;

interface InfoHeaderProps {
  stepIndex?: number;
}

const InfoHeader: FC<InfoHeaderProps> = ({ stepIndex, children }) => {
  const { t } = useTranslate("influencer_root.approval_request.tutorials");

  return (
    <Wrapper>
      <Header>
        {stepIndex && <Step>{stepIndex}</Step>}
        <Text>{children}</Text>
      </Header>
      <Link>{stepIndex ? t("how_to") : t("show")}</Link>
    </Wrapper>
  );
};

export default InfoHeader;
