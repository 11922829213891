import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { MediaKindT, MediaT } from "../types";

type createMediaPayloadT = {
  media: {
    media_group_id: string;
    kind: MediaKindT;
    url?: string;
    likes?: number;
    comments?: number;
    reach?: number;
    impressions?: number;
    swipeups?: number;
    sticker_taps?: number;
    image_urls?: string[];
    published_at?: string;
  };
};

export const createMedia = async (
  payload: createMediaPayloadT,
): Promise<MediaT> => {
  return axios
    .post(getApiUrl(ApiRoutesE.MEDIAS), payload)
    .then((res) => res.data.media);
};
