import styled from "@emotion/styled";
import { Tooltip } from "antd";
import * as React from "react";
import { FC } from "react";
import { designToken } from "../helpers/antDesign";
import { rem } from "../styling/theme";
import FlexBox from "./FlexBox";

const StyledTooltip = styled(Tooltip)({
  color: designToken.colorPrimary,
  cursor: "default",
});

type ArrayTagsT = {
  array: string[];
  prefix: string;
};

const ArrayTags: FC<ArrayTagsT> = ({ array, prefix }) => {
  if (array.length === 0) return <>-</>;

  const toStr = (array: string[]): string =>
    `${prefix}${array?.join(`, ${prefix}`)}`;

  if (array.length < 3) return <>{toStr(array)}</>;

  return (
    <FlexBox justifyContent="flex-start" gap={rem(4)}>
      {`${toStr(array.slice(0, 2))},`}
      <StyledTooltip title={toStr(array.slice(2, 100))}>
        <span
          style={{ textDecoration: "underline", textDecorationStyle: "dotted" }}
        >{`+${array.length - 2} more`}</span>
      </StyledTooltip>
    </FlexBox>
  );
};

export default ArrayTags;
