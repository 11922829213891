import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../components/FlexBox";
import { rem, theme } from "../../../styling/theme";

const Wrapper = styled.div`
  background-color: ${theme.color.primaryColor};
  border-radius: ${rem(8)};
  color: ${theme.color.whiteColor};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  min-height: ${rem(290)};
  margin-bottom: ${rem(20)};
  overflow: hidden;

  ${theme.media.lg} {
    justify-content: space-between;
    flex-direction: row;
    min-height: ${rem(360)};
  }
`;

const TextWrapper = styled.div`
  padding: ${rem(25)};

  ${theme.media.lg} {
    width: 60%;
    padding: ${rem(40)} ${rem(36)} ${rem(44)} ${rem(40)};
  }
`;

const ImgWrapper = styled(FlexBox)<{ centered: boolean }>(({ centered }) => ({
  position: "absolute",
  top: rem(130),
  right: rem(-22),
  alignItems: "center",
  justifyContent: "center",
  width: centered ? "40%" : "auto",

  [theme.media.lg]: {
    position: "relative",
    top: "auto",
    right: "auto",
  },
}));

const IpadImg = styled.img`
  margin-top: ${rem(40)};
  width: 100%;
  height: ${rem(242)};

  ${theme.media.lg} {
    width: auto;
    height: auto;
  }
`;

const MedalImg = styled.img`
  height: auto;
  height: ${rem(80)};
  margin-top: ${rem(10)};

  ${theme.media.lg} {
    height: ${rem(242)};
    width: auto;
    height: auto;
  }
`;

const ImgCircle1 = styled.img`
  display: none;
  ${theme.media.lg} {
    display: block;
    position: absolute;
    right: 335px;
    top: 200px;
  }
`;
const ImgCircle2 = styled.img`
  display: none;
  ${theme.media.lg} {
    position: absolute;
    display: block;
    right: 112px;
    top: 20px;
    width: 167px;
    height: auto;
  }
`;
const ImgCircle3 = styled.img`
  display: none;
  ${theme.media.lg} {
    display: block;
    position: absolute;
    right: 50px;
    bottom: 0;
  }
`;

const Circle1 = require("../../../assets/fb-approval/icons/circle126.png");
const Circle2 = require("../../../assets/fb-approval/icons/circle167.png");
const Circle3 = require("../../../assets/fb-approval/icons/circle185.png");
const Ipad = require("../../../assets/fb-approval/icons/ipad-layer-1.png");
const Medal = require("../../../assets/fb-approval/icons/medal.png");

export type LargeImageT = "ipad" | "medal";

const LargeColourWrapper: FC<{ image?: LargeImageT }> = ({
  image = "ipad",
  children,
}) => {
  return (
    <Wrapper>
      <TextWrapper>{children}</TextWrapper>
      <ImgWrapper centered={image === "medal"}>
        {image === "ipad" ? (
          <IpadImg src={Ipad} alt="Ipad" />
        ) : (
          <MedalImg src={Medal} alt="Medal" />
        )}
        <ImgCircle1 src={Circle1} alt="" />
        <ImgCircle2 src={Circle2} alt="" />
        <ImgCircle3 src={Circle3} alt="" />
      </ImgWrapper>
    </Wrapper>
  );
};

export default LargeColourWrapper;
