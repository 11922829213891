import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { MediaGroupT } from "../types";

type MergeMediaGroupsPropsT = {
  id: string;
  payload: {
    media_group_merge: {
      source_id: string;
      target_id: string;
    };
  };
};

type UpdatedMediaGroupPayloadT = {
  mediaGroup: MediaGroupT;
};

type mergeMediaGroupsT = (
  props: MergeMediaGroupsPropsT,
) => Promise<UpdatedMediaGroupPayloadT>;

export const mergeMediaGroups: mergeMediaGroupsT = async ({ id, payload }) => {
  return axios
    .post(getApiUrl(ApiRoutesE.CAMPAIGN_MEDIA_GROUPS_MERGE, id), payload)
    .then((res) => res.data);
};
