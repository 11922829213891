import styled from "@emotion/styled";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import React, { FC, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import Spacer from "../../../components/Spacer";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { rem, theme } from "../../../styling/theme";
import Icon, { IconTypeE } from "../../../ui/icons/Icon";

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #02061f33;
  overflow-y: auto;
  padding: ${rem(50)} ${rem(16)} ${rem(40)};
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  z-index: 99999;

  ${theme.media.lg} {
    padding: ${rem(32)};
  }
`;

const ModalWrapper = styled.div<{ size: SizeT }>(({ size }) => ({
  background: theme.color.whiteColor,
  margin: "auto",
  position: "relative",
  zIndex: 41,
  borderRadius: "12px",
  width: "100%",
  padding: rem(20),
  maxWidth: size === "default" ? "1100px" : "515px",

  [theme.media.lg]: {
    width: "100%",
    padding: size === "default" ? rem(80) : rem(63),
  },
}));

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  appearance: none;
  color: #02061f;
  padding: ${rem(8)};
  cursor: pointer;
  font-size: 0;
  transition: color 300ms;

  svg {
    fill: currentColor;
  }

  &:hover {
    color: ${theme.color.darkPrimaryColor};
  }
`;

const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${rem(16)};
`;

const Title = styled.h2`
  color: #02061f;
  font-size: ${rem(22)};
  font-weight: 500;
  margin: 0;
`;

const ModalContent = styled.div<{ size: SizeT }>(({ size }) => ({
  marginTop: size === "default" ? rem(32) : 0,
}));

type SizeT = "default" | "small";

export interface ModalProps {
  isOpen: boolean;
  headerless?: boolean;
  header?: React.ReactNode | string;
  onClose: () => void;
  size?: SizeT;
}

export const isClient = typeof window !== "undefined";

const Modal: FC<ModalProps> = ({
  isOpen,
  headerless = false,
  header,
  onClose,
  size = "default",
  children,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  // Close the modal if clicked outside
  const containerRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside([ref], onClose);

  useEffect(() => {
    if (containerRef.current !== null) {
      isOpen
        ? disableBodyScroll(containerRef.current as HTMLElement, {
            reserveScrollBarGap: true,
          })
        : enableBodyScroll(containerRef.current as HTMLElement);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen]);

  const el = isClient && document.getElementById("portal");

  return (
    (isOpen &&
      el &&
      createPortal(
        <Overlay ref={containerRef}>
          <ModalWrapper size={size} ref={ref}>
            {!headerless && (
              <ModalHeader>
                {header ? (
                  typeof header === "string" ? (
                    <Title>{header}</Title>
                  ) : (
                    header
                  )
                ) : (
                  <Spacer />
                )}
                <CloseButton
                  onClick={onClose}
                  aria-label="close modal"
                  title="close modal"
                >
                  <Icon icon={IconTypeE.cross} size={16} />
                </CloseButton>
              </ModalHeader>
            )}
            <ModalContent size={size}>{children}</ModalContent>
          </ModalWrapper>
        </Overlay>,
        el,
      )) ||
    null
  );
};

export default Modal;
