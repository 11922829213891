import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${rem(32)};
`;

export const Heading = styled.h1`
  color: ${theme.color.darkPrimaryColor};
  font-weight: 700;
  font-size: ${rem(20)};
  line-height: ${rem(20)};

  ${theme.media.lg} {
    font-weight: 600;
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  }
`;

export const HeadingLink = styled.a`
  color: ${theme.color.darkPrimaryColor};
  border-bottom: 1px solid ${theme.color.darkPrimaryColor};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

interface HeaderT {
  title: string;
}

const Header: FC<HeaderT> = ({ title }) => {
  const { t } = useTranslate("influencer_root.approval_request");

  return (
    <HeaderRow>
      <Heading>{title}</Heading>
      <HeadingLink>{t("frequently_asking")}</HeadingLink>
    </HeaderRow>
  );
};

export default Header;
