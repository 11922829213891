import styled from "@emotion/styled";
import FlexBox from "../components/FlexBox";
import { rem, theme } from "../styling/theme";

type GridWrapperStyleT = {
  sidesMargin?: number;
};

export const GridWrapper = styled(FlexBox)<GridWrapperStyleT>(
  ({ sidesMargin = 12 }) => ({
    position: "relative",
    justifyContent: "stretch",
    alignItems: "stretch",
    flexWrap: "wrap",
    minWidth: "100%",
    margin: `0 ${rem(-sidesMargin)}`,

    [theme.media.maxS]: {
      margin: 0,
    },
  }),
);
