$(document).on("click", "#change-network-profile", function (e) {
  var _that = $(this);
  $.ajax({
    url: _that.data("url"),
    type: "GET",
    beforeSend: function (e) {
      _that.closest(".network-connection").addClass("is-loading");
    },
    complete: function (e) {
      _that.closest(".network-connection").removeClass("is-loading");
    },
  });
  return false;
});

const o_popup = (options) => {
  var that = window;
  options.windowName = options.windowName || "ConnectWithOAuth"; // should not include space for IE
  options.windowOptions =
    options.windowOptions ||
    "location=0,status=0,width=1024,height=768,display=popup";
  options.callback = function () {
    if (that._oauthWindow.error) {
      alert(that._oauthWindow.error);
    } else {
      var authorizationId = that._oauthWindow.authorization_id;
      var provider = that._oauthWindow.provider;
      var wrapper = $("#auth_wrapper_" + provider)
        .find(".network-connection")
        .first();

      if (authorizationId) {
        $.ajax({
          url: "/authorizations/" + authorizationId + "/accounts",
          type: "GET",
          data: {},
          beforeSend: function (e) {
            wrapper.addClass("is-loading");
          },
          complete: function (e) {
            wrapper.removeClass("is-loading");
          },
        });
      }
    }
  };

  if (options.path && options.windowName && options.windowOptions) {
    that._oauthWindow = window.open(
      options.path,
      options.windowName,
      options.windowOptions
    );
  }

  that._oauthInterval = window.setInterval(function () {
    if (that._oauthWindow.closed) {
      window.clearInterval(that._oauthInterval);
      options.callback();
    }
  }, 500);
};

window.open_oauth = function (url) {
  o_popup({
    path: url,
  });
};
