import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../../../styling/theme";
import QuestionBox, {
  QuestionBoxItemProps,
  QuestionBoxProps,
} from "./QuestionBox";

interface FaqProps extends Omit<QuestionBoxProps, "index"> {
  data: Array<QuestionBoxItemProps>;
}

const Wrapper = styled.dl`
  margin: 0;

  ${theme.media.maxSm} {
    margin-bottom: ${rem(36)};
  }
`;

const Faq: FC<FaqProps> = ({ data, variant }) => (
  <Wrapper>
    {data.map(({ header, content }, index) => (
      <QuestionBox
        key={index}
        index={Number(index)}
        header={header}
        content={content}
        variant={variant}
      />
    ))}
  </Wrapper>
);

export default Faq;
