import { QueryClient } from "react-query";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { ContactT } from "../../features/influencer-database-detail/types";

export const updateContactMutation = (
  queryClient: QueryClient,
  data: ContactT,
): void => {
  queryClient.setQueryData<ContactT | undefined>(
    QueryKeys.NETWORK_PROFILES_CONTACT,
    () => {
      return data;
    },
  );
};
