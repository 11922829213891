import { theme } from "../../../../../../styling/theme";

export const getOptionsEngagement = (
  callBackForYTicks: (value: number | string, index: number) => string,
) => {
  return {
    responsive: true,
    layout: {
      padding: {
        left: 10,
        right: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: ({ formattedValue }: any) => formattedValue,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: { weight: "500", size: 14 },
          color: theme.color.textLightGreyColor,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: callBackForYTicks,
          font: { weight: "500", size: 14 },
          color: theme.color.textLightGreyColor,
        },
      },
    },
  };
};
