import { _o } from "./helpers";

type forceReloadInitT = () => void;
export const forceReloadInit: forceReloadInitT = () => {
  const forceReloadElements = document.getElementsByClassName("force-reload");

  if (forceReloadElements) {
    _o(forceReloadElements).forEach((element) => {
      element.addEventListener("click", () => {
        setTimeout(() => {
          location.reload();
          return false;
        }, 250);
      });
    });
  }
};
