import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import Loader from "react-loader-spinner";
import { theme } from "../styling/theme";

const Wrapper = styled.div({
  display: "flex",
  div: {
    display: "flex",
  },
});

type LoaderT = {
  size?: number;
  color?: string;
};

const LoaderComponent: FC<LoaderT> = ({
  size = 80,
  color = theme.color.primaryColor,
}) => (
  <Wrapper>
    <Loader type="Oval" color={color} height={size} width={size} />
  </Wrapper>
);

export default LoaderComponent;
