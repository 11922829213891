import styled from "@emotion/styled";
import { Divider } from "antd";
import { debounce, set } from "lodash";
import * as React from "react";
import { FC, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { getNetworkProfileContact } from "../../data-access/getNetworkProfileContact";
import { updateNetworkProfileContact } from "../../data-access/updateNetworkProfileContact";
import Campaigns from "./components/crm/Campaigns";
import InputItem from "./components/crm/InputItem";
import Networks from "./components/crm/Networks";
import Notes from "./components/crm/Notes";
import Tags from "./components/crm/Tags";
import { BaseContactT, ContactT, InfluencerProfileT } from "./types";

const Wrapper = styled(FlexBox)({
  width: "100%",

  [theme.media.maxSm]: {
    flexDirection: "column",
  },
});

const ContentWrapper = styled(FlexBox)({
  width: "50%",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  alignSelf: "flex-start",
  padding: `${rem(16)} ${rem(16)} ${rem(16)} ${rem(8)}`,

  ":first-of-type": {
    borderRight: `1px solid ${theme.color.borderColor}`,
  },

  ":last-child": {
    borderLeft: `1px solid ${theme.color.borderColor}`,
    marginLeft: rem(-1),
  },

  [theme.media.maxSm]: {
    width: "100%",

    ":first-of-type": {
      borderRight: "none",
    },

    ":last-child": {
      borderLeft: "none",
    },
  },
});

const SectionTitle = styled(Paragraph)({
  fontWeight: 600,
  padding: `0 0 ${rem(16)} ${rem(8)}`,
});

type AddressKeyT = "address.city" | "address.zip_code" | "address.street";
export type AttributeT = keyof Omit<BaseContactT, "address"> | AddressKeyT;

type CrmT = {
  networkProfile: InfluencerProfileT;
};

const Crm: FC<CrmT> = ({ networkProfile }) => {
  const defaultContact: ContactT = {
    id: "",
    phone: null,
    email: null,
    company_number: null,
    address: null,
    campaigns: [],
    tags: [],
    notes: [],
    related_accounts: [],
  };
  const [contact, setContact] = useState<ContactT>(defaultContact);

  const { t } = useTranslate("brands.influencer_database.crm.contact");

  const { isLoading } = useQuery(
    QueryKeys.NETWORK_PROFILES_CONTACT,
    () => getNetworkProfileContact(networkProfile.id),
    {
      onSuccess: (data) => {
        setContact(data);
      },
    },
  );

  const { mutate: update } = useMutation(() =>
    updateNetworkProfileContact(networkProfile.id, contact),
  );
  const updateContact = useRef(debounce(() => update(), 500)).current;

  const onInputChangeHandle = (
    keyPath: AttributeT,
    value: string | string[],
  ): void => {
    const newContact = set(contact, keyPath, value);
    setContact((prev) => ({ ...prev, ...newContact }));
    updateContact();
  };

  return (
    <Wrapper>
      {contact && (
        <>
          <ContentWrapper>
            <SectionTitle paragraph={t("title")} paragraphSize="small" />
            <InputItem
              type={"phone"}
              attributes={["phone"]}
              contact={contact}
              setContact={onInputChangeHandle}
            />
            <InputItem
              type={"email"}
              attributes={["email"]}
              contact={contact}
              setContact={onInputChangeHandle}
            />
            <InputItem
              type={"address"}
              attributes={[
                "address.street",
                "address.city",
                "address.zip_code",
              ]}
              contact={contact}
              setContact={onInputChangeHandle}
            />
            <InputItem
              type={"company_number"}
              attributes={["company_number"]}
              contact={contact}
              setContact={onInputChangeHandle}
            />
            <Divider />
            <SectionTitle paragraph={t("networks")} paragraphSize="small" />
            <Networks contact={contact} networkProfile={networkProfile} />
            <Divider />
            <SectionTitle paragraph={t("tags.title")} paragraphSize="small" />
            <Tags contact={contact} setContact={onInputChangeHandle} />
            <Divider />
            <SectionTitle
              paragraph={t("campaigns.title")}
              paragraphSize="small"
            />
            <Campaigns contact={contact} />
          </ContentWrapper>
          <ContentWrapper>
            <SectionTitle paragraph={t("notes_title")} paragraphSize="small" />
            {!isLoading && (
              <Notes contact={contact} networkProfileId={networkProfile.id} />
            )}
          </ContentWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default Crm;
