import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ContactNoteT } from "../features/influencer-database-detail/types";

export const createContactNote = async (
  networkProfileId: string,
  text: string,
): Promise<ContactNoteT> => {
  const payload = { note: { text: text } };

  return axios
    .post(
      getApiUrl(ApiRoutesE.NETWORK_PROFILES_CONTACT_NOTES, networkProfileId),
      payload,
    )
    .then((res) => res.data.contact_note);
};
