import * as React from "react";
import { FC } from "react";
import { Bar } from "react-chartjs-2";
import { roundNumber } from "../../../../../../helpers/formaters";
import { theme } from "../../../../../../styling/theme";
import { EngagementHistogramCardDataT } from "../../types";
import { getoptionsEngagementHistogram } from "./options";

type EngagementHistogramExportT = {
  data: EngagementHistogramCardDataT;
};

type getTooltipTitleT = (min?: number, max?: number) => string;
const getTooltipTitle: getTooltipTitleT = (min, max) => {
  const firstPart = "".concat(min ? `${roundNumber(min * 100, 2)}%` : "");
  const secondPart = firstPart.concat(" - ");
  return secondPart.concat(max ? `${roundNumber(max * 100, 2)}%` : "");
};

const EngagementHistogramExport: FC<EngagementHistogramExportT> = ({
  data,
}) => {
  const { engagement_rate, items } = data;

  const itemsData = items.map((item) => ({
    [item.total]: item.min ? item.min : 0,
  }));

  const tooltipData = items.map((item) => ({
    [item.min ? item.min : 0]: getTooltipTitle(item.min, item.max),
  }));

  const values = itemsData.map((s) => Object.keys(s)).map((a) => a.join());
  const labels = itemsData.map((s) => Object.values(s)).map((a) => a.join());

  const infPositionIndex = labels.findIndex(
    (item) => parseFloat(item) >= engagement_rate,
  );

  const medianPositionIndex = items.findIndex((item) =>
    Object.keys(item).find((s) => s === "median"),
  );

  const medianValue = items[medianPositionIndex]["min"];

  const chartData = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: theme.color.greyLightColor,
        hoverBackgroundColor: theme.color.darkPrimaryColor,
        barThickness: 6,
        borderRadius: 5,
      },
    ],
  };

  const medianAndUser = {
    id: "medianAndUser",
    afterDraw(chart: any, _: any, options: any) {
      const {
        ctx,
        chartArea: { width, bottom, height },
      } = chart;

      const medianX = (width / items.length) * medianPositionIndex;
      const medianY = height + 25;

      const infPlace = (width / items.length) * infPositionIndex;
      const infX = infPlace - 30;
      const infY = height;

      ctx.strokeStyle = options.greyColor;
      ctx.beginPath();
      ctx.moveTo(medianX - 1, medianY);
      ctx.lineTo(medianX - 1, medianY + 5);
      ctx.lineWidth = 3;
      ctx.stroke();

      ctx.fillStyle = theme.color.textLightGreyColor;
      ctx.textAlign = "center";
      ctx.font = "normal 15px sans-serif";
      ctx.fillText(options.medianText, medianX, medianY + 15);
      ctx.fillStyle = theme.color.textGreyColor;
      ctx.font = "bold 20px sans-serif";
      ctx.fillText(options.medianNumber, medianX, medianY + 35);

      ctx.strokeStyle = options.greyColor;
      ctx.beginPath();
      ctx.moveTo(10, bottom);
      ctx.lineTo(width + 10, bottom);
      ctx.lineWidth = 5;
      ctx.stroke();

      ctx.strokeStyle = options.blueColor;
      ctx.beginPath();
      ctx.moveTo(10, bottom);
      ctx.lineTo(infPlace, bottom);
      ctx.lineWidth = 5;
      ctx.stroke();

      ctx.fillStyle = options.blueColor;
      ctx.beginPath();
      ctx.moveTo(infX + 70, infY + 15);
      ctx.arcTo(infX - 10, infY + 15, infX - 10, infY - 15, 5);
      ctx.arcTo(infX - 10, infY - 15, infX + 70, infY - 15, 5);
      ctx.arcTo(infX + 70, infY - 15, infX + 70, infY + 15, 5);
      ctx.arcTo(infX + 70, infY + 15, 0, infY + 15, 5);
      ctx.fill();

      ctx.fillStyle = theme.color.whiteColor;
      ctx.font = "bold 24px sans-serif";
      ctx.fillText(options.tooltipText, infPlace, infY + 8);
    },
  };

  return (
    <Bar
      data={chartData}
      options={getoptionsEngagementHistogram(
        tooltipData,
        engagement_rate,
        medianValue,
      )}
      plugins={[medianAndUser]}
      height={120}
    />
  );
};

export default EngagementHistogramExport;
